<template>
	<div class="position-relative">
		<aq-alert-modal
			ref="totalSelectedInvalid"
			name="total-selected-invalid"
			title="Invalid data"
			:description="claimSelectionMessage"
			:click-to-close="false"
		/>
		<invoice-items-scan
			ref="invoiceItems"
			:recognized-data="scannedResult"
			:invoice-duplicate-line-count="invoiceDuplicateLineCount"
			@changed="isInvoicePassed = false"
			@invoice-passed="onInvoicePassed"
			@finish-edit="onFinishEdit"
			@finish-multi-edit="onFinishMultiEdit"
			@finish-split-edit="onFinishSplit"
			@line-type-selected="onLineTypeSelected"
			@delete="onDelete"
			@toggle="onToggle"
			@toggle-all="onToggleAll"
			@highlight="$emit('highlight-doc', $event)"
			@details-passed="onDetailsPassed"
			@finish-discounts-split="onFinishDiscountsSplit"
			@show-close-button="$emit('show-close-button', $event)"
			@show-document-preview="$emit('show-document-preview')"
			v-if="scannedResult"
			:is-read-only-mode="isReadOnlyMode"
			:locale="locale"
			:multicondition="multicondition"
			:claim-id="claimId"
			:file-id="file.id"
		/>
		<div
			v-if="isAllSectionsPassed && !isReadOnlyMode"
			class="populate-data text-center mt-40 h-100"
		>
			<div><i class="fas fa-thumbs-up text-success--lighten fs-32" /></div>
			<p class="mt-20 fs-14">
				You have successfully validated the extracted form data. Would you like to populate your claim with this data?
			</p>
			<button
				v-if="$can.PopulateClaimItemsFromInvoiceScan"
				class="btn btn-primary mt-20 btn-radius--less d-block mx-auto"
				@click="onPopulateClaim"
			>
				Populate Claim
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import eventBus from '@commonServices/eventBus';
import ScanService from '@commonServices/scanService';
import FileService from '@commonServices/fileService';
import ClaimService from '@commonServices/claimService';
import { toVetInvoiceModel } from '@commonServices/utils/converter';
import { shortDate, currency } from '@commonServices/utils/filters';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError } from '@commonServices/utils/general';
import { emptyDocumentScanResult } from '@commonServices/utils/documentScanConverter';

export default {
	inject: ['hideDocumentPanel'],
	data () {
		return {
			isInvoicePassed: false,
			scannedResult: undefined,
			finalResult: null,
			isSaved: false,
			isDetailsPassed: false,
			recognizedTotalTax: null,
			recognizedTaxItemsSum: null,
			isLineItemTaxChanged: false,
			invoiceDuplicateLineCount: 0,
		};
	},
	props: {
		file: {
			type: Object,
			required: true,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		claimId: {
			type: Number,
			required: false,
			default: 0,
		},
	},
	mounted () {
		const documentPromise = this.file.scanned ? ScanService.openDocument(this.file.id) : ScanService.scanDocument(this.file.id);
		documentPromise.then(result => {
			this.scannedResult = result;

			FileService.getDuplicateInvoices(this.file.id).then(result => {
				this.invoiceDuplicateLineCount = Math.max(...result.map(x => x.lines.length));
			});
		}).catch(error => {
			if (isKnownError(error, ErrorCode.FormNotRecognised)) {
				this.scannedResult = emptyDocumentScanResult(this.claimId);
			}
		});
	},
	beforeDestroy () {
		if (this.finalResult && !this.isSaved) {
			ScanService.saveDocumentScan(this.file.id, this.finalResult);
		}
	},
	computed: {
		...mapState(['basicClaimAmount', 'currentUser']),
		isAllSectionsPassed () {
			return this.isInvoicePassed
				&& this.isDetailsPassed;
		},
		selectedInvoicesTotalAmountMessage () {
			return `Claim selection does not match the "Total Claim Amount". Total Claim Amount is ${currency(this.basicClaimAmount, this.locale)}. Total Claim selection is ${currency(this.invoiceTotal, this.locale)}`;
		},
		selectedInvoicesTotalTaxAmountMessage () {
			return `Claim selection does not match the "Total Tax Amount". Total Tax Amount is ${currency(this.recognizedTotalTax, this.locale)}. Total Tax selection is ${currency(this.recognizedTaxItemsSum, this.locale)}`;
		},
		claimSelectionMessage () {
			let message = '';
			if (!this.invoiceTotalValid) {
				message += `${this.selectedInvoicesTotalAmountMessage} \n`;
			}
			if (!this.invoiceTotalTaxValid) {
				message += this.selectedInvoicesTotalTaxAmountMessage;
			}

			return message;
		},
		invoiceTotal () {
			return this.finalResult?.totalAmount?.value ?? 0;
		},
		invoiceTotalTax () {
			return this.finalResult?.totalTax?.value ?? 0;
		},
		invoiceTotalValid () {
			return parseFloat(this.basicClaimAmount) === parseFloat(this.invoiceTotal);
		},
		invoiceTotalTaxValid () {
			if (this.recognizedTaxItemsSum !== 0 && !this.isLineItemTaxChanged) {
				return parseFloat(this.recognizedTotalTax) === parseFloat(this.recognizedTaxItemsSum);
			}
			return true;
		},
	},
	methods: {
		...mapActions(['changeCurrentClaimStatus', 'changeCalculateClaimItemsOnPanelClose']),
		async onPopulateClaim () {
			await ScanService.saveDocumentScan(this.file.id, this.finalResult);
			this.isSaved = true;
			const claimItems = await ClaimService.getClaimItemsFromVetInvoices(this.claimId, toVetInvoiceModel(this.file.id, this.finalResult));
			eventBus.$emit('populate-claim-items', { items: claimItems, fileId: this.file.id });
			this.changeCalculateClaimItemsOnPanelClose(false);
			this.hideDocumentPanel();
		},
		async onInvoicePassed (totalAmount, recognizedTotalTax, recognizedTaxItemsSum, currentTotalTax, isLineItemTaxChanged, totalDiscount) {
			this.isLineItemTaxChanged = isLineItemTaxChanged;
			this.recognizedTotalTax = recognizedTotalTax ?? 0;
			this.recognizedTaxItemsSum = recognizedTaxItemsSum;
			this.finalResult = cloneDeep(this.scannedResult);
			this.finalResult.totalAmount.value = totalAmount;
			this.finalResult.totalAmount.accuracy = 1;
			if (currentTotalTax != null && this.finalResult.totalTax) {
				this.finalResult.totalTax.value = currentTotalTax;
				this.finalResult.totalTax.accuracy = 1;
			}
			if (totalDiscount != null && this.finalResult.totalDiscount) {
				this.finalResult.totalDiscount.value = totalDiscount;
				this.finalResult.totalDiscount.accuracy = 1;
			}
			if (!this.invoiceTotalValid || !this.invoiceTotalTaxValid) {
				await this.$refs.totalSelectedInvalid.show();
			}
			this.isInvoicePassed = true;
		},
		onFinishMultiEdit ({ indexes, value }) {
			for (const index of indexes) {
				const entity = this.scannedResult.items[index];
				entity.date.value = shortDate(value.date);
				entity.policySection.value = value.selectedPolicySection;
				entity.editedByUser.value = { ...this.currentUser };
				entity.claimCondition.value = value.selectedClaimCondition;
				entity.petName.value = value.petName;
				entity.itemType.value = value.itemType;
				entity.deductions = value.selectedUserDeductions?.map((deduction, index) => {
					return {
						value: {
							id: deduction.id,
							value: deduction.description,
							amount: index === 0 && entity.amount.value ? entity.amount.value : 0,
						},
					};
				}) ?? [];

				this.updateAccuracy(entity);
			}
		},
		onFinishSplit (lineItemCopies, lineItem, lineItemIndex) {
			const user = { ...this.currentUser };
			lineItem.editedByUser.value = user;
			this.$set(this.scannedResult.items, lineItemIndex, lineItem);
			for (const lineItemCopy of lineItemCopies) {
				lineItemCopy.createdByUser.value = user;
				lineItemCopy.date.value = lineItem.date.value;
				this.scannedResult.items.push(lineItemCopy);
			}
		},
		onFinishDiscountsSplit (items) {
			for (const lineItem of items) {
				const entity = this.scannedResult.items[lineItem.index];
				entity.discountAmount.value = lineItem.discount;
				entity.discountAmount.confidence = 1;
				entity.editedByUser.value = { ...this.currentUser };
			};
		},
		onFinishEdit ({ index, value, newItem }) {
			const entity = index == null ? newItem : this.scannedResult.items[index];

			entity.description.value = value.description;
			entity.amount.value = value.amount;
			entity.date.value = value.date;
			entity.policySection.value = value.selectedPolicySection;
			entity.quantity.value = value.quantity;
			entity.editedByUser.value = { ...this.currentUser };
			entity.claimCondition.value = value.selectedClaimCondition;
			entity.nonFinancialNumber.value = value.nonFinancialNumber;
			entity.discountAmount.value = value.discountAmount;
			entity.discountPercent.value = value.discountPercent;
			entity.tax.value = value.tax;
			entity.itemType.value = value.itemType;
			entity.petName.value = value.petName;
			entity.deductions = value.selectedUserDeductions?.map((deduction, index) => {
				return {
					value: {
						id: deduction.id,
						value: deduction.description,
						amount: index === 0 && entity.amount.value ? entity.amount.value : 0,
					},
				};
			}) ?? [];

			this.updateAccuracy(entity);

			if (index == null) {
				entity.createdByUser.value = { ...this.currentUser };
				this.scannedResult.items.push(newItem);
			}
		},
		onLineTypeSelected ({ index, value }) {
			const entity = this.scannedResult.items[index];
			entity.itemType.value = value;
			entity.itemType.confidence = 1;
			entity.editedByUser.value = { ...this.currentUser };
		},
		onDelete (index) {
			this.scannedResult.items.splice(index, 1);
		},
		onToggle (payload) {
			const toggledItem = this.scannedResult.items[payload.index];
			toggledItem.excluded.value = !payload.value;
		},
		onToggleAll (payload) {
			this.scannedResult.items.forEach(item => { item.excluded.value = !payload; });
		},
		updateAccuracy (item) {
			for (const prop of Object.values(item)) {
				prop.confidence = 1;
			}
		},
		setScannedItemCoordinates (boundingBox, page) {
			this.$emit('highlight-doc', { boundingBox, page });
		},
		onDetailsPassed ({ isBasicDataSame, claimData }) {
			this.isDetailsPassed = true;
			if (isBasicDataSame) return;
			ClaimService.proceedClaim(this.claimId, claimData)
				.then(claimStatus => this.changeCurrentClaimStatus(claimStatus));
		},
	},
	provide () {
		return {
			setScannedItemCoordinates: this.setScannedItemCoordinates,
		};
	},
};
</script>

<style lang="scss" scoped>
  .btn-radius--less {
    border-radius: 2px;
  }

  .text-success--lighten {
    color: lighten($success, 15%);
  }
</style>
