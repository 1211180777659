import { reportingHttpClient } from './httpClient';
import {
	toClaimReportModel,
	toClaimPaymentsReportModel,
	toClaimStatusReportModel,
	toReportChartModel,
	toUserStatsReportModel,
	toClaimQualityAuditReportModel,
} from '@commonServices/utils/reportsConverter';
import { FilterGroupEnum } from '@commonServices/models/FilterGroup';
import extraService from './extraService';
import { FilterType } from './models/FilterType';
import exportService from './exportService';
import { getQueryString } from '@commonServices/utils/general';
import { getDayStart, getDayEnd } from '@commonServices/utils/dateUtils';

const ReportModelConverterMap = {
	[FilterGroupEnum.ClaimReport]: toClaimReportModel,
	[FilterGroupEnum.ClaimPayments]: toClaimPaymentsReportModel,
	[FilterGroupEnum.ClaimStatus]: toClaimStatusReportModel,
	[FilterGroupEnum.UserStats]: toUserStatsReportModel,
	[FilterGroupEnum.QualityAuditReport]: toClaimQualityAuditReportModel,
};

export default {
	// #region requests

	getGroups () {
		return reportingHttpClient('/api/claims/reports')
			.get();
	},
	getMenuOptions (reportType) {
		return reportingHttpClient(`/api/claims/reports/${reportType}/menu-options`)
			.get();
	},
	runReport (reportType, filters, paging, sorting) {
		const toModel = ReportModelConverterMap[reportType];
		const filterSetModel = {
			filters,
			sorting,
		};
		return reportingHttpClient(`/api/claims/reports/${reportType}?${paging.toQuery()}`)
			.post(filterSetModel)
			.then(toModel);
	},
	getChartForReport (reportType, filters) {
		return reportingHttpClient(`/api/claims/reports/${reportType}/chart`)
			.post(filters)
			.then(toReportChartModel);
	},
	searchReports (searchTerm) {
		return reportingHttpClient(`/api/claims/reports/search?searchTerms=${searchTerm}`, { omitSpinner: true })
			.get();
	},
	getFiltersDictionaries (reportType) {
		let filterTypes = [];
		switch (reportType) {
		case FilterGroupEnum.ClaimReport: {
			filterTypes = [FilterType.Brand, FilterType.PetType, FilterType.PetGender, FilterType.PolicyStatus, FilterType.ClaimStatus, FilterType.ConditionType, FilterType.RejectionReason,
				FilterType.UserDeductionType, FilterType.ClaimFormType, FilterType.ReassessmentReason, FilterType.ReassessmentOutcome, FilterType.County];
			break;
		}
		case FilterGroupEnum.ClaimPayments: {
			filterTypes = [FilterType.PaymentType];
			break;
		}
		case FilterGroupEnum.ClaimStatus: {
			filterTypes = [FilterType.Brand, FilterType.PetType, FilterType.ConditionType, FilterType.ClaimStatus, FilterType.ClaimFormType, FilterType.County];
			break;
		}
		case FilterGroupEnum.UserStats: {
			filterTypes = [FilterType.Brand, FilterType.StatsTeams];
			break;
		}
		case FilterGroupEnum.QualityAuditReport: {
			filterTypes = [FilterType.Brand, FilterType.ClaimStatus, FilterType.FraudFlag];
			break;
		}
		}
		return extraService.getFiltersData(filterTypes);
	},
	saveUserReport (reportType, userReportModel, handleErrorCodes = []) {
		return reportingHttpClient(`/api/claims/reports/${reportType}/user/filters`, { handleErrorCodes })
			.post(userReportModel);
	},
	getUserReportFilters (userReportId) {
		return reportingHttpClient(`/api/claims/reports/${userReportId}/user/filters`)
			.get();
	},
	updateUserReport (userReportId, filters, sorting) {
		return reportingHttpClient(`/api/claims/reports/${userReportId}/user/filters`)
			.put({ filters, sorting });
	},
	deleteUserReport (userReportId) {
		return reportingHttpClient(`/api/claims/reports/${userReportId}`)
			.delete();
	},
	generateExportDocument (exportModel, fileName) {
		return exportService.exportDocument(reportingHttpClient, exportModel, fileName, '/api/claims/reports/export');
	},
	getClosedClaims () {
		const params = {
			userId: null,
			dateFrom: getDayStart().toISOString(),
			dateTo: getDayEnd().toISOString(),
		};

		return reportingHttpClient(`/api/reporting/stats/closed-claims?${getQueryString(params)}`, { omitSpinner: true })
			.get();
	},
	// #endregion
};
