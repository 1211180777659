<template>
	<aq-sandwich
		ref="sandwich"
		header="Policy Details"
		sub-header="Validate extracted data to verify policy details"
		active-container-class="active-container h-100 d-flex flex-column"
		content-class="flex-grow-1 d-flex flex-column"
		:is-active-on-start="isActiveOnStart"
	>
		<template slot="content">
			<div class="section-content flex-fill">
				<div class="container-fluid">
					<aq-scan-form-input
						class="mb-16"
						v-model="customerName.value"
						label="Customer Name"
						:is-valid="!warnings('customerName').length"
						:warning-messages="warnings('customerName')"
						:disabled="isReadonly"
						@input="customerName.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.customerName)"
					/>
					<aq-scan-form-input
						class="mb-10"
						v-model="petName.value"
						label="Pet Name"
						:is-valid="!warnings('petName').length"
						:warning-messages="warnings('petName')"
						:disabled="isReadonly"
						@input="petName.accuracy = 1, $emit('changed')"
						@focused="highlight(recognizedData.petName)"
					/>
				</div>
			</div>
			<div class="section-footer">
				<div class="container-fluid">
					<button
						class="btn btn-primary btn-radius--less mr-0 ml-auto d-block"
						@click="onProceed()"
					>
						Proceed
					</button>
				</div>
			</div>
		</template>
	</aq-sandwich>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import { shownErrors } from '@commonServices/utils/general';

export default {
	name: 'PolicyInformationScan',
	props: {
		customerName: {
			type: Object,
			required: true,
		},
		petName: {
			type: Object,
			required: true,
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: false,
		},
		recognizedData: {
			type: Object,
			required: true,
		},
		isActiveOnStart: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		...mapState(['interactionContext']),
		existingCustomerName () {
			return `${this.interactionContext.customer.firstName} ${this.interactionContext.customer.lastName}`;
		},
		existingPetName () {
			return this.interactionContext.pet.name;
		},
		warningMessages () {
			return {
				customerName: {
					value: {
						matchingExisting: `Customer name does not match existing: ${this.existingCustomerName}`,
					},
				},
				petName: {
					value: {
						matchingExisting: `Pet name does not match existing: ${this.existingPetName}`,
					},
				},
			};
		},
	},
	validations () {
		return {
			customerName: {
				value: {
					matchingExisting: (value) => value === this.existingCustomerName,
				},
			},
			petName: {
				value: {
					matchingExisting: (value) => value === this.existingPetName,
				},
			},
		};
	},
	methods: {
		warnings (property) {
			return shownErrors(
				this.$v[property],
				'value',
				this.warningMessages[property].value,
			);
		},
		onProceed () {
			this.$refs.sandwich.onToggle();
			this.$emit('passed', cloneDeep({ customerName: this.customerName, petName: this.petName }));
		},
		expand () {
			this.$refs.sandwich.onExpand();
		},
		highlight (field) {
			const { page, boundingBox } = field;
			this.$emit('focused', { page, boundingBox });
		},
	},
};
</script>

<style lang="scss" scoped>
.bg-danger-darken {
  color: $body-color;
}

.search-item {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease;

  &:last-child {
    border: none;
  }

  &:hover,
  &.selected {
    p {
      color: $body-color;
    }
  }

  &:hover {
    background: $primary;
  }

  &.selected {
    background: $active-color;
  }

  &.create-new {
    &:hover {
      color: $body-color;
      background: darken($primary, 15%);
    }
  }
}
</style>
