<template>
	<div
		class="aq-read-only-input control-container"
		:class="{'disabled': disabled}"
	>
		<div class="label">
			{{ label }}
		</div>
		<div
			class="description text-truncate mt-20"
			v-tooltip="description"
		>
			{{ description || defaultEmptyValue }}
		</div>
		<template v-if="description && type==='money'">
			<div class="icon-wrapper">
				<i
					class="control-icon text-primary fa-lg"
					:class="[iconCurrencyClass]"
				/>
			</div>
		</template>
		<div
			class="locked"
			v-if="disabled"
		>
			<i class="fas fa-lock" />
		</div>
	</div>
</template>
<script>
import { getCurrencyByLocale } from '@commonServices/settings/localeSettings';

const FontAwesomeCurrencyMap = {
	'$': 'fa-dollar-sign',// eslint-disable-line
	'£': 'fa-pound-sign',
};

export default {
	name: 'AqReadOnlyInput',
	props: {
		label: {
			type: String,
			required: true,
		},
		description: {
			type: [String, Number, null],
			required: false,
			default: null,
		},
		type: {
			type: String,
			default: 'text',
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		iconCurrencyClass () {
			return `fas ${FontAwesomeCurrencyMap[getCurrencyByLocale(this.locale).symbol]}`;
		},
		defaultEmptyValue () {
			return this.type === 'money' ? '0.00' : 'N/A';
		},
	},
};
</script>
<style lang="scss" scoped>
.aq-read-only-input {
  position: relative;
  background: var(--controlBg);
  cursor: default;
  padding: 8px 20px 15px;
  border-radius: 3px;
  min-height: 55px;

  .label {
    font-size: 12px;
    line-height: 18px;
    color: $primary;
  }

  .description {
    font-size: 14px;
    line-height: 15px;
  }
}

.icon-wrapper {
  position: absolute;
  right: 20px;
  top: 22px;
}
</style>
