export class TableRecognitionResult {
	constructor (
		date,
		quantity,
		description,
		amount,
		policySection,
		excluded,
		deductions,
		editedByUser,
		createdByUser,
		claimCondition,
		nonFinancialNumber,
		discountAmount,
		discountPercent,
		itemType,
		tax,
		petName,
	) {
		this.date = date;
		this.quantity = quantity;
		this.description = description;
		this.amount = amount;
		this.policySection = policySection;
		this.excluded = excluded;
		this.deductions = deductions;
		this.editedByUser = editedByUser;
		this.createdByUser = createdByUser;
		this.claimCondition = claimCondition;
		this.nonFinancialNumber = nonFinancialNumber;
		this.discountAmount = discountAmount;
		this.discountPercent = discountPercent;
		this.itemType = itemType;
		this.tax = tax;
		this.petName = petName;
	}
}
