<template>
	<div class="d-flex flex-column h-100">
		<aq-confirmation-modal
			ref="continueDialog"
			name="continue-dialog"
			title="Confirm Change"
			description="You have unsaved changes which may influence the calculation of this claim. Any Subsequent data may be invalidated."
			yes-label="Save"
			no-label="Cancel"
			data-qa="processClaim_modal_confirmChange"
		/>
		<aq-confirmation-modal
			ref="saveChangesDialog"
			name="save-changes-dialog"
			title="Save changes"
			description="You have unsaved changes, Click Save to Save as Draft or Cancel to Close without saving."
			yes-label="Save"
			no-label="Cancel"
			data-qa="processClaim_modal_saveChanges"
		>
			<template
				v-if="!isAllowedToSaveClaimAsDraft"
				slot="yesButton"
			>
				<button
					type="button"
					class="btn btn-success ml-auto"
					disabled
				>
					<div
						v-tooltip="{
							content: 'Current claim status prevents saving as draft.',
							classes: 'not-allowed-save-draft-tooltip'
						}"
					>
						Save
					</div>
				</button>
			</template>
		</aq-confirmation-modal>
		<reassessment-outcome-modal
			v-if="claimId"
			data-qa="claimProcess_modal_reassessmentOutcomeModal"
			name="reassessment-outcome-modal"
			ref="saveReassessmentOutcomeModal"
			:selected-reassessment-outcome="selectedReassessmentOutcome"
			:claim-id="claimId"
			@input="selectedReassessmentOutcome = $event"
		/>
		<aq-modal name="reject-claim">
			<manual-rejection-modal
				:rejection-context="{ claimId }"
				@reject="onReject"
				data-qa="processClaim_modal_rejectClaim"
			/>
		</aq-modal>
		<aq-modal name="fraud-check">
			<fraud-check-modal
				@fraud-check="onFraudCheck"
				:claim-id="claimId"
				data-qa="processClaim_modal_fraudCheck"
			/>
		</aq-modal>

		<div class="container-fluid p-0 mb-50">
			<div class="d-flex flex-row">
				<div class="d-flex flex-column w-100">
					<aq-fraud-check-actions
						v-if="isFraudCheck"
						:claim-id="sharedData.claimId"
						:is-reassessment="sharedData.isReassessment"
						:has-claim-unsaved-changes="isProcessDirty"
						:fraud-check-reasons="sharedData.fraudCheckReasons"
						@finish="onFraudChekActionFinish"
					/>
					<div class="mt-100">
						<aq-process-timeline
							:periods="treatmentPeriods"
							:selected-period="selectedPeriod"
						/>
					</div>
					<div class="row no-gutters mt-25">
						<div class="col-4">
							<claim-item-form
								ref="claimForm"
								class="pb-30"
								:policy-sections="policySections"
								:treatment-periods="treatmentPeriods"
								:all-claim-items="claimItems"
								:editing-index="editingIndex"
								:is-read-only-mode="!$can.AddClaimItems || isReadOnlyMode"
								:claim-amount="claimAmount"
								:is-continuation-claim="sharedData.isContinuationClaim"
								:locale="locale"
								:multicondition="multicondition"
								:conditions="conditions"
								:currency-conversion-required="currencyConversionRequired"
								:customer-currency-code="customerCurrencyCode"
								@policy-changed="onChangePolicy"
								@save="onSaveItem"
								@cancel="onCancelItem"
								@period-changed="onPeriodChanged"
							/>
						</div>
						<div class="col-8">
							<div class="pl-30">
								<div class="section-header">
									<div class="section-header__title">
										Policy Sections
									</div>
									<p>View and manage your added claim items</p>
								</div>
								<claim-item-view
									ref="claimView"
									:all-rows="claimItems"
									:conditions="conditions"
									:is-read-only-mode="!$can.EditClaimItems || isReadOnlyMode"
									:locale="locale"
									:multicondition="multicondition"
									@select="onRowSelected"
									@edit="onRowEdited"
									@delete="onRowDeleted"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<claim-navigation-bar
			:shared-data="sharedData"
			:status="status"
			:multicondition="multicondition"
			:is-read-only-mode="isReadOnlyMode"
			:is-last-step="isLastStep"
			:rejection-state="rejectionState"
			@cancel="onCancel"
			@back="onBack"
			@next="onNext"
			@manual-reject="onRejectManually"
			@fraud="onFraudCheckSelect"
			@override-no-policy-limits="onOverrideNoPolicyLimits"
			@escalate-claim="onClaimEscalateSelect"
			@remove-escalation="onRemoveEscalation"
			@override-rejection="overrideRejection"
			@cancel-override-condition="cancelOverrideRejection"
		>
			<template slot="success">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block d-flex">
							<div><i class="mr-10 fas fa-check text-white" /></div>
							<div>You have completed all of the required fields. Please proceed to the next screen.</div>
						</div>
					</div>
				</div>
			</template>
			<template slot="warning">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block justify-content-center d-flex">
							<div><i class="mr-10 fas fa-exclamation-triangle text-white" /></div>
							<div>{{ rejectionMessage }}</div>
						</div>
						<aq-claims-list
							v-if="duplicateDataClaimIds?.length"
							class="mt-5"
							label="Claim ID's"
							:claim-ids="duplicateDataClaimIds"
						/>
					</div>
				</div>
			</template>
			<template slot="danger">
				<div class="d-flex align-items-center mx-30">
					<div class="mx-auto">
						<div class="d-inline-block d-flex">
							<div><i class="mr-10 fas fa-times-circle text-white" /></div>
							<div>{{ dangerMessage }}</div>
						</div>
					</div>
				</div>
			</template>
		</claim-navigation-bar>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ClaimService from '@commonServices/claimService';
import ClaimItemView from '@commonServices/models/ClaimItemView';
import StepNameEnum from '@commonServices/models/steps/StepNameEnum';
import { pageStatusEnum } from '@commonServices/models/PageStatusEnum';
import cloneDeep from 'lodash.clonedeep';
import eventBus from '@commonServices/eventBus';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError } from '@commonServices/utils/general';
import ClaimItemsValidationResultEnum from '@commonServices/models/ClaimItemsValidationResultEnum';
import { toClaimConditionDescription } from '@commonServices/utils/converter';
import { outcomeState, claimNumberStatus, saveClaimAsDraftAllowedStatuses } from '@commonServices/models/ClaimStatusActionEnum';
import AqClaimsList from '@commonWidgets/AqClaimsList';
import { CountryCurrencyCodesMap } from '@clientCommon/services/models/CurrencyCodes';

const noActiveCoverPeriodMsg = 'Policy Warning - No Active Cover Period';
const noProductCoverageMsg = 'Claim Rejection - No Product Coverage';
const noPolicyLimitsMsg = 'No Policy Limits available';
const rejPreExCondMsg = 'Claim Rejection - Pre-Existing Condition';
const rejRelCondMsg = 'Claim Rejection - Related Condition';
const rejWaitPeriodMsg = 'Policy Wait Period Rejection';
const rejManuallyMsg = 'Claim Rejection - Manual Rejection';
const rejMixedMsg = 'Claim Rejection - All Diagnoses are rejected';
const invalidClaimItemsMsg = 'There are claim items with invalid data. Update or remove to proceed.';
const conditionsClaimItemsCountMismatchMsg = 'Claim line items expected for all recorded ailments.';
const invalidTotalAmountMsg = 'Claim breakdown total does not match the “Total Claim Amount”';
const duplicateClaimMsg = 'Claim Rejection - Duplicate Claim (Same date of loss, same amount)';
const duplicateInvoiceMsg = 'Claim Rejection - Duplicate Invoice';

export default {
	name: 'ClaimProcess',
	inject: ['goNext', 'goPrevious', 'exitClaim', 'getStepData', 'updateClaimStatus', 'mountClaimPage', 'leavePage', 'onClaimEscalate'],
	components: {
		AqClaimsList,
	},
	props: {
		sharedData: {
			type: Object,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			isLoaded: false,
			originalStepData: null,
			isProcessDirty: false,
			conditions: [],
			claimItems: [],
			policySections: [],
			treatmentPeriods: [],
			policyId: 0,
			claimId: 0,
			status: pageStatusEnum.Danger,
			selectedPeriod: null,
			editingIndex: -1,
			hasPayments: false,
			validationResult: ClaimItemsValidationResultEnum.INVALID_TOTAL_AMOUNT,
			ignoreNoPolicyLimits: false,
			ignoreDuplicateInvoice: false,
			ignoreDuplicateClaim: false,
			wasIgnoredRejection: false,
			claimAmount: 0,
			duplicateClaimCheckResult: {
				isValid: true,
				claimIds: [],
			},
			duplicateInvoiceCheckResult: {
				isValid: true,
				claimIds: [],
			},
			rejectionData: {
				isManuallyRejected: false,
				rejectionReasonId: 0,
				comment: null,
			},
			fraudCheck: false,
			fraudCheckReasons: [],
			fraudCheckComment: null,
			selectedReassessmentOutcome: null,
			outcomeState: outcomeState.Rejected,
			fraudCheckFailed: false,
			escalationReasonId: null,
			duplicateClaimIds: [],
			stepProceedAborted: false,
		};
	},
	async mounted () {
		await this.mountClaimPage();
		await this.mountPage();
		this.isLoaded = true;
	},
	beforeDestroy () {
		eventBus.$off('populate-claim-items', this.populateHandler);
		eventBus.$off('delete-claim-items', this.onRemoveFileRelatedClaimItems);
		eventBus.$off('invoice-panel-add-claim-condition', this.onAddClaimConditionFromInvoicePanel);
	},
	computed: {
		...mapState(['isOpenDocPanel', 'calculateClaimItemsOnPanelClose', 'currentUser', 'claimPayees', 'appSettings']),
		isAffectOtherSteps: function () {
			return this.hasPayments && this.isProcessDirty;
		},
		isLastStep () {
			if (this.isReadOnlyMode) {
				return (this.status === pageStatusEnum.Warning || this.status === pageStatusEnum.Danger) && !this.sharedData.isReassessment;
			}
			return this.status === pageStatusEnum.Warning;
		},
		rejectionMessage () {
			if (this.validationResult === ClaimItemsValidationResultEnum.REJECTED_MANUALLY) {
				return rejManuallyMsg;
			}
			if (this.isDuplicateInvoice || (this.isPendingRejection && this.sharedData.nextStatus === claimNumberStatus.RejectedByDuplicateInvoice)) {
				return duplicateInvoiceMsg;
			}
			if (this.isDuplicateClaim || (this.isPendingRejection && this.sharedData.nextStatus === claimNumberStatus.RejectedByDuplicateClaim)) {
				return duplicateClaimMsg;
			}

			switch (this.validationResult) {
			case ClaimItemsValidationResultEnum.REJECTED_BY_NO_POLICY_COVER: {
				return noActiveCoverPeriodMsg;
			}
			case ClaimItemsValidationResultEnum.REJECTED_BY_NO_PRODUCT_COVER: {
				return noProductCoverageMsg;
			}
			case ClaimItemsValidationResultEnum.REJECTED_SECTION_LIMIT:
			case ClaimItemsValidationResultEnum.REJECTED_BY_CONDITION_LIMIT:
			case ClaimItemsValidationResultEnum.REJECTED_BY_MULTIPLE_CONDITIONS: {
				return noPolicyLimitsMsg;
			}
			case ClaimItemsValidationResultEnum.REJECTED_BY_PREEXISTING_CONDITION: {
				return rejPreExCondMsg;
			}
			case ClaimItemsValidationResultEnum.REJECTED_BY_RELATED_CONDITION: {
				return rejRelCondMsg;
			}
			case ClaimItemsValidationResultEnum.REJECTED_WAITING_PERIOD: {
				return rejWaitPeriodMsg;
			}
			case ClaimItemsValidationResultEnum.REJECTED_MULTIPLE: {
				return rejMixedMsg;
			}
			}

			return '';
		},
		dangerMessage () {
			switch (this.validationResult) {
			case ClaimItemsValidationResultEnum.INVALID: {
				return invalidClaimItemsMsg;
			}
			case ClaimItemsValidationResultEnum.CONDITIONS_CLAIM_ITEMS_COUNT_MISMATCH: {
				return conditionsClaimItemsCountMismatchMsg;
			}
			case ClaimItemsValidationResultEnum.INVALID_TOTAL_AMOUNT: {
				return invalidTotalAmountMsg;
			}
			default: return '';
			}
		},
		isFraudCheck () {
			return this.sharedData.claimStatus === claimNumberStatus.FraudCheck;
		},
		isReadOnlyMode () {
			return (!this.$can.AddClaimItems
				&& !this.$can.EditClaimItems
				&& !this.$can.PopulateClaimItemsFromInvoiceScan)
				|| this.sharedData.isReadOnlyMode;
		},
		isAllowedToSaveClaimAsDraft () {
			return saveClaimAsDraftAllowedStatuses.includes(this.sharedData.claimStatus);
		},
		rejectionState () {
			return {
				isManuallyRejected: this.isManuallyRejected,
				isNoPolicyLimits: this.checkNoPolicyLimitsError(this.validationResult),
				hasRejectionOverride: this.ignoreDuplicateInvoice || this.ignoreDuplicateClaim,
				isDuplicateClaim: this.isDuplicateClaim,
				isDuplicateInvoice: this.isDuplicateInvoice,
				hasDuplicateOverride: this.ignoreDuplicateInvoice || this.ignoreDuplicateClaim,
			};
		},
		isDuplicate () {
			return this.isDuplicateInvoice || this.isDuplicateClaim;
		},
		isDuplicateClaim () {
			return (!this.duplicateClaimCheckResult.isValid && !this.ignoreDuplicateClaim)
				|| (this.isReadOnlyMode && this.sharedData.claimStatus === claimNumberStatus.RejectedByDuplicateClaim);
		},
		isDuplicateInvoice () {
			return (!this.duplicateInvoiceCheckResult.isValid && !this.ignoreDuplicateInvoice)
				|| (this.isReadOnlyMode && this.sharedData.claimStatus === claimNumberStatus.RejectedByDuplicateInvoice);
		},
		duplicateDataClaimIds () {
			if (this.isDuplicate && !this.isReadOnlyMode && !this.isManuallyRejected) {
				return this.isDuplicateInvoice ? this.duplicateInvoiceCheckResult.claimIds : this.duplicateClaimCheckResult.claimIds;
			}
			return this.duplicateClaimIds;
		},
		ignoreDuplicateState () {
			return {
				ignoreDuplicateClaim: this.ignoreDuplicateClaim,
				ignoreDuplicateInvoice: this.ignoreDuplicateInvoice,
			};
		},
		claimData () {
			return {
				claimId: this.sharedData.claimId,
				dateOfLoss: this.sharedData.claim.basicData.dateOfLoss,
				amount: this.sharedData.claim.basicData.amount,
				invoiceNumbers: this.sharedData.invoiceNumbers,
				isLoaded: this.isLoaded,
			};
		},
		isManuallyRejected () {
			return this.rejectionData.isManuallyRejected || this.validationResult === ClaimItemsValidationResultEnum.REJECTED_MANUALLY;
		},
		isPendingRejection () {
			return this.sharedData.claimStatus === claimNumberStatus.RejectionPending;
		},
		currencyConversionRequired () {
			return this.appSettings.currencyConversion
					&& this.sharedData.claimVets.some(vet => vet.country !== this.sharedData.customerCountry);
		},
		customerCurrencyCode () {
			return CountryCurrencyCodesMap[this.sharedData.customerCountry];
		},
	},
	watch: {
		isOpenDocPanel (isOpen) {
			if (!isOpen && !this.isReadOnlyMode && this.calculateClaimItemsOnPanelClose) {
				this.calculateClaimItem(this.claimItems);
			}
		},
		claimData () {
			if (this.isLoaded) {
				this.validateDuplicate();
			}
		},
		ignoreDuplicateState () {
			if (this.isLoaded) {
				this.calculateClaimItem(this.claimItems);
			}
		},
	},
	methods: {
		...mapActions(['changeBasicClaimAmount', 'changeCalculateClaimItemsOnPanelClose']),
		...mapMutations(['setClaimStepState']),
		onOverrideNoPolicyLimits () {
			this.ignoreNoPolicyLimits = true;
			this.onNext();
		},
		async mountPage () {
			this.policyId = this.sharedData.policyId;
			this.claimId = this.sharedData.claimId;
			const [policySections, processData] = await Promise.all([ClaimService.getPolicySections(this.claimId), ClaimService.getClaimItems(this.claimId)]);
			this.conditions = this.sharedData.claim.conditions.map(c => ({
				id: c.id,
				description: toClaimConditionDescription(c),
			}));
			this.policySections = policySections;
			this.originalStepData = cloneDeep(processData);
			this.claimItems = processData.claimItems;
			this.ignoreDuplicateInvoice = processData.wasIgnoredDuplicateInvoice;
			this.ignoreDuplicateClaim = processData.wasIgnoredDuplicateClaim;
			this.validationResult = processData.validationResult;
			this.wasIgnoredRejection = processData.wasIgnoredNoPolicyLimits;
			this.duplicateClaimIds = processData.duplicateClaimIds;
			this.status = this.getPageStatus();
			this.hasPayments = processData.hasPayments;
			this.claimAmount = processData.claimAmount;
			this.changeBasicClaimAmount(this.claimAmount);
			eventBus.$on('delete-claim-items', this.onRemoveFileRelatedClaimItems);
			eventBus.$on('populate-claim-items', this.populateHandler);
			eventBus.$on('invoice-panel-add-claim-condition', this.onAddClaimConditionFromInvoicePanel);
		},
		onRemoveFileRelatedClaimItems (file) {
			if (file) {
				this.claimItems = this.claimItems.filter((item) => item.fileId !== file.fileId);
				this.calculateClaimItem(this.claimItems).then(() =>
					this.changeCalculateClaimItemsOnPanelClose(true));
			}
		},
		async onChangePolicy (claimConditionId, policySectionId) {
			if (!claimConditionId || !policySectionId) {
				this.treatmentPeriods = [];
			} else {
				this.treatmentPeriods = await ClaimService.getTreatmentPeriods(this.claimId, claimConditionId, policySectionId);
			}
		},
		onSaveItem (newItem) {
			this.isProcessDirty = true;
			this.selectedPeriod = newItem.treatmentPeriod;
			const claimItem = new ClaimItemView(
				undefined,
				newItem.condition.id,
				newItem.policySection.id,
				newItem.policySection.description,
				newItem.treatmentPeriod,
				newItem.amount,
				newItem.amountExpression,
				newItem.deductions,
				null,
				0,
				null,
				true,
				newItem.nonFinancialNumber,
				newItem.rebalanceExcess,
				null,
				null,
			);
			const cloneClaimItems = [...this.claimItems];

			if (this.editingIndex === -1) cloneClaimItems.push(claimItem);
			else {
				claimItem.claimItemId = cloneClaimItems[this.editingIndex].claimItemId;
				claimItem.systemDeductions = cloneClaimItems[this.editingIndex].systemDeductions;
				cloneClaimItems[this.editingIndex] = claimItem;
			}

			this.calculateClaimItem(cloneClaimItems).then(() => {
				this.$refs.claimForm.clearForm();
				this.editingIndex = -1;
				this.$refs.claimView.clearRowClasses();
			});
		},
		onCancelItem () {
			this.editingIndex = -1;
			this.$refs.claimView.clearRowClasses();
		},
		calculateClaimItem (claimItems) {
			const claimData = {
				claimItems,
				ignoreDuplicateInvoice: this.ignoreDuplicateInvoice,
				ignoreDuplicateClaim: this.ignoreDuplicateClaim,
			};
			return ClaimService.calculateClaimItem(this.sharedData.claimId, claimData).then(data => {
				this.claimItems = data.claimItems;
				this.validationResult = data.validationResult;
				this.wasIgnoredRejection = data.wasIgnoredNoPolicyLimits;
				this.status = this.getPageStatus();
				this.claimAmount = data.claimAmount;
			});
		},
		async onBack () {
			await this.goPrevious();
		},
		showOutcomesModal () {
			const isRejectedByPolicyLimits = this.checkNoPolicyLimitsError(this.validationResult) && !this.ignoreNoPolicyLimits;
			const isReject = [
				ClaimItemsValidationResultEnum.REJECTED_BY_NO_POLICY_COVER,
				ClaimItemsValidationResultEnum.REJECTED_BY_NO_PRODUCT_COVER,
				ClaimItemsValidationResultEnum.REJECTED_WAITING_PERIOD,
				ClaimItemsValidationResultEnum.REJECTED_BY_PREEXISTING_CONDITION,
				ClaimItemsValidationResultEnum.REJECTED_BY_RELATED_CONDITION,
				ClaimItemsValidationResultEnum.REJECTED_MULTIPLE].includes(this.validationResult);

			return this.sharedData.isReassessment && (isReject || isRejectedByPolicyLimits || this.isManuallyRejected
				|| this.isDuplicateClaim || this.isDuplicateInvoice);
		},
		async onNext () {
			if (this.isReadOnlyMode) {
				if (this.isLastStep && !this.wasIgnoredRejection) this.leavePage();
				else await this.goNext();
			} else if (this.status !== pageStatusEnum.Danger) {
				if (this.status === pageStatusEnum.Success) {
					await this.goNext();
				} else {
					if (this.isAffectOtherSteps) {
						const userChoice = await this.$refs.continueDialog.show();
						if (userChoice === 'yes') {
							try {
								await this.saveClaimItems(false, this.ignoreNoPolicyLimits); // custom error handling
								if (this.stepProceedAborted) {
									return;
								}
								this.isProcessDirty = false;
								if (this.ignoreNoPolicyLimits) {
									await this.goNext();
								} else {
									this.leavePage();
								}
							} catch (e) {
								await this.handleError(e);
							}
						} else if (userChoice === 'no') {
							this.claimItems = cloneDeep(this.originalStepData.claimItems);
							this.status = this.originalStepData.status;
							this.isProcessDirty = false;
						}
					} else {
						try {
							if (this.showOutcomesModal()) {
								const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(this.outcomeState);
								if (userChoice === 'no' || userChoice === 'cross') {
									return;
								}
							}
							await this.saveClaimItems(false, this.ignoreNoPolicyLimits, this.selectedReassessmentOutcome?.id);
							if (this.stepProceedAborted) {
								return;
							}
							this.isProcessDirty = false;
							if (this.ignoreNoPolicyLimits) {
								await this.goNext();
							} else {
								this.leavePage();
							}
						} catch (e) {
							await this.handleError(e);
						}
					}
				}
			}
		},
		onCancel () {
			this.leavePage();
		},
		async handleError (e) {
			if (isKnownError(e, ErrorCode.ClaimCannotMovedToStatus)) {
				await this.saveClaimItems(true);
				this.isProcessDirty = false;
				this.leavePage();
			} else {
				throw e;
			}
		},
		onReject (value) {
			this.rejectionData.isManuallyRejected = true;
			this.rejectionData.rejectionReasonId = value.reasonId;
			this.rejectionData.comment = value.comment;
			this.exitClaim();
		},
		onRejectManually () {
			this.$modal.show('reject-claim');
		},
		onFraudCheck (reasons, comment) {
			this.fraudCheck = true;
			this.fraudCheckReasons = reasons;
			this.fraudCheckComment = comment;
			this.exitClaim();
		},
		onPeriodChanged (value) {
			this.selectedPeriod = value;
		},
		onRowSelected (index) {
			this.onPeriodChanged(this.claimItems[index].treatmentPeriod);
		},
		onRowDeleted (index) {
			const cloneClaimItems = [...this.claimItems];
			cloneClaimItems.splice(index, 1);
			this.calculateClaimItem(cloneClaimItems).then(() => {
				this.isProcessDirty = true;
			});
		},
		onRowEdited (index) {
			this.editingIndex = index;
		},
		async saveDraft () {
			if (this.isReadOnlyMode
				|| this.rejectionData.isManuallyRejected
				|| this.fraudCheck
				|| this.fraudCheckFailed
				|| this.escalationReasonId) {
				return Promise.resolve();
			} else if (!this.isProcessDirty) {
				if (this.status === pageStatusEnum.Warning) return Promise.resolve();
				return this.saveClaimItems(true);
			} else {
				const userAction = await this.$refs.saveChangesDialog.show();
				if (userAction === 'yes') {
					return this.saveClaimItems(true);
				}
				if (userAction === 'no') return Promise.resolve();
				return Promise.reject(new Error('user click cross'));
			}
		},
		async beforeLeaveHandler (isNextStep) {
			if (this.isReadOnlyMode || this.ignoreNoPolicyLimits || this.escalationReasonId) return Promise.resolve();
			if (this.rejectionData.isManuallyRejected) {
				if (this.showOutcomesModal()) {
					const userChoice = await this.$refs.saveReassessmentOutcomeModal.show(this.outcomeState);
					if (userChoice === 'no' || userChoice === 'cross') {
						this.rejectionData.isManuallyRejected = false;
						this.rejectionData.rejectionReasonId = 0;
						this.rejectionData.comment = null;
						return Promise.reject(new Error('User canceled selecting reassessment outcome'));
					}
				}
				const saveClaimItems = await this.saveClaimItems(false, false, this.selectedReassessmentOutcome?.id, this.rejectionData);
				if (this.stepProceedAborted) {
					return Promise.reject(new Error('Page status was changed'));
				}
				return saveClaimItems;
			}
			if (this.fraudCheck) {
				const saveClaimItems = await this.saveClaimItems(false, false, this.selectedReassessmentOutcome?.id, null, { fraudCheck: this.fraudCheck, fraudCheckReasons: this.fraudCheckReasons, fraudCheckComment: this.fraudCheckComment });
				if (this.stepProceedAborted) {
					return Promise.reject(new Error('Page status was changed'));
				}
				return saveClaimItems;
			}
			if (isNextStep) {
				if (this.isAffectOtherSteps) {
					const userChoice = await this.$refs.continueDialog.show();
					if (userChoice === 'cross') return Promise.reject(new Error('user click cross'));
					if (userChoice === 'no') {
						this.claimItems = cloneDeep(this.originalStepData.claimItems);
						this.status = this.originalStepData.status;
						this.isProcessDirty = false;
						if (this.status === pageStatusEnum.Success) return Promise.resolve();
						else return Promise.reject(new Error('invalid data to continue'));
					}
				}
				const saveClaimItems = await this.saveClaimItems(false);
				if (this.stepProceedAborted) {
					return Promise.reject(new Error('Page status was changed'));
				}
				return saveClaimItems;
			} else {
				if (this.isAffectOtherSteps) {
					const userChoice = await this.$refs.continueDialog.show();
					if (userChoice === 'cross') return Promise.reject(new Error('user click cross'));
					if (userChoice === 'no') return Promise.resolve();
					if (userChoice === 'yes') {
						await this.saveClaimItems(true);
						if (this.status !== pageStatusEnum.Success) {
							const processStepData = this.getStepData(StepNameEnum.ClaimProcess);
							processStepData.isPassed = false;
						}
						return Promise.resolve();
					}
				}
				return this.saveClaimItems(true);
			}
		},
		checkNoPolicyLimitsError (validationResult) {
			return [
				ClaimItemsValidationResultEnum.REJECTED_SECTION_LIMIT,
				ClaimItemsValidationResultEnum.REJECTED_BY_CONDITION_LIMIT,
				ClaimItemsValidationResultEnum.REJECTED_BY_MULTIPLE_CONDITIONS].includes(validationResult);
		},
		getPageStatus () {
			switch (this.validationResult) {
			case ClaimItemsValidationResultEnum.INVALID:
			case ClaimItemsValidationResultEnum.CONDITIONS_CLAIM_ITEMS_COUNT_MISMATCH:
			case ClaimItemsValidationResultEnum.INVALID_TOTAL_AMOUNT: {
				return pageStatusEnum.Danger;
			}
			case ClaimItemsValidationResultEnum.REJECTED_SECTION_LIMIT:
			case ClaimItemsValidationResultEnum.REJECTED_BY_CONDITION_LIMIT:
			case ClaimItemsValidationResultEnum.REJECTED_BY_MULTIPLE_CONDITIONS:
				return this.isReadOnlyMode && this.wasIgnoredRejection ? pageStatusEnum.Success : pageStatusEnum.Warning;
			case ClaimItemsValidationResultEnum.REJECTED_BY_NO_POLICY_COVER:
			case ClaimItemsValidationResultEnum.REJECTED_BY_NO_PRODUCT_COVER:
			case ClaimItemsValidationResultEnum.REJECTED_BY_PREEXISTING_CONDITION:
			case ClaimItemsValidationResultEnum.REJECTED_BY_RELATED_CONDITION:
			case ClaimItemsValidationResultEnum.REJECTED_WAITING_PERIOD:
			case ClaimItemsValidationResultEnum.REJECTED_MULTIPLE:
			case ClaimItemsValidationResultEnum.REJECTED_MANUALLY: {
				return pageStatusEnum.Warning;
			}
			default: {
				return (this.isDuplicate
					|| (this.isPendingRejection && [claimNumberStatus.RejectedByDuplicateInvoice, claimNumberStatus.RejectedByDuplicateClaim].includes(this.sharedData.nextStatus))) ? pageStatusEnum.Warning : pageStatusEnum.Success;
			}
			}
		},
		populateHandler ({ items, fileId }) {
			const scannedItems = items;
			const scannedFileId = fileId;
			const newClaimItems = this.claimItems.filter(x => x.fileId !== scannedFileId);

			if (scannedItems && scannedItems.length > 0) {
				scannedItems.forEach(x => {
					newClaimItems.push(x);
				});
			}
			this.calculateClaimItem(newClaimItems).then(() =>
				this.changeCalculateClaimItemsOnPanelClose(true));
			this.$refs.claimForm.clearForm();
		},
		onAddClaimConditionFromInvoicePanel ({ newClaimCondition, policySections }) {
			this.conditions.push({
				id: newClaimCondition.id,
				description: toClaimConditionDescription(newClaimCondition),
			});
			this.policySections = policySections;
		},
		async onFraudChekActionFinish (leaveClaimPage) {
			await this.mountClaimPage();
			if (leaveClaimPage) {
				this.$router.back();
			}
		},
		onFraudCheckSelect () {
			this.$modal.show('fraud-check');
		},
		updateClaimStepState () {
			const claim = {
				claimItems: this.claimItems,
			};
			this.setClaimStepState(claim);
		},
		async onClaimEscalateSelect () {
			this.escalationReasonId = await this.onClaimEscalate(this.isProcessDirty);

			if (this.escalationReasonId) {
				try {
					await this.exitClaim();
					await ClaimService.escalate(this.claimId, this.escalationReasonId);
				} catch {
				}
				this.escalationReasonId = null;
			}
		},
		async onRemoveEscalation () {
			await this.exitClaim();
			await ClaimService.removeEscalation(this.claimId);
		},
		async validateDuplicate () {
			if (!this.appSettings.rejectDuplicate || this.isReadOnlyMode) {
				return;
			}

			const [duplicateInvoiceResult, duplicateClaimResult] = await Promise.all([ClaimService.validateDuplicateInvoice(this.claimData.claimId),
				ClaimService.validateDuplicateClaim(this.claimData.claimId, this.claimData.dateOfLoss, this.claimData.amount)]);

			this.duplicateInvoiceCheckResult = duplicateInvoiceResult;
			if (this.duplicateInvoiceCheckResult.isValid) {
				this.ignoreDuplicateInvoice = false;
			}

			this.duplicateClaimCheckResult = duplicateClaimResult;
			if (this.duplicateClaimCheckResult.isValid) {
				this.ignoreDuplicateClaim = false;
			}

			this.status = this.getPageStatus();
		},
		overrideRejection () {
			if (this.isDuplicateInvoice) {
				this.ignoreDuplicateInvoice = true;
			} else if (this.isDuplicateClaim) {
				this.ignoreDuplicateClaim = true;
			}
			this.status = this.getPageStatus();
		},
		cancelOverrideRejection () {
			this.ignoreDuplicateInvoice = false;
			this.ignoreDuplicateClaim = false;
			this.status = this.getPageStatus();
		},
		async saveClaimItems (saveAsDraft, ignoreNoPolicyLimits = false, reassessmentOutcomeId = null,
			rejectionData = { isManuallyRejected: false,	rejectionReasonId: null, comment: null },
			fraudCheckData = { fraudCheck: false, fraudCheckReasons: [], fraudCheckComment: null }) {
			this.stepProceedAborted = false;
			if (!saveAsDraft && !this.isManuallyRejected) {
				const isValidDuplicateInvoiceBeforeCheck = this.duplicateInvoiceCheckResult.isValid;
				const isValidDuplicateClaimBeforeCheck = this.duplicateClaimCheckResult.isValid;
				await this.validateDuplicate();
				if (isValidDuplicateClaimBeforeCheck !== this.duplicateClaimCheckResult.isValid
					|| isValidDuplicateInvoiceBeforeCheck !== this.duplicateInvoiceCheckResult.isValid) {
					this.stepProceedAborted = true;
					return;
				}
			}

			return await ClaimService.saveClaimItems(this.claimId, this.claimItems, saveAsDraft, ignoreNoPolicyLimits, this.ignoreDuplicateInvoice, this.ignoreDuplicateClaim, reassessmentOutcomeId, rejectionData, fraudCheckData)
				.then((claimStatus) => this.updateClaimStatus(claimStatus.value));
		},
	},
};
</script>

<style lang="scss">
.not-allowed-save-draft-tooltip {
  top: -8px !important;
}
</style>

<style lang="scss" scoped>
.mt-100 {
  margin-top: 100px;
}

::v-deep .claim-link {
  color: white;

  &:hover {
    color: darken($primary, 20%);
  }
}
</style>
