<template>
	<div>
		<template v-if="tempFile">
			<div class="font-weight-bold text-center text-white text-truncate">
				{{ tempFiles.length > 0 ? "Uploading" : "Added" }}
				{{ tempFile.fileName }}
			</div>
			<transition-group name="list-complete">
				<div
					v-for="fileItem in tempFiles"
					:key="fileItem.fileName"
					class="file-item"
				>
					<aq-progress
						:percentage="fileItem.percent"
						color-class="bg-primary"
						class="pt-6"
					/>
				</div>
			</transition-group>
		</template>
		<template v-else>
			<div class="doc-select">
				<input
					type="file"
					data-qa="fileUpload_input_addFile"
					style="display: none;"
					ref="docInput"
					@change="onDocumentPicked"
					@click="clearInput"
					:multiple="false"
					:accept="acceptFileTypes"
				>
			</div>
			<form
				data-qa="fileUpload_button_addFile"
				ref="fileform"
				:class="[
					'drag-zone',
					{ 'dropzone-over': dragging, error: fileUploadValidationError },
				]"
				@mouseenter="activeHover = true"
				@mouseleave="activeHover = false"
				@dragenter="dragging = true"
				@dragleave="dragging = false"
				@click="onPickDocuments"
				v-show="isUploadExpanded"
			>
				<div class="upload-icon">
					<span class="message-holder">
						{{ infoMessage }}
					</span>
					<i class="fas fa-cloud-upload-alt" />
				</div>
			</form>
		</template>
	</div>
</template>

<script>
import { getFileExtension } from '@commonServices/utils/general';
import { mapState } from 'vuex';

const UploadFileErrorTimeout = 4000;

export default {
	name: 'AqFileUpload',
	props: {
		claimId: {
			type: Number,
			required: false,
			default: null,
		},
		tempFile: {
			type: Object,
			required: false,
			default: null,
		},
		tempFiles: {
			type: Array,
			required: false,
			default: null,
		},
		isUploadExpanded: {
			type: Boolean,
			required: false,
		},
	},
	data () {
		return {
			dragging: false,
			isDragAndDropCapable: false,
			dragEvents: [
				'drag',
				'dragstart',
				'dragend',
				'dragover',
				'dragenter',
				'dragleave',
				'drop',
			],
			uploadedFiles: [],
			multipleDocs: false,
			invalidDrag: false,
			fileIsOverMaxLimit: false,
			activeHover: false,
		};
	},
	computed: {
		...mapState(['appSettings']),
		acceptFileTypes () {
			return this.appSettings.allowedFileExtentions.join(', ');
		},
		infoMessage () {
			if (this.dragging) {
				return 'Release click to upload';
			}
			if (this.activeHover) {
				return 'Click to browse';
			}
			if (this.multipleDocs) {
				return 'Multiple documents not allowed';
			}
			if (this.invalidDrag) {
				return 'Invalid file type';
			}
			if (this.fileIsOverMaxLimit) {
				return `Upload Error: File greater than ${(this.appSettings.maxRequestSize / 1024 / 1024).toFixed(0)}mb`;
			}
			return 'Drag files here';
		},
		fileUploadValidationError () {
			return this.multipleDocs || this.invalidDrag || this.fileIsOverMaxLimit;
		},
	},
	updated () {
		this.isDragAndDropCapable = this.dragAndDropCapable();

		if (this.isDragAndDropCapable) {
			this.dragEvents.forEach((event) => {
				this.$refs.fileform.addEventListener(event, (e) => {
					e.preventDefault();
					e.stopPropagation();
				});
			});

			this.$refs.fileform.addEventListener('drop', (e) => {
				this.dragging = false;
				const files = e.dataTransfer.files;
				if (files.length > 1) {
					this.multipleDocs = true;

					setTimeout(() => {
						this.multipleDocs = false;
					}, UploadFileErrorTimeout);
				} else if (files.length === 1 && this.isValidFile(files[0])) {
					this.addFiles(files[0]);
				} else {
					this.invalidDrag = true;
					setTimeout(() => {
						this.invalidDrag = false;
					}, UploadFileErrorTimeout);
				}
			});
		}
	},
	methods: {
		dragAndDropCapable () {
			const dropzone = this.$refs.fileform;
			if (dropzone) {
				return (
					('draggable' in dropzone
            || ('ondrop' in dropzone && 'ondragstart' in dropzone))
          && 'FormData' in window
          && 'FileReader' in window
				);
			}
			return false;
		},
		addFiles (file) {
			if (file.size > this.appSettings.maxRequestSize) {
				// by default configured to 50 MB
				this.fileIsOverMaxLimit = true;
			} else {
				this.fileIsOverMaxLimit = false;
				this.$emit('file-added', file);
			}
		},
		onDocumentPicked (event) {
			this.addFiles(event.target.files[0]);
		},
		clearInput (event) {
			event.target.value = null;
		},
		onPickDocuments () {
			this.$refs.docInput.click();
		},
		isValidFile (file) {
			const fileExtention = getFileExtension(file.name);
			return this.acceptFileTypes.includes(fileExtention);
		},
	},
};
</script>

<style lang="scss" scoped>
.drag-zone * {
  pointer-events: none;
}

.drag-zone {
  display: block;
  border: 2px dashed rgba($gray, 0.3);
  border-radius: 50%;
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  transition: 0.2s ease;
  cursor: pointer;

  .upload-icon {
    position: absolute;
    font-size: 120px;
    inset: -45px 0 0;
    margin: auto;
    width: 151px;
    height: 119px;
    line-height: 129px;
    color: $primary;
    transition: 0.2s ease;
  }

  .message-holder {
    color: $body-color;
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 100%;
    padding-top: 90%;
    line-height: 140%;
  }

  &:hover {
    border-color: $body-color;
    color: $body-color;
  }

  &:active {
    border-color: $body-color;

    .upload-icon {
      color: $active-color;
    }
  }

  &.error {
    .upload-icon {
      color: lighten($danger, 30%);
    }
  }

  &.dropzone-over {
    border-color: $body-color;
    background-color: rgba(darken($dark, 30%), 0.2);

    .upload-icon {
      color: $active-color;
    }
  }
}

// animation
.file-item {
  transition: all 0.4s;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .view-file {
    margin: 0 0 0 20px !important;
  }
}

.list-complete-enter,
.list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>
