<template>
	<div class="wrapper">
		<div
			v-if="source"
			class="wrapper-tools"
		>
			<span v-tooltip="'Open in a new tab'">
				<a
					target="_blank"
					@click="openUrl(source)"
					class="btn btn-secondary cursor-pointer"
				>
					<i class="fas fa-external-link-alt" />
				</a>
			</span>
			<slot name="tools"/>
		</div>
		<div class="h-100 scrollbar px-10 position-relative">
			<div class="d-flex flex-column">
				<Markdown :content="content" />
			</div>
		</div>
	</div>
</template>

<script>
import Markdown from './Markdown';
export default {
	components: {
		Markdown,
	},
	props: {
		content: {
			type: String,
			required: true,
		},
		source: {
			type: String,
			required: false,
		},
	},
	methods: {
		openUrl (src) {
			window.open(src);
		},
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: white;
  height: 100%;

  .wrapper-tools {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid var(--bodyBg);
    background-color: rgba(var(--modalBg), 0.95);
    padding: 5px;
    gap: 5px;

    .btn {
      width: 35px;
      height: 33px;
      line-height: 33px;
      padding: 0;
      font-size: 17px;
    }
  }

  &.with-header {
    height: calc(100% - 63px);
  }
}

.scrollbar {
  overflow: hidden auto;
  height: calc(100% - 70px) !important
}
</style>
