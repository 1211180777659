<template>
	<div class="d-flex flex-wrap searh-term__container">
		<aq-search-term
			v-for="(term, index) of terms"
			:key="index"
			:header="term.label"
			:value="term.value"
			:removable="term.removable"
			:excluded="term.excluded"
			header-class="text-primary"
			class="px-10 py-6 mt-5 mr-5 search-term__rounded"
			@remove="onRemoveSearchTerm"
			data-qa="text_searchTerm"
		/>
	</div>
</template>

<script>
import { removeFilter } from '@commonServices/filterServices';

const sortAndChartLabels = ['Aggregate', 'Column', 'Group By', 'Sort By', 'Then By'];

export default {
	name: 'AqSearchTermContainer',
	props: {
		searchTerms: {
			type: Array,
			required: false,
			default: () => [],
		},
		filter: {
			type: Object,
			required: true,
		},
		removable: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	computed: {
		terms () {
			return this.searchTerms.map(searchTerm => ({
				...searchTerm,
				removable: this.removable && !sortAndChartLabels.includes(searchTerm.label),
			}));
		},
	},
	methods: {
		onRemoveSearchTerm (term) {
			try {
				removeFilter(this.filter, term.header);
			} finally {
				this.$emit('remove-search-term');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .searh-term {
    color: var(--bodyColour);

    &__container {
      background: var(--contentBg);
    }

    &__rounded {
      border-radius: 4px;
    }
  }
</style>
