<template>
	<div>
		<aq-modal-container
			@close="$modal.hide($parent.name)"
			title="Split treatment line into multiple conditions"
			modal-width="1200"
		>
			<div class="modal-content w-100">
				<div class="container p-0 mb-35">
					<div class="no-gutters">
						<div class="row pt-30 d-flex align-items-center">
							<span class="text-bold ml-15 pr-0">Enter number of copies (max 5):</span>
							<aq-max-min-number-input
								data-qa="treatmentLinesModal_input_copiesNumber"
								class="ml-10"
								:min-number="0"
								:max-number="5"
								:value="copiesNumber"
								input-class="control-container copies-number-input"
								@keyup.enter.native="copiesNumber = $event.target.valueAsNumber"
							/>
						</div>
						<div class="horizontal-rule mt-15" />
						<div :class="{'transparent': !copiesNumber}">
							<div class="row mb-15 mt-20">
								<div class="col-2 text-bold">
									Original treatment line:
								</div>
								<div class="col-2 offset-8 ">
									<aq-checkbox
										data-qa="treatmentLinesModal_checkbox_splitAmountsEqually"
										label="Split amounts equally"
										:disabled="!copiesNumber"
										:value="splitAmountsEqually"
										@input="onSplitAmountsEqually"
									/>
								</div>
							</div>
							<div
								v-if="originalLineItem"
								class="row ml-1 mr-1 pl-10 pr-10 pt-5 pb-5"
								:class="{'border-treatment-line transparent': !copiesNumber}"
							>
								<aq-read-only-input
									class="col-3 mr-10"
									label="Treatment"
									:description="`${originalLineItem.quantity.value} x ${originalLineItem.description.value}`"
								/>
								<aq-read-only-input
									class="col-2 mr-10"
									label="Ailment"
									:description="originalLineItem.claimCondition.value.description"
								/>
								<aq-read-only-input
									class="col-2 mr-10"
									label="Policy Section"
									:description="originalLineItem.policySection.value.description"
								/>
								<aq-read-only-input
									class="col mr-10"
									label="Tax"
									type="money"
									:locale="locale"
									:description="originalLineItem.tax.value"
								/>
								<aq-read-only-input
									class="col mr-10"
									label="Discount"
									type="money"
									:locale="locale"
									:description="originalLineItem.discountAmount.value"
								/>
								<aq-read-only-input
									class="col"
									label="Amount"
									type="money"
									:locale="locale"
									:description="originalLineItem.amount.value"
								/>
							</div>
						</div>
						<div v-if="copiesNumber">
							<div class="mt-15 mb-15 dashed-line" />
							<div
								v-for="(lineItemCopy, index) of lineItemCopies"
								:key="index"
								:class="[ index === hoveredLineItemCopy ? 'border-treatment-line' : 'border-invisible' ]"
								class="d-flex align-items-center row pl-10 pr-10 pt-5 pb-5 mt-2 mb-5 ml-1 mr-1 treatment-line"
								@mouseover="selectItem(index)"
								@mouseleave="unselectItem"
							>
								<aq-read-only-input
									class="col-3 mr-10"
									label="Treatment"
									:description="`${lineItemCopy.quantity.value} x ${lineItemCopy.description.value}`"
								/>
								<aq-select
									data-qa="treatmentLinesModal_select_ailmentCopy"
									class="col-2 mr-10"
									:options="conditions"
									label="Ailment"
									option-label="description"
									track-by="id"
									searchable
									:is-valid="!$v.lineItemCopies.$each.$iter[index].claimCondition.value.$error"
									v-model="lineItemCopy.claimCondition.value"
									no-result-message="No Ailment found"
									@input="onSelectAilment(index, ...arguments)"
								/>
								<aq-select
									data-qa="treatmentLinesModal_select_policySectionCopy"
									class="col-2 mr-10"
									:options="conditionPolicySections"
									label="Policy Section"
									option-label="description"
									track-by="id"
									searchable
									:is-valid="!$v.lineItemCopies.$each.$iter[index].policySection.value.$error"
									v-model="lineItemCopy.policySection.value"
									no-result-message="No Policy Section found"
									@input="$v.lineItemCopies.$each.$iter[index].policySection.value.$touch"
								/>
								<aq-form-input
									data-qa="treatmentLinesModal_input_discountCopy"
									class="col mr-10"
									label="Tax"
									icon-class="text-primary fa-lg"
									type="money"
									:locale="locale"
									:disabled="splitAmountsEqually"
									v-model="lineItemCopy.tax.value"
								/>
								<aq-form-input
									data-qa="treatmentLinesModal_input_discountCopy"
									class="col mr-10"
									label="Discount"
									icon-class="text-primary fa-lg"
									type="money"
									:locale="locale"
									:disabled="splitAmountsEqually"
									v-model="lineItemCopy.discountAmount.value"
								/>
								<aq-form-input
									data-qa="treatmentLinesModal_input_amountCopy"
									class="col"
									label="Amount"
									icon-class="text-primary fa-lg"
									type="money"
									:locale="locale"
									:disabled="splitAmountsEqually"
									v-model="lineItemCopy.amount.value"
								/>
								<div
									data-qa="treatmentLinesModal_button_removeCopy"
									v-if="index === hoveredLineItemCopy"
									class="circle-btn yellow d-flex align-items-center"
									@click="onDelete(index)"
									v-tooltip="{
										content: 'Delete Item',
										classes: [ 'tooltip-orange' ]
									}"
								>
									<i class="aqv-delete m-auto" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-bottom d-flex align-items-center px-20 py-10">
					<button
						data-qa="treatmentLinesModal_button_cancel"
						type="button"
						@click="onCancel"
						class="btn btn-secondary"
					>
						Cancel
					</button>
					<button
						data-qa="treatmentLinesModal_button_create"
						type="button"
						@click="onCreate"
						:disabled="!copiesNumber || !areLineItemsCopiesValid"
						class="btn btn-success ml-auto"
					>
						Create
					</button>
				</div>
			</div>
		</aq-modal-container>
	</div>
</template>

<script>
import AqMaxMinNumberInput from '@commonView/Shared/AqMaxMinNumberInput';
import AqReadOnlyInput from '@commonView/Shared/AqReadOnlyInput';
import cloneDeep from 'lodash.clonedeep';
import { required } from 'vuelidate/lib/validators';

export default {
	components: {
		AqMaxMinNumberInput,
		AqReadOnlyInput,
	},
	data () {
		return {
			originalLineItem: null,
			hoveredLineItemCopy: null,
			copiesNumber: 0,
			originalLineItemCopies: [],
			lineItemCopies: [],
			splitAmountsEqually: false,
			conditionPolicySections: [],
		};
	},
	props: {
		selectedLineItem: {
			type: Object,
			required: true,
		},
		policySections: {
			type: Array,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	mounted () {
		this.originalLineItem = cloneDeep(this.selectedLineItem);
		this.conditionPolicySections = this.policySections;
	},
	validations () {
		return {
			lineItemCopies: {
				$each: {
					claimCondition: {
						value: {
							required,
						},
					},
					policySection: {
						value: {
							required,
						},
					},
				},
			},
		};
	},
	methods: {
		onCreate () {
			this.$emit('create-line-item-copies', this.lineItemCopies, this.originalLineItem);
			this.$modal.hide(this.$parent.name);
		},
		onCancel () {
			this.$modal.hide(this.$parent.name);
		},
		onDelete (index) {
			this.lineItemCopies.splice(index, 1);
			this.copiesNumber--;
			this.onSplitAmountsEqually();
		},
		onSplitAmountsEqually () {
			this.splitAmountsEqually = !this.splitAmountsEqually;
			if (!this.splitAmountsEqually) {
				this.lineItemCopies = this.originalLineItemCopies;
				this.originalLineItem.amount.value = this.selectedLineItem.amount.value;
				this.originalLineItem.discountAmount.value = this.selectedLineItem.discountAmount.value;
				this.originalLineItem.tax.value = this.selectedLineItem.tax.value;
				return;
			}
			const splitAmounts = this.calculateSplitAmounts(this.originalLineItem.amount.value);
			const splitDiscounts = this.calculateSplitAmounts(this.originalLineItem.discountAmount.value);
			const splitTaxes = this.calculateSplitAmounts(this.originalLineItem.tax.value);
			this.originalLineItem.amount.value = splitAmounts.original;
			this.originalLineItem.discountAmount.value = splitDiscounts.original;
			this.originalLineItem.tax.value = splitTaxes.original;
			this.originalLineItemCopies = this.lineItemCopies;
			this.lineItemCopies = this.lineItemCopies.map(c => {
				return {
					...c,
					amount: {
						...c.amount,
						value: splitAmounts.copy,
					},
					discountAmount: {
						...c.discountAmount,
						value: splitDiscounts.copy,
					},
					tax: {
						...c.tax,
						value: splitTaxes.copy,
					},
				};
			});
		},
		selectItem (item) {
			this.hoveredLineItemCopy = item;
		},
		unselectItem () {
			this.hoveredLineItemCopy = null;
		},
		calculateSplitAmounts (originalAmount) {
			const amount = originalAmount / (this.copiesNumber + 1);
			const splitOriginal = Math.ceil(amount * 100) / 100;
			const splitCopy = Math.floor(amount * 100) / 100;
			return {
				original: splitOriginal,
				copy: splitCopy,
			};
		},
		onSelectAilment (index, newValue) {
			this.$v.lineItemCopies.$each.$iter[index].claimCondition.value.$touch();
			this.$v.lineItemCopies.$each.$iter[index].policySection.value.$model = null;
			this.conditionPolicySections = this.policySections.filter(ps => ps.claimConditionId === newValue?.id);
		},
		defaultValues () {
			this.selectedLineItem.amount.value = this.selectedLineItem.amount.value || 0;
			this.selectedLineItem.discountAmount.value = this.selectedLineItem.discountAmount.value || 0;
			this.selectedLineItem.tax.value = this.selectedLineItem.tax.value || 0;
		},
	},
	computed: {
		areLineItemsCopiesValid () {
			return Object.values(this.$v.lineItemCopies.$each.$iter)
				.every((item) => {
					return !item.claimCondition.value.$error && !item.policySection.value.$error;
				});
		},
	},
	watch: {
		copiesNumber (newValue) {
			this.splitAmountsEqually = false;
			this.defaultValues();
			const copies = new Array(newValue);
			for (let i = 0; i < newValue; i++) {
				copies[i] = cloneDeep(this.selectedLineItem);
			}
			this.lineItemCopies = copies;
			this.originalLineItem = cloneDeep(this.selectedLineItem);
		},
	},
};
</script>

<style lang="scss" scoped>
.dashed-line {
  border-bottom: 3px dashed #d7f0ff;
}

.treatment-line {
  position: relative;
}

.border-treatment-line {
  border: 1px solid #fc7602;
}

.border-invisible {
  border: 1px solid transparent;
}

.circle-btn {
  position: absolute;
  right: -17px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 31px;
  cursor: pointer;

  &.yellow {
    color: #ffff;
    background-color: #fc7602;

    &:hover {
      color: #ffff;
      background-color: darken(#fc7602, 10%);
    }
  }
}

::v-deep .copies-number-input {
  width: 55px;
  min-height: 38px;
  border: none;
  outline: none;
  resize: none;
  cursor: text;
}

.horizontal-rule {
  border-bottom: 3px solid #d7f0ff;
}

.transparent {
  opacity: 0.71;
}
</style>
