<template>
	<div class="d-flex searh-term">
		<div class="text-truncate">
			<p
				v-if="header"
				class="fs-12 searh-term_header"
				:class="headerClass"
			>
				{{ header }}
			</p>

			<div
				class="fs-14 searh-term_value"
				:class="valueClass"
			>
				<template v-if="Array.isArray(value)">
					<div
						class="text-truncate"
						v-for="item in value"
						:key="item"
					>
						{{ item }}
					</div>
				</template>
				<template v-else>
					{{ value }}
				</template>
			</div>
		</div>
		<div
			v-if="removable"
			class="icon fs-18 ml-20 my-auto p-4"
			:class="iconClass"
			@click="onRemove"
			data-qa="searchTerm_button_remove"
		>
			&times;
		</div>
		<div
			v-if="excluded != null"
			class="badge-container"
			:class="{ 'excluded': excluded}"
		>
			<div class="badge">
				<i class="fa-solid" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AqSearchTerm',
	props: {
		header: {
			type: String,
			required: false,
		},
		value: {
			type: [String, Array],
			required: true,
		},
		headerClass: {
			type: String,
			required: false,
			default: 'text-warning',
		},
		valueClass: {
			type: String,
			required: false,
		},
		iconClass: {
			type: String,
			required: false,
			default: 'text-primary',
		},
		removable: {
			type: Boolean,
			required: false,
			default: true,
		},
		excluded: {
			type: Boolean,
			required: false,
			default: null,
		},
	},
	methods: {
		onRemove () {
			this.$emit('remove', { header: this.header, value: this.value });
		},
	},
};
</script>

<style lang="scss" scoped>
  .searh-term {
    color: var(--bodyColour);
    max-width: 400px;
    background: var(--attributeBg);
    position: relative;
    overflow: hidden;

    p {
      color: var(--bodyColour);
    }

    .icon:hover {
      cursor: pointer;
      opacity: 0.75;
      transition: all 0.2s;
    }

    .badge-container {
      width: 35px;
      position: absolute;
      top: 0;
      right: 0;

      .badge {
        opacity: 0.5;
        background-color: var(--filterBadgeOne);
        width: 56px;
        height: 56px;
        position: absolute;
        right: -24px;
        top: -32px;
        transform: rotate(38deg);

        i {
          font-size: 17px;
          color: #87B27F;
          transform: rotate(-38deg);
          position: absolute;
          top: 36px;
          left: 24px;

          &::before {
            content: "\f055";
          }
        }
      }

      &.excluded {
        .badge {
          background-color: var(--filterBadgeTwo);

          i {
            color: #DC6E6C;

            &::before {
              content: "\f056";
            }
          }
        }
      }
    }
  }
</style>
