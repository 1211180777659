<template>
	<div class="mx-auto d-flex align-items-center">
		<div class="section-header__title">
			<span v-if="viewMode">My Tasks</span>
		</div>
		<div
			class="toggle-btn d-flex"
			v-if="viewMode"
		>
			<ListView
				@click="switchView(TaskViewModeEnum.List)"
				class="list pr-10"
				:class="{ 'active-toggle' : viewMode === TaskViewModeEnum.List }"
			/>
			<CardView
				@click="switchView(TaskViewModeEnum.Card)"
				class="card align-self-start"
				:class="{ 'active-toggle' : viewMode === TaskViewModeEnum.Card }"
			/>
		</div>
		<button
			@click="onGetTask"
			v-if="isVisible && viewMode"
			class="btn btn-primary px-50 mx-10 position-absolute"
			:disabled="!getTaskAvailable"
		>
			Get Task
			<div class="plus-button position-absolute">
				<i class="fas fa-plus text-white" />
			</div>
		</button>
	</div>
</template>

<script>
import { CardView, ListView } from '@assets/icons';
import { TaskViewModeEnum } from '@commonServices/settings/taskViewSettings';

export default {
	name: 'TasksSandwichHeader',
	components: {
		ListView,
		CardView,
	},
	emits: ['get-task', 'toggle-task-view'],
	props: {
		getTaskAvailable: {
			type: Boolean,
			required: true,
		},
		viewMode: {
			type: String,
			required: false,
			default: '',
		},
	},
	data () {
		return {
			getTaskBtnAvailable: true,
			isVisible: true,
			TaskViewModeEnum,
		};
	},
	methods: {
		onGetTask () {
			this.$emit('get-task');
		},
		switchView (mode) {
			if (this.viewMode !== mode) {
				this.$emit('toggle-task-view', mode);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
  .plus-button {
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    border: 2px solid $body-color;
    border-radius: 50%;
    transform: scale(0.7);

    .fa-plus {
      font-size: 18px;
      line-height: 35px;
    }
  }

  .btn-primary {
    right: 1rem;
    letter-spacing: 0.5px;
    font-size: 16px;
    border-radius: 10px 50px 50px 10px;
  }

  .toggle-btn {
    position: absolute;
    right: 13rem;
    cursor: pointer;

    .active-toggle {
      color: $body-color;
      cursor: default;
    }
  }

  .list {
    height: 25px;
    color: var(--cardIconColour);

    &:hover:not(.active-toggle) {
      color: var(--subSectionColour);
    }
  }

  .card {
    height: 14px;
    color: var(--cardIconColour);

    &:hover:not(.active-toggle) {
      color: var(--subSectionColour);
    }
  }

</style>
