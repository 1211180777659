<template>
	<div>
		<div class="section-header pl-45">
			<div :class="{ 'col-7': showCurrencyConversionWarning }">
				<template v-if="editingIndex === -1">
					<div class="section-header__title">
						Add a claim item
					</div>
					<p>Add the details of a new claim item</p>
				</template>
				<template v-else>
					<div class="section-header__title">
						Update a claim item
					</div>
					<p>Update the details of an existing claim item</p>
				</template>
			</div>
			<div
				v-if="showCurrencyConversionWarning"
				class="d-flex align-items-center col-5 badge"
			>
				<i class="fas fa-exclamation-triangle fs-24" />
				<div class="ml-9">
					Conversion to <span class="font-weight-bold">{{ customerCurrencyCode }}</span> required
				</div>
				<div class="badge-fold" />
			</div>
		</div>
		<div class="section-content ml-25">
			<div class="container-fluid">
				<div class="row no-gutters mb-18">
					<div class="col-12">
						<aq-highlight-border-with-icon
							:highlight-color="conditionHighlightColor"
							icon="fa-exclamation"
							icon-size="small"
							icon-circle
							background-highlight
							shadow
						>
							<aq-select
								:options="conditions"
								label="Ailment"
								option-label="description"
								track-by="id"
								searchable
								:is-valid="!$v.newClaimItem.selectedCondition.$error && $v.newClaimItem.unique"
								:disabled="isReadOnlyMode || !multicondition"
								:is-sorted="false"
								v-model="newClaimItem.selectedCondition"
								@input="onConditionChanged"
								no-result-message="No Ailment found"
								data-qa="claimItemForm_select_condition"
							/>
							<template slot="tooltipContent">
								No defined condition
							</template>
						</aq-highlight-border-with-icon>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem"
							:validator="$v.newClaimItem"
							property="selectedCondition"
						/>
					</div>
				</div>
				<div class="row no-gutters mb-18">
					<div class="col-12">
						<aq-select
							:options="conditionPolicySections"
							label="Policy Section"
							option-label="description"
							track-by="id"
							searchable
							:is-valid="!$v.newClaimItem.selectedPolicySection.$error && $v.newClaimItem.unique"
							:disabled="isReadOnlyMode"
							v-model="newClaimItem.selectedPolicySection"
							@input="onPolicySectionChanged"
							no-result-message="No Policy Section found"
							data-qa="claimItemForm_select_policySection"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem"
							:validator="$v.newClaimItem"
							property="selectedPolicySection"
						/>
					</div>
				</div>
				<div class="row no-gutters">
					<div class="col-6 pr-18">
						<aq-select
							:options="treatmentPeriods"
							label="Treatment Period"
							:is-valid="!$v.newClaimItem.selectedTreatmentPeriod.$error && $v.newClaimItem.unique"
							:disabled="isReadOnlyMode"
							v-model="newClaimItem.selectedTreatmentPeriod"
							no-result-message="No Treatment Period found"
							data-qa="claimItemForm_select_treatmentPeriod"
						>
							<template #default="props">
								<div class="d-flex align-items-center">
									<div :class="getTreatmendPeriodLabelClass(props.option.treatmentPeriodType)">
										{{ getTreatmentPeriodLabel(props.option.treatmentPeriodType) }}
									</div>
									<div>
										{{ props.option.start | shortDate }} -
										{{ props.option.end | shortDate }}
									</div>
								</div>
							</template>
							<template	#singleLabel="props">
								<div
									:class="['multiselect__single d-flex align-items-center', ]"
									v-if="newClaimItem.selectedTreatmentPeriod && props.option"
								>
									<div :class="getTreatmendPeriodLabelClass(props.option.treatmentPeriodType)">
										{{ getTreatmentPeriodLabel(props.option.treatmentPeriodType) }}
									</div>
									<div>
										{{ props.option.start | shortDate }} -
										{{ props.option.end | shortDate }}
									</div>
								</div>
							</template>
						</aq-select>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem"
							:validator="$v.newClaimItem"
							property="selectedTreatmentPeriod"
						/>
					</div>
					<div class="col-6 pl-18">
						<aq-highlight-border-with-icon
							:highlight-color="showCurrencyConversionWarning ? 'warning' : null"
							border-style="bold"
						>
							<aq-calc-input
								ref="amountInput"
								label="Claim Amount"
								icon-class="text-primary fa-lg"
								:locale="locale"
								:value="newClaimItem.amount"
								:value-expression="newClaimItem.amountExpression"
								:is-valid="!$v.newClaimItem.amount.$error && !$v.newClaimItem.amountExpression.$error"
								@input="onAmountUpdated(newClaimItem, $event)"
								:disabled="isReadOnlyMode"
								data-qa="claimItemForm_input_amount"
							/>
						</aq-highlight-border-with-icon>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem"
							:validator="$v.newClaimItem"
							property="amount"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem"
							:validator="$v.newClaimItem"
							property="amountExpression"
						/>
					</div>
				</div>
				<div
					v-if="newClaimItem.selectedPolicySection && newClaimItem.selectedPolicySection.hasNonFinancialLimit"
					class="row no-gutters mt-14"
				>
					<div class="col-6 pr-18">
						<aq-form-input
							:label="nonFinancialNumberLabel"
							icon-class="text-primary fa-lg"
							type="number"
							v-model="newClaimItem.nonFinancialNumber"
							@input="$v.newClaimItem.nonFinancialNumber.$touch"
							:is-valid="!$v.newClaimItem.nonFinancialNumber.$error"
							:disabled="isReadOnlyMode"
							data-qa="claimItemForm_input_nonFinancialNumber"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem"
							:validator="$v.newClaimItem"
							property="nonFinancialNumber"
						/>
					</div>
				</div>
				<div
					class="row no-gutters"
					v-if="!$v.newClaimItem.unique"
				>
					<div class="col-12 mt-10 text-danger">
						{{ errorMessages.newClaimItem.unique }}
					</div>
				</div>
				<div v-if="newClaimItem.deductions.length > 0">
					<div class="mt-30 text-subtitle fs-12">
						User Deduction
					</div>
				</div>
				<div
					v-for="(deduction, index) in newClaimItem.deductions"
					:key="index"
					class="row no-gutters mt-12 pb-6"
				>
					<div class="col-7 pr-18">
						<aq-select
							:options="conditionDeductionTypes"
							label="Deduction Type"
							option-label="description"
							track-by="id"
							v-model="newClaimItem.deductions[index].type"
							:is-valid="!$v.newClaimItem.deductions.$each.$iter[index].type.$error"
							@input="touchDeduction(index,'type')"
							no-result-message="No Deduction Type found"
							data-qa="claimItemForm_select_deductionType"
						/>
						<aq-form-input-error
							class="error mt-4"
							:error-messages="errorMessages.newClaimItem.deductions"
							:validator="$v.newClaimItem.deductions.$each.$iter[index]"
							property="type"
						/>
					</div>
					<div class="col-4 pl-18">
						<aq-calc-input
							label="Amount"
							icon-class="text-primary fa-lg"
							:locale="locale"
							:value="newClaimItem.deductions[index].amount"
							:value-expression="newClaimItem.deductions[index].amountExpression"
							:is-valid="$v.newClaimItem.deductions.$each.$iter[index] && !$v.newClaimItem.deductions.$each.$iter[index].amount.$error && !$v.newClaimItem.deductions.$each.$iter[index].amountExpression.$error"
							@input="onDeductionAmountUpdated(newClaimItem.deductions[index], index, $event)"
							:disabled="isReadOnlyMode"
							data-qa="claimItemForm_input_deductionAmount"
						/>
						<aq-form-input-error
							class="error mt-4 position-relative"
							:error-messages="errorMessages.newClaimItem.deductions"
							:validator="$v.newClaimItem.deductions.$each.$iter[index]"
							property="amount"
						/>
						<aq-form-input-error
							class="error mt-4 position-relative"
							:error-messages="errorMessages.newClaimItem.deductions"
							:validator="$v.newClaimItem.deductions.$each.$iter[index]"
							property="amountExpression"
						/>
					</div>
					<div class="col-1 pl-6 align-self-start mt-16">
						<button
							class="d-block btn small btn-secondary ml-auto"
							type="button"
							@click="removeDeduction(index)"
							data-qa="claimItemForm_button_removeDeduction"
						>
							<i class="fas fa-times" />
						</button>
					</div>
				</div>
				<div class="row no-gutters mt-30">
					<div class="col d-flex">
						<div class="ml-auto">
							<button
								class="btn btn-secondary"
								type="button"
								:disabled="isReadOnlyMode || $v.newClaimItem.$invalid"
								@click="onAddDeduction"
								data-qa="claimItemForm_button_addDeduction"
							>
								Add User Deduction
							</button>
						</div>
					</div>
				</div>
				<div class="row no-gutters mt-30">
					<div class="col d-flex">
						<div class="ml-auto">
							<button
								class="btn btn-secondary mr-14"
								type="button"
								@click="onCancel"
								v-if="editingIndex >= 0"
								data-qa="claimItemForm_button_cancel"
							>
								Cancel
							</button>
							<button
								class="btn btn-primary"
								type="button"
								:disabled="isReadOnlyMode || $v.newClaimItem.$invalid"
								@click="onNewClaimItem"
								data-qa="claimItemForm_button_save"
							>
								{{ editingIndex === -1 ? 'Done' : 'Update' }}
							</button>
						</div>
					</div>
				</div>
				<div v-if="showOptions">
					<aq-sandwich
						ref="sandwich"
						header="Options"
						active-class=""
						header-class="fs-14 options-sub-header"
						section-header="options-header d-flex"
						data-qa="claimItemForm_sandwich_rebalanceExcess"
					>
						<template slot="content">
							<div class="options-content">
								<aq-checkbox
									class="tick"
									label="Excess Rebalance"
									data-qa="claimItemForm_checkbox_rebalanceExcess"
									:disabled="isReadOnlyMode"
									v-model="newClaimItem.rebalanceExcess"
								/>
							</div>
						</template>
					</aq-sandwich>
				</div>
			</div>
		</div>
		<div class="claim-amount">
			<div class="row no-gutters">
				<div
					class="col-9 p-20"
					:class="[isRemainingAmountZeroed ? 'bg-success-darken' : 'bg-danger-darken']"
				>
					Claim Amount
				</div>
				<div
					class="col-3 p-20 text-right"
					:class="[isRemainingAmountZeroed ? 'bg-success' : 'bg-danger']"
				>
					{{ remainingAmount | currency(locale) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required, requiredIf, minValue } from 'vuelidate/lib/validators';
import { moreThen, unique, maxAmount, isMathExpression } from '@commonServices/utils/validators';
import { ValidationMessages, DiagnosisNotMade } from '@commonServices/utils/constants';
import { capitalize, pluralize } from '@commonServices/utils/filters';
import deductionService from '@commonServices/deductionService';
import { areNumbersEqual } from '@commonServices/utils/general';
import { areDatesEqual } from '@commonServices/utils/dateUtils';
import AqHighlightBorderWithIcon from '@commonWidgets/AqHighlightBorderWithIcon';
import { mapState } from 'vuex';

export default {
	components: {
		AqHighlightBorderWithIcon,
	},
	data () {
		return {
			newClaimItem: {
				amount: 0,
				amountExpression: null,
				selectedCondition: null,
				selectedPolicySection: undefined,
				selectedTreatmentPeriod: undefined,
				nonFinancialNumber: null,
				rebalanceExcess: false,
				deductions: [],
			},
			isVetFeesAssigned: false,
			errorMessages: {
				newClaimItem: {
					unique: `Claim item with the same ${this.multicondition ? 'ailment, ' : ''}policy section and treatment period already exists`,
					amount: {
						required: ValidationMessages.required,
						minValue: ValidationMessages.noNegative,
						maxAmount: ValidationMessages.maxAmount,
					},
					amountExpression: {
						isMathExpression: ValidationMessages.invalidExpression,
					},
					selectedCondition: {
						required: ValidationMessages.required,
					},
					selectedPolicySection: {
						required: ValidationMessages.required,
					},
					nonFinancialNumber: {
						required: ValidationMessages.required,
						minValue: ValidationMessages.greaterThanZero('Number'),
					},
					selectedTreatmentPeriod: {
						required: ValidationMessages.required,
					},
					deductions: {
						type: {
							required: ValidationMessages.required,
						},
						amount: {
							required: ValidationMessages.required,
							minValue: ValidationMessages.noNegative,
							maxAmount: ValidationMessages.maxAmount,
						},
						amountExpression: {
							isMathExpression: ValidationMessages.invalidExpression,
						},
					},
				},
			},
			claimDeductions: [],
		};
	},
	props: {
		policySections: {
			type: Array,
			required: true,
		},
		treatmentPeriods: {
			type: Array,
			required: true,
		},
		allClaimItems: {
			type: Array,
			required: true,
		},
		editingIndex: {
			type: Number,
			required: false,
		},
		isReadOnlyMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		claimAmount: {
			type: Number,
			required: false,
			default: 0,
		},
		isContinuationClaim: {
			type: Boolean,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
		conditions: {
			type: Array,
			required: false,
			default: () => [],
		},
		currencyConversionRequired: {
			type: Boolean,
			required: true,
		},
		customerCurrencyCode: {
			type: String,
			required: false,
			default: null,
		},
	},
	validations () {
		const cloneClaimItems = [...this.allClaimItems];

		if (this.editingIndex >= 0) {
			cloneClaimItems.splice(this.editingIndex, 1);
		}
		const moreThanZero = this.newClaimItem.selectedPolicySection && this.newClaimItem.selectedPolicySection.hasNonFinancialLimit ? moreThen(0) : minValue(0);
		const positiveIfNotRebalanceExcess = this.newClaimItem.rebalanceExcess ? () => true : minValue(0);
		return {
			newClaimItem: {
				unique: unique(cloneClaimItems, this.policySectionTreatmentPeriodComparator),
				amount: {
					required,
					minValue: positiveIfNotRebalanceExcess,
					maxAmount: maxAmount(16),
				},
				amountExpression: {
					isMathExpression,
				},
				selectedCondition: {
					required: this.multicondition ? required : () => true,
				},
				selectedPolicySection: {
					required,
				},
				selectedTreatmentPeriod: {
					required,
				},
				nonFinancialNumber: {
					required: requiredIf(() => this.newClaimItem.selectedPolicySection && this.newClaimItem.selectedPolicySection.hasNonFinancialLimit),
					minValue: moreThanZero,
				},
				deductions: {
					$each: {
						type: {
							required,
						},
						amount: {
							required,
							minValue: minValue(0),
							maxAmount: maxAmount(16),
						},
						amountExpression: {
							isMathExpression,
						},
					},
				},
			},
		};
	},
	async mounted () {
		this.claimDeductions = await deductionService.getClaimDeductions(this.$route.params.claimId);
	},
	methods: {
		onAmountUpdated (target, data) {
			target.amount = data.value;
			target.amountExpression = data.valueExpression;
			this.$v.newClaimItem.amount.$touch();
			this.$v.newClaimItem.amountExpression.$touch();
		},
		onDeductionAmountUpdated (deduction, index, data) {
			deduction.amount = data.value;
			deduction.amountExpression = data.valueExpression;
			this.$v.newClaimItem.deductions.$each.$iter[index].amount.$touch();
			this.$v.newClaimItem.deductions.$each.$iter[index].amountExpression.$touch();
			this.touchDeduction(index, 'amount');
		},
		policySectionTreatmentPeriodComparator (value1, value2) {
			if (!value2.selectedTreatmentPeriod || !value2.selectedPolicySection) return false;
			return value1.claimConditionId === (value2.selectedCondition || {}).id
				&& value1.policySectionId === value2.selectedPolicySection.id
				&& value1.rebalanceExcess === value2.rebalanceExcess
				&& areDatesEqual(value1.treatmentPeriod.start, value2.selectedTreatmentPeriod.start)
				&& areDatesEqual(value1.treatmentPeriod.end, value2.selectedTreatmentPeriod.end);
		},
		onPolicySectionChanged () {
			this.newClaimItem.selectedTreatmentPeriod = null;
			if (this.newClaimItem.selectedPolicySection == null || !this.newClaimItem.selectedPolicySection.hasNonFinancialLimit) {
				this.newClaimItem.nonFinancialNumber = null;
			}
		},
		onConditionChanged () {
			this.newClaimItem.selectedPolicySection = null;
			this.newClaimItem.deductions = [];
			this.onPolicySectionChanged();
		},
		onNewClaimItem () {
			if (this.$v.newClaimItem.$invalid) return;
			const currentValue = {
				condition: this.newClaimItem.selectedCondition,
				policySection: this.newClaimItem.selectedPolicySection,
				treatmentPeriod: this.newClaimItem.selectedTreatmentPeriod,
				amount: this.newClaimItem.amount,
				amountExpression: this.newClaimItem.amountExpression,
				deductions: this.newClaimItem.deductions.map(x =>
					({
						id: x.type.id,
						description: x.type.value,
						amount: x.amount,
						amountExpression: x.amountExpression,
					}),
				),
				nonFinancialNumber: this.newClaimItem.nonFinancialNumber,
				rebalanceExcess: this.newClaimItem.rebalanceExcess,
			};
			this.$emit('save', currentValue);
		},
		onAddDeduction () {
			if (this.newClaimItem.deductions.length <= 0
				|| this.newClaimItem.deductions[this.newClaimItem.deductions.length - 1].type) {
				this.newClaimItem.deductions.push({
					type: undefined,
					amount: 0,
					amountExpression: null,
				});
			}
		},
		removeDeduction (index) {
			this.$v.newClaimItem.deductions.$each.$iter[index].$reset();
			this.newClaimItem.deductions.splice(index, 1);
		},
		touchDeduction (index, fieldName) {
			this.$v.newClaimItem.deductions.$each.$iter[index][fieldName].$touch();
		},
		onCancel () {
			this.clearForm();
			this.$emit('cancel');
		},
		clearForm () {
			this.$v.newClaimItem.$reset();
			this.newClaimItem.selectedCondition = !this.multicondition || this.conditions.length === 1 ? this.conditions[0] : null;
			this.newClaimItem.selectedPolicySection = undefined;
			this.newClaimItem.selectedTreatmentPeriod = undefined;
			this.newClaimItem.deductions = [];
			this.newClaimItem.nonFinancialNumber = null;
			this.newClaimItem.rebalanceExcess = false;
			this.newClaimItem.amountExpression = null;
			if (this.showOptions) {
				this.$refs.sandwich.onCollapse();
			}
			this.$refs.amountInput.reset();
		},
		getTreatmentPeriodLabel (periodType) {
			switch (periodType) {
			case 1: return 'SC';
			case 2: return 'NC';
			case 3: return 'CI';
			}
		},
		getTreatmendPeriodLabelClass (periodType) {
			switch (periodType) {
			case 1: return 'period-label-icon icon-sc';
			case 2: return 'period-label-icon icon-nc';
			case 3: return 'period-label-icon icon-ci';
			}
		},
	},
	computed: {
		...mapState(['appSettings']),
		remainingAmount () {
			return this.claimAmount - this.allClaimItems.filter(ci => !ci.rebalanceExcess).reduce((sumAmount, item) => sumAmount + item.amount, 0);
		},
		isRemainingAmountZeroed () {
			return areNumbersEqual(this.remainingAmount, 0);
		},
		nonFinancialNumberLabel () {
			const { selectedPolicySection } = this.newClaimItem;
			return selectedPolicySection && selectedPolicySection.hasNonFinancialLimit ? `Number of ${capitalize(pluralize(selectedPolicySection.nonFinancialLimitUnit, 10))}` : '';
		},
		conditionPolicySections () {
			return this.newClaimItem.selectedCondition ? this.policySections.filter(ps => ps.claimConditionId === this.newClaimItem.selectedCondition.id) : [];
		},
		conditionDeductionTypes () {
			return this.claimDeductions.filter(d => d.claimConditionId === this.newClaimItem.selectedCondition?.id && !d.isSystem);
		},
		remaningClaimConditions () {
			return this.conditions.filter(condition => !this.allClaimItems.some(claimItem => claimItem.claimConditionId === condition.id));
		},
		showCurrencyConversionWarning () {
			return this.currencyConversionRequired && this.customerCurrencyCode;
		},
		conditionHighlightColor () {
			const condition = this.newClaimItem.selectedCondition?.description;

			if (this.appSettings.highlightDiagnosisNotMade && condition) {
				return condition.toUpperCase().includes(DiagnosisNotMade) ? 'warning' : null;
			}

			return null;
		},
		showOptions () {
			return this.appSettings.rebalanceExcessFunction && this.$can.RebalanceExcess;
		},
	},
	watch: {
		conditions: function (newConditionsValue) {
			this.newClaimItem.selectedCondition = !this.multicondition || newConditionsValue.length === 1 ? newConditionsValue[0] : null;
		},
		'newClaimItem.selectedPolicySection': function (newValue) {
			this.$emit('policy-changed', this.newClaimItem.selectedCondition?.id, newValue ? newValue.id : 0);
		},
		'newClaimItem.selectedTreatmentPeriod': function (newValue) {
			this.$emit('period-changed', newValue);
		},
		editingIndex: function (newIndex) {
			const item = this.allClaimItems[newIndex];

			if (item) {
				this.newClaimItem.amount = item.amount;
				this.newClaimItem.amountExpression = item.amountExpression;
				this.newClaimItem.selectedCondition = this.conditions.find(c => c.id === item.claimConditionId);
				this.newClaimItem.selectedPolicySection = this.conditionPolicySections.find(ps => ps.id === item.policySectionId);
				this.newClaimItem.selectedTreatmentPeriod = item.treatmentPeriod;
				if (item.userDeductions) {
					this.newClaimItem.deductions = item.userDeductions.map(x => ({
						amount: x.amount,
						amountExpression: x.amountExpression,
						type: this.claimDeductions.find(cd => cd.id === x.id),
					}),
					);
				}
				this.newClaimItem.nonFinancialNumber = item.nonFinancialNumber;
				this.newClaimItem.rebalanceExcess = item.rebalanceExcess;

				this.$v.newClaimItem.$touch();
			} else {
				this.newClaimItem = {
					amount: 0,
					amountExpression: null,
					selectedCondition: !this.multicondition || this.conditions.length === 1 ? this.conditions[0] : null,
					selectedPolicySection: null,
					selectedTreatmentPeriod: null,
					deductions: [],
					nonFinancialNumber: null,
					rebalanceExcess: false,
				};
			}
		},
		treatmentPeriods (periods) {
			if (this.isContinuationClaim
				&& this.isVetFeesAssigned
				&& periods.length) {
				this.newClaimItem.selectedTreatmentPeriod = periods[0];
				if (periods.length === 1) {
					this.newClaimItem.amount = this.claimAmount;
				}
			} else if (!this.newClaimItem.selectedTreatmentPeriod
				&& periods.length === 1
				&& this.allClaimItems.findIndex(item => (item.claimConditionId === this.newClaimItem.selectedCondition.id
					&& item.policySectionId === this.newClaimItem.selectedPolicySection.id)) === -1) {
				// Populate treatment period in case there are no claim-items with selected policy section and condition
				this.newClaimItem.selectedTreatmentPeriod = periods[0];
			}
		},
		conditionPolicySections: {
			handler: function (newPolicySections) {
				if (!this.newClaimItem.selectedPolicySection
					&& newPolicySections.length === 1
					&& this.allClaimItems.findIndex(item => (item.claimConditionId === this.newClaimItem.selectedCondition.id
						&& item.policySectionId === newPolicySections[0].id)) === -1) {
					this.newClaimItem.selectedPolicySection = newPolicySections[0];
				}
			},
			immediate: true,
		},
		allClaimItems (items) {
			if (this.isContinuationClaim && !items.length) {
				const vetFeesSection = this.conditionPolicySections.find(section => section.description === 'Vet Fees');
				if (vetFeesSection) {
					this.isVetFeesAssigned = true;
					this.newClaimItem.selectedPolicySection = vetFeesSection;
				}
			}
		},
		remaningClaimConditions (remaningConditions) {
			if (remaningConditions.length === 1) {
				this.newClaimItem.amount = this.remainingAmount;
			} else {
				this.newClaimItem.amount = 0;
			}
			this.newClaimItem.amountExpression = null;
		},
	},
};
</script>

<style lang="scss" scoped>
.period-label-icon {
  height: 20px;
  width: 20px;
  font-size: 10px;
  margin-right: 5px;
  text-align: center;
  border-radius: 50%;
  line-height: 20px;

  &.icon-sc {
    background-color: $primary;
  }

  &.icon-nc {
    background-color: $danger;
  }

  &.icon-ci {
    background-color: $warning;
  }
}

.claim-amount {
  color: $body-color;
  margin-left: 25px;
}

.badge {
  height: 50%;
  background-color: $warning;
  color: $body-color;
  padding: 6px;
  margin-right: -30px;
  margin-left: auto;
}

.badge-fold {
  height: 5px;
  width: 10px;
  position: absolute;
  right: 0;
  top: -5px;
  background-color: $warning;
  filter: brightness(0.5);
  clip-path: path("M 0 5 L 0 0 L 10 5 Z");
}

::v-deep .options-header {
  padding: 10px;
  background: var(--tableGroupBg);
  margin-top: 20px;

  .options-sub-header {
    font-weight: normal;
    color: var(--bodyColour) !important;
  }

  .caret {
    padding-right: 0 !important;
  }
}

.options-content {
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: var(--attributeBg);
}
</style>
