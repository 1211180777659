import { render, staticRenderFns } from "./PolicyInformationScan.vue?vue&type=template&id=06ec2962&scoped=true"
import script from "./PolicyInformationScan.vue?vue&type=script&lang=js"
export * from "./PolicyInformationScan.vue?vue&type=script&lang=js"
import style0 from "./PolicyInformationScan.vue?vue&type=style&index=0&id=06ec2962&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06ec2962",
  null
  
)

export default component.exports