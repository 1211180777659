<template>
	<div>
		<note-form
			v-if="isEditing"
			:data="selectedNote"
			:note-types="availableNoteTypes()"
			@cancel="onCancel"
			@save="onSave"
		/>
		<div
			class="section-content h-100 pb-20"
			v-show="!isEditing"
		>
			<div class="container-fluid h-100 d-flex flex-column">
				<aq-level-switcher
					class="d-flex align-items-center"
					show-policy
				/>
				<div class="p-10 d-flex flex-wrap search-terms">
					<aq-search-term
						v-for="(term, index) of searchTerms"
						:key="index"
						:value="term"
						@remove="onRemoveSearchTerm"
						class="px-10 mr-5 mb-5 py-6 bg-warning search-term__rounded align-items-center"
					/>
				</div>
				<div>
					<aq-search-box
						@search="onSearchTermAdded"
						@input="onInput"
						@open-filters="onToggleFilters"
						:icon-class="{ 'bg-warning': isFiltersOpen }"
						:focused="true"
					/>
				</div>
				<div class="position-relative">
					<div class="p-10 d-flex flex-wrap search-terms">
						<aq-search-term
							v-for="(term, index) of displayFilters"
							:key="index"
							:value="term.value"
							:header="term.header"
							header-class="text-warning"
							icon-class="text-primary"
							@remove="onRemoveFilter"
							class="px-10 mr-5 mb-5 py-6 attribute search-term__rounded"
						/>
					</div>
					<note-filter
						:filter-options="filterOptions"
						:filter="selectedFilter"
						v-if="isFiltersOpen"
						@apply-filters="onApplyFilters"
						@filter-changed="onFilterChanged"
						class="note-filter mt-5"
					/>
				</div>
				<div
					v-if="isClaimInFinalStatus && hasDashboardNotes && levelFilter.claimId"
					class="d-flex justify-content-end mb-10 font-weight-bold"
				>
					<aq-checkbox
						data-qa="notes_checkbox_showDashboardNotes"
						class="mb-10 tick"
						v-model="showDashboardNotes"
						label="Include Dashboard Notes"
					/>
				</div>
				<div class="d-flex">
					<button
						class="btn btn-primary w-100 mt-5"
						@click="onEditNote(null)"
						v-if="$can.AddNotes && !levelFilter.policyIds"
					>
						Add Note
					</button>
				</div>
				<div class="mb-25 d-flex mt-10">
					<span class="font-weight-bold mr-2">
						{{ filteredNotes.length }} {{ 'Note' | pluralize(filteredNotes.length) }}
					</span>&nbsp;match your search criteria
					<div
						class="mr-0 ml-auto text-primary font-weight-bolder reset-filters"
						@click="onResetFilters"
						data-qa="notes_button_resetFilters"
					>
						Reset	Filters
					</div>
				</div>
				<div class="overflow-wrapper">
					<note
						v-for="(note) of filteredNotes"
						:key="`${note.id}${note.updatedDate}`"
						:note="note"
						class="mb-10"
						@select-action="onSelectAction($event)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	finalClaimStatuses,
} from '@commonServices/models/ClaimStatusActionEnum';
import NotesService from '@commonServices/notesService';
import UsersService from '@commonServices/usersService';
import NoteFilter from './notes/NoteFilter';
import Note from './notes/Note';
import NoteForm from './notes/NoteForm';
import { toDateSearchTerm, AllDateTypes } from '@commonServices/utils/dateUtils';
import { toNotesApiFilters } from '@commonServices/utils/converter';
import { mapState } from 'vuex';
import { NoteTypesEnum } from '@commonServices/models/NoteTypesEnum';
import NoteSubtypesEnum from '@commonServices/models/NoteSubtypesEnum';

export default {
	name: 'AqNotes',
	components: {
		NoteFilter,
		Note,
		NoteForm,
	},
	data () {
		return {
			notes: [],
			isEditing: false,
			selectedNote: null,
			isFiltersOpen: false,
			users: [],
			selectedFilter: {
				type: null,
				users: [],
				date: { label: 'Dates' },
			},
			displayFilters: [],
			isDuplicationError: false,
			searchTerms: [],
			showDashboardNotes: true,
		};
	},
	async mounted () {
		this.users = await UsersService.getUsers();
		if (this.isClaimInFinalStatus) {
			this.showDashboardNotes = false;
		}
	},
	computed: {
		...mapState(['levelFilter', 'currentClaimStatus']),
		filterOptions () {
			const availableNoteTypes = this.availableNoteTypes();

			return [
				{
					filterLabel: 'Type',
					filterOptions: availableNoteTypes,
				},
				{
					filterLabel: 'User',
					filterOptions: this.users,
				},
				{
					filterLabel: 'Date',
					filterOptions: AllDateTypes,
				},
			];
		},
		alertNotesCount () {
			return this.notes.filter(note => note.type === NoteTypesEnum.Alert).length;
		},
		nonDashboardNotes () {
			return this.notes.filter(note => note.subtype !== NoteSubtypesEnum.Dashboard);
		},
		filteredNotes () {
			return this.showDashboardNotes ? this.notes : this.nonDashboardNotes;
		},
		hasDashboardNotes () {
			return this.notes.length !== this.nonDashboardNotes.length;
		},
		isClaimInFinalStatus () {
			return finalClaimStatuses.includes(this.currentClaimStatus);
		},
	},
	watch: {
		searchTerms () {
			this.updateNotes();
		},
		levelFilter: {
			handler: function () {
				this.updateNotes();
			},
			immediate: true,
		},
	},
	methods: {
		availableNoteTypes () {
			let availableNoteTypes = NotesService.getNoteTypes(this.$can);
			if (!this.levelFilter.claimId) {
				availableNoteTypes = availableNoteTypes.filter(x => x.key !== NoteTypesEnum.Appeal);
			}

			return availableNoteTypes;
		},
		updateNotes (apiFilter) {
			const appliedFilter = apiFilter || toNotesApiFilters(this.selectedFilter);
			NotesService.getNotes({
				...this.levelFilter,
				...appliedFilter,
				searchTerms: this.searchTerms,
			}).then((data) => {
				this.notes = data;
			});
		},
		onToggleFilters () {
			this.isFiltersOpen = !this.isFiltersOpen;
		},
		onApplyFilters () {
			this.isFiltersOpen = false;
			this.setupFilters();
		},
		onFilterChanged (newFilter) {
			this.selectedFilter = newFilter;
		},
		setupFilters () {
			const appliedFilter = toNotesApiFilters(this.selectedFilter);
			this.setDisplayFilters(appliedFilter);
			this.updateNotes(appliedFilter);
		},
		setDisplayFilters (appliedFilter) {
			this.displayFilters = [];

			if (this.selectedFilter.type) {
				this.displayFilters.push({
					header: 'Type',
					value: this.selectedFilter.type.value,
				});
			}

			if (this.selectedFilter.users.length) {
				this.displayFilters.push({
					header: 'Users',
					value: this.selectedFilter.users.map(user => user.description).join(', '),
				});
			}

			const dateDisplay = toDateSearchTerm(appliedFilter.dateFrom, appliedFilter.dateTo);
			if (dateDisplay) {
				this.displayFilters.push({
					header: this.selectedFilter.date.type,
					value: dateDisplay,
				});
			}
		},
		onResetFilters () {
			this.selectedFilter.type = null;
			this.selectedFilter.users = [];
			this.selectedFilter.date = { label: 'Dates' };

			this.displayFilters = [];

			this.updateNotes();
		},
		onRemoveFilter (filter) {
			const removeItemIndex = this.displayFilters.findIndex((item) => item.value === filter.value);
			this.displayFilters.splice(removeItemIndex, 1);
			this.updateFilter(filter.header.toLowerCase());
		},
		updateFilter (property) {
			if (property === 'type') {
				this.selectedFilter[property] = null;
			} else if (Array.isArray(this.selectedFilter[property])) {
				this.selectedFilter[property] = [];
			} else {
				this.selectedFilter.date = { label: 'Dates' };
			}

			this.updateNotes();
		},
		onEditNote (note) {
			if (note) {
				this.selectedNote = { ...note };
			} else {
				this.selectedNote = {
					type: 1,
					title: null,
					details: null,
				};
			}
			this.isEditing = true;
		},
		onCancel () {
			this.selectedNote = null;
			this.isEditing = false;
		},
		async onSelectAction (event) {
			switch (event.action) {
			case 'edit': {
				const note = this.notes.find(item => item.id === event.id);
				this.onEditNote(note);
				break;
			}
			case 'delete': {
				await NotesService.deleteNote(event.id);
				this.notes = this.notes.filter(note => note.id !== event.id);
				this.$emit('alert-notes-changed', this.alertNotesCount);
				break;
			}
			}
		},
		async onSave (result) {
			if (!result.id) {
				const data = await NotesService.createNote({ ...result, ...this.levelFilter });
				if (result.type === NoteTypesEnum.Alert) { // 'Alert' notes go to top of list
					this.notes.unshift(data.value);
					this.$emit('alert-notes-changed', this.alertNotesCount);
				} else {
					this.notes.push(data.value);
				}
			} else {
				const data = await NotesService.editNote(result);
				const index = this.notes.findIndex(note => note.id === data.value.id);
				this.$set(this.notes, index, data.value);
			}
			this.selectedNote = null;
			this.isEditing = false;
			this.searchTerms = [];
		},
		onSearchTermAdded (value) {
			this.isDuplicationError = this.searchTerms.includes(value);

			if (this.isDuplicationError) {
				return;
			}

			this.searchTerms.push(value);
		},
		onRemoveSearchTerm (term) {
			const removeItemIndex = this.searchTerms.indexOf(term.value);
			this.searchTerms.splice(removeItemIndex, 1);
		},
		onInput () {
			this.isDuplicationError = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.overflow-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0;
  padding-right: 15px;
}

.search-terms {
  width: 450px;
}

.search-term__rounded {
  border-radius: 4px;
}

.reset-filters {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: darken($primary, 10%);
    transition: color 0.2s;
  }
}

.section-content {
  overflow: hidden auto;
}
</style>
