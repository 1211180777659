<template>
	<div>
		<aq-sandwich
			ref="sandwich"
			header="Payment Breakdown"
			active-class=""
			header-class="pl-25"
			class="mb-50 mr-25"
		>
			<template slot="content">
				<div>
					<div class="section-content ml-25">
						<div class="container-fluid">
							<el-table
								ref="paymentBreakdown"
								empty-text="The table is empty"
								:data="claimItems"
								style="width: 100%;"
								class="breakdown-table"
								:row-style="getRowStyle"
								:row-class-name="'offset-top'"
								:span-method="breakdownSpan"
								show-summary
								:summary-method="getSummaries"
								max-height="400"
								data-qa="paymentBreakdown_table_paymentBreakdown"
							>
								<el-table-column
									label="Policy Section"
									data-qa="paymentBreakdown_column_policySection"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.policySectionName }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Treatment Period"
									width="510"
									data-qa="paymentBreakdown_column_treatmentPeriod"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.treatmentPeriodStart | shortDate }} - {{ scope.row.treatmentPeriodEnd | shortDate }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Amount"
									prop="amount"
									width="200"
									data-qa="paymentBreakdown_column_amount"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.amount | currency(locale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="User"
									prop="userDeductions"
									width="200"
									data-qa="paymentBreakdown_column_userDeductions"
								>
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.userDeductions">
												-
											</span>{{ scope.row.userDeductions | currency(locale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="System"
									prop="systemDeductions"
									width="200"
									data-qa="paymentBreakdown_column_systemDeductions"
								>
									<template slot-scope="scope">
										<div>
											<span v-if="scope.row.systemDeductions > 0">
												-
											</span>{{ Math.abs(scope.row.systemDeductions) | currency(locale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Total"
									prop="totalAmount"
									width="50"
									data-qa="paymentBreakdown_column_totalAmount"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.totalAmount | currency(locale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									width="90"
									data-qa="paymentBreakdown_column_buttons"
								>
									<template slot-scope="scope">
										<button
											v-if="!expandedRowsFlags.find((item) => item.key === scope.$index).isExpanded"
											type="button"
											class="expand-icon btn small btn-primary"
											@click="toggleExpansion(scope)"
											data-qa="paymentBreakdown_button_expandBreakdown"
										>
											<i class="fas fa-angle-right" />
										</button>
										<button
											v-else
											type="button"
											class="expand-icon expand-icon--expanded btn small btn-primary"
											@click="toggleExpansion(scope)"
											data-qa="paymentBreakdown_button_collapseBreakdown"
										>
											<i class="fas fa-angle-down" />
										</button>
									</template>
								</el-table-column>
								<el-table-column
									type="expand"
									width="50"
									data-qa="paymentBreakdown_column_expandBreakdown"
								>
									<template slot-scope="props">
										<div class="d-flex p-20 deductions">
											<div>
												<div class="mt-6">
													Deductions
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													User Deductions
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalUser.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalUser.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													{{ uiLocaleSettings.excessDeductionsLabel }}
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalExcess.deductedValue > 0">
														-
													</span>
													{{ Math.abs(props.row.totalDeductions.totalExcess.deductedValue) | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Co-ins {{ getDeductionOriginalLimitDescription(props.row.totalDeductions.totalCoInsurance.originalLimit) }}
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalCoInsurance.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalCoInsurance.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Section % Cover {{ getDeductionOriginalLimitDescription(props.row.totalDeductions.totalPartialCoverage.originalLimit) }}
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalPartialCoverage.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalPartialCoverage.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Non Financial Limit
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalNonFinancialLimit.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalNonFinancialLimit.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Section/Subsection Limit
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalLimit.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalLimit.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Condition Limit
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalConditionLimit.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalConditionLimit.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													No Coverage
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalNoCoverage.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalNoCoverage.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Rejected
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalRejected.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalRejected.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Tax
												</div>
												<div>
													<span v-if="props.row.totalDeductions.totalTax.deductedValue">
														-
													</span>
													{{ props.row.totalDeductions.totalTax.deductedValue | currency(locale) }}
												</div>
											</div>
											<div>
												<div class="text-subtitle mb-10">
													Total
												</div>
												<div>
													<span v-if="getTotalDeductions(props.row.totalDeductions)">
														-
													</span>
													{{ getTotalDeductions(props.row.totalDeductions) | currency(locale) }}
												</div>
											</div>
											<div class="last-col" />
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</template>
		</aq-sandwich>
	</div>
</template>

<script>

import { currency } from '@commonServices/utils/filters';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';

export default {
	name: 'PaymentBreakdown',
	props: {
		claimItems: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			expandedRowsFlags: [],
		};
	},
	watch: {
		claimItems () {
			this.expandedRowsFlags = this.claimItems.map((item, index) => {
				return {
					key: index,
					isExpanded: false,
				};
			}).filter(Boolean);
		},
	},
	computed: {
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
	},
	methods: {
		breakdownSpan ({ row, columnIndex }) {
			switch (columnIndex) {
			case 5:
				return {
					rowspan: 1,
					colspan: 2,
				};
			default:
				return {
					rowspan: 1,
					colspan: 1,
				};
			}
		},
		getRowStyle ({ row }) {
			if (row.rowType === 1) {
				return 'border-top-width: 20px';
			} else {
				return 'border-top-width: 5px';
			}
		},
		toggleExpansion (scope) {
			this.$refs.paymentBreakdown.toggleRowExpansion(scope.row);
			const index = this.expandedRowsFlags.findIndex((item) => item.key === scope.$index);
			this.expandedRowsFlags[index].isExpanded = !this.expandedRowsFlags[index].isExpanded;
		},
		getSummaries (param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = 'Total';
					return;
				}
				const values = data.map(item => Number(item[column.property]));
				if (!values.every(value => isNaN(value))) {
					let sign = '';
					const sum = values.reduce((prev, curr) => {
						return prev + curr;
					}, 0);
					if ((column.property === 'userDeductions'
						|| column.property === 'systemDeductions')
						&& sum !== 0) {
						sign = '-';
					}
					sums[index] = `${sign}${currency(sum, this.locale)}`;
				}
			});
			return sums;
		},
		getTotalDeductions (deductions) {
			return Object.values(deductions)
				.filter(Boolean)
				.reduce((accum, deduction) => {
					return accum + deduction.deductedValue;
				}, 0);
		},
		getDeductionOriginalLimitDescription (originalLimit) {
			return originalLimit ? `(${originalLimit}%)` : '';
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .breakdown-table {
  .expand-icon {
    &.expand-icon--expanded {
      background-color: $warning;
    }
  }

  .el-table__expanded-cell {
    padding: 0;
    text-align: left;
  }

  tr td:first-child .cell {
    font-weight: bold;
  }
}

::v-deep .bg-primary-lighten {
  background: darken($primary, 15%);
}

::v-deep .bg-primary-darken {
  background: darken($primary, 25%);
}

.deductions > div {
  width: 160px;

  &.last-col {
    width: 10px;
  }
}
</style>
