import { FilterType } from '@commonServices/models/FilterType';
import { DateTypes, fromStringToDate, toApiDateFilter, toDateSearchTerm } from '@commonServices/utils/dateUtils';
import { NumberFilerTypes } from '@commonServices/utils/general';
import { currency } from '@commonServices/utils/filters';
import LocalDate from '@commonServices/models/LocalDate';

export function setSelectedFilterValue (filterData, label, type, multiselect = false, defaultValue = null, excludable = true) {
	const filterValue = {
		type,
		label,
		value: null,
		multiselect,
		excluded: excludable ? false : null,
	};

	if (!filterData) return { ...filterValue, value: defaultValue };

	const values = filterData.map(filter => ({
		id: isNaN(filter.value) ? filter.value : parseInt(filter.value),
		description: filter.description,
	}));
	filterValue.value = multiselect ? values : values[0];
	filterValue.excluded = excludable ? filterData.some(f => f.excluded) : null;
	return filterValue;
}

export function setDateFilter ([from] = [], [to] = [], label) {
	const dateFilter = {
		type: null,
		dateFrom: null,
		dateTo: null,
		label,
	};

	if (from) {
		dateFilter.type = from.description;
		dateFilter.dateFrom = fromStringToDate(from.value);
	}
	if (to) {
		dateFilter.type = to.description;
		dateFilter.dateTo = fromStringToDate(to.value);
		if (to.description === DateTypes.BeforeDate) {
			dateFilter.dateFrom = fromStringToDate(to.value);
		}
	}

	return dateFilter;
}

export function setNumberFilter ([from] = [], [to] = [], [exactValue] = [], label) {
	const numberFilter = {
		type: null,
		from: null,
		to: null,
		label,
	};

	if (exactValue) {
		numberFilter.type = exactValue.description;
		numberFilter.from = parseFloat(exactValue.value);
	}
	if (from) {
		numberFilter.type = from.description;
		numberFilter.from = parseFloat(from.value);
	}

	if (to) {
		numberFilter.type = to.description;
		numberFilter.to = parseFloat(to.value);
		if (to.description === NumberFilerTypes.LessThan) {
			numberFilter.from = parseFloat(to.value);
		}
	}

	return numberFilter;
}

// to api
export function toApiFilters (filterData) {
	const apiFiltersModel = [];
	const searchTerms = [];
	for (const [key, filter] of Object.entries(filterData)) {
		if (key === 'dates') {
			const { dateFilters, dateSearchTerms } = specifyDateFilters(filter);
			apiFiltersModel.push(...dateFilters);
			searchTerms.push(...dateSearchTerms);
			continue;
		}
		if (key === 'numbers') {
			const { numberFilters, numberSearchTerms } = specifyNumberFilters(filter);
			apiFiltersModel.push(...numberFilters);
			searchTerms.push(...numberSearchTerms);
			continue;
		}
		if (!filter.value || (Array.isArray(filter.value) && !filter.value.length)) {
			continue;
		}

		if (Array.isArray(filter.value)) {
			apiFiltersModel.push(...filter.value.map(f => ({
				type: filter.type,
				value: f.id.toString(),
				description: f.description,
				excluded: filter.excluded === null ? false : filter.excluded,
			})));
			searchTerms.push({ label: filter.label, value: filter.value.map(item => item.description), excluded: filter.excluded });
		} else {
			apiFiltersModel.push({
				type: filter.type,
				value: filter.value.id.toString(),
				description: filter.value.description,
				excluded: filter.excluded === null ? false : filter.excluded,
			});
			searchTerms.push({ label: filter.label, value: filter.value.description, excluded: filter.excluded });
		}
	}
	const populatedFilters = apiFiltersModel.filter((model) => model.value != null);
	return { populatedFilters, searchTerms };
}

const DATE_FORMAT = date => date == null ? null : new LocalDate(date);
const dateFilterTypeMap = {
	petDateOfBirth: {
		dateFromType: FilterType.PetDateOfBirthFrom,
		dateToType: FilterType.PetDateOfBirthTo,
		format: DATE_FORMAT,
	},
	inceptionDate: {
		dateFromType: FilterType.PolicyInceptionDateFrom,
		dateToType: FilterType.PolicyInceptionDateTo,
		format: DATE_FORMAT,
	},
	datePolicyStart: {
		dateFromType: FilterType.DatePolicyStartFrom,
		dateToType: FilterType.DatePolicyStartTo,
		format: DATE_FORMAT,
	},
	dateOnSet: {
		dateFromType: FilterType.ClaimDateOfLossFrom,
		dateToType: FilterType.ClaimDateOfLossTo,
		format: DATE_FORMAT,
	},
	dateTreatmentStart: {
		dateFromType: FilterType.ClaimTreatmentStartDateFrom,
		dateToType: FilterType.ClaimTreatmentStartDateTo,
		format: DATE_FORMAT,
	},
	dateTreatmentEnd: {
		dateFromType: FilterType.ClaimTreatmentEndDateFrom,
		dateToType: FilterType.ClaimTreatmentEndDateTo,
		format: DATE_FORMAT,
	},
	datePaid: {
		dateFromType: FilterType.ClaimDatePaidFrom,
		dateToType: FilterType.ClaimDatePaidTo,
	},
	claimCreatedDate: {
		dateFromType: FilterType.ClaimCreatedDateFrom,
		dateToType: FilterType.ClaimCreatedDateTo,
	},
	lastInteractionDate: {
		dateFromType: FilterType.LastInteractionDateFrom,
		dateToType: FilterType.LastInteractionDateTo,
	},
	claimFormUploadedDate: {
		dateFromType: FilterType.ClaimFormUploadedDateFrom,
		dateToType: FilterType.ClaimFormUploadedDateTo,
	},
	fraudCheckRequestedDate: {
		dateFromType: FilterType.FraudCheckRequestedDateFrom,
		dateToType: FilterType.FraudCheckRequestedDateTo,
	},
	missingInfoRequestedDate: {
		dateFromType: FilterType.MissingInfoDateFrom,
		dateToType: FilterType.MissingInfoDateTo,
	},
	reassessmentRequestedDate: {
		dateFromType: FilterType.ReassessmentRequestedDateFrom,
		dateToType: FilterType.ReassessmentRequestedDateTo,
	},
	claimClosedDate: {
		dateFromType: FilterType.ClaimClosedDateFrom,
		dateToType: FilterType.ClaimClosedDateTo,
	},
	statsDate: {
		dateFromType: FilterType.DateTimeFrom,
		dateToType: FilterType.DateTimeTo,
	},
};

function specifyDateFilters (dates) {
	const dateFilters = [];
	const dateSearchTerms = [];

	for (const [key, dateFilter] of Object.entries(dates)) {
		if (!dateFilter.type) {
			continue;
		}
		const appliedFilter = toApiDateFilter(dateFilter.type, dateFilter.dateFrom, dateFilter.dateTo);

		// prepare dates for search terms
		let searchTermDateFrom = appliedFilter.dateFrom;
		let searchTermDateTo = appliedFilter.dateTo;
		if (dateFilter.type === DateTypes.SelectDate) {
			searchTermDateTo = null;
		} else if (dateFilter.type === DateTypes.BeforeDate) {
			searchTermDateTo = dateFilter.dateFrom;
		} else if (dateFilter.type === DateTypes.AfterDate) {
			searchTermDateFrom = dateFilter.dateFrom;
		}

		dateSearchTerms.push({
			label: `${dateFilter.label} - ${dateFilter.type}`,
			value: toDateSearchTerm(searchTermDateFrom, searchTermDateTo),
		});

		// format api filters
		const { dateFromType, dateToType, format } = dateFilterTypeMap[key];
		dateFilters.push({
			type: dateFromType,
			value: format ? format(appliedFilter.dateFrom) : appliedFilter.dateFrom,
			description: dateFilter.type,
		});
		dateFilters.push({
			type: dateToType,
			value: format ? format(appliedFilter.dateTo) : appliedFilter.dateTo,
			description: dateFilter.type,
		});
	}
	return { dateFilters, dateSearchTerms };
}

const numberFilterTypeMap = {
	claimAmount: {
		numberFromType: FilterType.ClaimAmountFrom,
		numberToType: FilterType.ClaimAmountTo,
		numberEqualType: FilterType.ClaimAmountEqual,
	},
	paymentAmount: {
		numberFromType: FilterType.PaymentAmountFrom,
		numberToType: FilterType.PaymentAmountTo,
		numberEqualType: FilterType.PaymentAmountEqual,
	},
	chaseCount: {
		numberFromType: FilterType.ChaseCountFrom,
		numberToType: FilterType.ChaseCountTo,
		numberEqualType: FilterType.ChaseCountEqual,
		format: x => x,
	},
	exGratiaAmount: {
		numberFromType: FilterType.ExGratiaPaymentFrom,
		numberToType: FilterType.ExGratiaPaymentTo,
		numberEqualType: FilterType.ExGratiaPaymentEqual,
	},
	dayCountToTreatmentStart: {
		numberFromType: FilterType.DaysCountToTreatmentStartFrom,
		numberToType: FilterType.DaysCountToTreatmentStartTo,
		numberEqualType: FilterType.DaysCountToTreatmentStartEqual,
		format: x => x,
	},
	daysOpen: {
		numberFromType: FilterType.DaysOpenFrom,
		numberToType: FilterType.DaysOpenTo,
		numberEqualType: FilterType.DaysOpenEqual,
		format: x => x,
	},
};

function specifyNumberFilters (numbers) {
	const numberFilters = [];
	const numberSearchTerms = [];

	for (const [key, numberFilter] of Object.entries(numbers)) {
		if (!numberFilter.type) {
			continue;
		}
		const numberFilterTypeInfo = numberFilterTypeMap[key];
		const appliedFilter = toApiNumberFilter(numberFilter.type, numberFilter.from, numberFilter.to);

		numberSearchTerms.push({
			label: `${numberFilter.label} - ${numberFilter.type}`,
			value: toNumberSearchTerm(appliedFilter.from, appliedFilter.to, appliedFilter.exactValue, numberFilterTypeInfo.format),
		});
		numberFilters.push({
			type: numberFilterTypeInfo.numberFromType,
			value: appliedFilter.from?.toString(),
			description: numberFilter.type,
		});
		numberFilters.push({
			type: numberFilterTypeInfo.numberToType,
			value: appliedFilter.to?.toString(),
			description: numberFilter.type,
		});
		numberFilters.push({
			type: numberFilterTypeInfo.numberEqualType,
			value: appliedFilter.exactValue?.toString(),
			description: numberFilter.type,
		});
	}
	return { numberFilters, numberSearchTerms };
}

function toNumberSearchTerm (from, to, exactValue, formatter = currency) {
	return [from, to, exactValue]
		.filter(val => val != null)
		.map(x => formatter(x))
		.join(' - ');
}

function toApiNumberFilter (numberFilterType, from, to) {
	const filter = { from: null, to: null, exactValue: null };

	switch (numberFilterType) {
	case NumberFilerTypes.Between:
		filter.from = from;
		filter.to = to;
		break;
	case NumberFilerTypes.Equals:
		filter.exactValue = from;
		break;
	case NumberFilerTypes.GreaterThan:
		filter.from = from;
		filter.to = null;
		break;
	case NumberFilerTypes.LessThan:
		filter.from = null;
		filter.to = from;
		break;
	}

	return filter;
}
