const ValidationMessages = {
	required: 'This field cannot be blank',
	noNegative: 'This field cannot be negative',
	greaterThanZero: (fieldName) => `${fieldName} must be greater than 0`,
	notEqual: (value) => `This field cannot be equal to ${value}`,

	maxLength: (length) => `The max length is ${length}`,
	get maxLength100 () { return this.maxLength(100); },
	get maxLength200 () { return this.maxLength(200); },
	get maxLength1000 () { return this.maxLength(1000); },
	get maxLength2000 () { return this.maxLength(2000); },
	get maxLength5000 () { return this.maxLength(5000); },

	maxAmount: 'The max length is 16 digits',

	dateInFuture: 'This date cannot be in future',
	dateShouldBeAfter: (arg) => `This date should be after ${arg}`,
	dateShouldBeEqualOrAfter: (arg) => `This date should be equal or after ${arg}`,
	dateCannotBeAfter: (arg) => `This date cannot be after ${arg}`,
	dateCannotBeBefore: (arg) => `This date cannot be before ${arg}`,
	negativeExGratia: 'A negative ex-gratia amount can not reduce the calculated payment to be less than zero',
	exGratiaAlreadyExists: 'An Ex-Gratia row for this recipient already exists',
	vetRequiredIfVetPayeeSelected: 'Vet required where Payee selection is Vet',
	between: (min, max) => `This field must be between ${min} and ${max}`,
	invalidExpression: 'Invalid expression',

};

const EverytingElseQueueName = 'Everything Else';
const UnfilteredTasksQueueName = 'Unfiltered Tasks';
const StorageKeyPersistentStore = 'persistentStore';
const PrePolicyHistoryMonthAmount = 18;

const DiagnosisNotMade = 'DIAGNOSIS NOT MADE';

export {
	ValidationMessages,
	EverytingElseQueueName,
	UnfilteredTasksQueueName,
	StorageKeyPersistentStore,
	PrePolicyHistoryMonthAmount,
	DiagnosisNotMade,
};
