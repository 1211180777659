import { FilterType, Filter } from '@commonServices/models/FilterType';
import { FilterGroupEnum } from '@commonServices/models/FilterGroup';
import BdxReportModel from '@commonServices/models/reports/BdxReportModel';
import ClaimPaymentsReportModel from '@commonServices/models/reports/ClaimPaymentsReportModel';
import ClaimStatusReportModel from '@commonServices/models/reports/ClaimStatusReportModel';
import UserStatsReportModel from '@commonServices/models/reports/UserStatsReportModel';
import ClaimQualityAuditReportModel from '@commonServices/models/reports/ClaimQualityAuditReportModel';
import { currency, shortDate, shortDateTime } from '@commonServices/utils/filters';
import { fromStringToDate } from '@commonServices/utils/dateUtils';
import {
	aggregateFunctionsList,
	claimPaymentFilterColumns,
	claimReportFilterColumns,
	claimStatusFilterColumns,
	userStatsFilterColumns,
	qualityAuditFilterColumns,
} from '@commonServices/models/ChartFilterModels';
import { isDate } from '@commonServices/utils/validators';
import { DateFormats } from '@commonServices/settings/localeSettings';
import { setDateFilter, setNumberFilter, setSelectedFilterValue, toApiFilters } from './filtersConverter';
import { toFullAddress, toUser } from './converter';
import groupBy from 'lodash.groupby';
import SortingDirection from '@commonServices/models/SortingDirection';
import {
	ClaimPaymentSortingColumns,
	ClaimReportSortingColumns,
	ClaimStatusSortingColumns, ClaimsToReassessSortingColumns, DraftClaimsSortingColumns,
	FraudClaimsSortingColumns, MissingInfoClaimsSortingColumns,
	PaymentApprovalClaimsSortingColumns,
	RejectionPendingClaimsSortingColumns,
	UserStatsSortingColumns,
	ClaimQualityAuditSortingColumns,
} from '@commonServices/models/SortingColumns';
import { PolicyStatusDisplay } from '../models/PolicyStatus';

// data
export function toClaimReportModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((report) => {
			const locale = report.brandLocale;
			const customerAddress = toFullAddress(report.customerAddress);
			const customerPostalCode = report.customerAddress.postalCode || 'No Postal Code';
			const productName = report.productNames.join(', ') || 'No Products';
			const vetAddresses = report.vets.map(toFullAddress);
			const vetBusinessNames = report.vets.map((vet) => vet.practiceName).join(', ') || 'No Vets';
			const vetPostalCodes = 	report.vets.map((vet) => vet.postalCode).join(', ') || 'No Vets';
			const petDateOfBirth = shortDate(report.petDateOfBirth);
			const petAgeInception = report.petAgeInMonthsAtInceptionDate;
			const petAgeTreatmentStart = report.petAgeInMonthsAtTreatmentStart;
			const policyInceptionDate = shortDate(report.policyInceptionDate);
			const datePolicyStart = shortDate(report.datePolicyStart) || 'No Date Policy';
			const ailments = report.ailments.join('; ') || 'No Ailment';
			const bodyParts = report.bodyParts.join('; ') || 'No Body Part';
			const conditionTypes = report.conditionTypes.join('; ') || 'No Condition';
			const dateOnSet = shortDate(report.claimDateOfLoss) || 'No Date of Loss';
			const dateTreatmentStart = shortDate(report.claimTreatmentStartDate) || 'No Treatment Start Date';
			const dateTreatmentEnd = shortDate(report.claimTreatmentEndDate) || 'No Treatment End Date';
			const sections = report.sections.join(', ') || 'No Sections';
			const datePaid = shortDate(report.claimDatePaid) || 'No Date Paid';
			const claimCreatedDate = shortDateTime(report.claimCreatedDate);
			const amountPaid = currency(report.claimAmountPaid, locale, true);
			const amountExGratia = currency(report.claimAmountExGratia, locale, true);
			const amountSubmitted = currency(report.claimAmountSubmitted || 0, locale, true);
			let userDeductionsMap = groupBy(report.userDeductions, 'description');
			userDeductionsMap = Object.entries(userDeductionsMap)
				.map(([key, value]) => value.length === 1 ? key : `${key} (${value.length})`)
				.sort((a, b) => (a > b ? 1 : -1));
			const userDeductions = userDeductionsMap.join(', ') || 'No User Deductions';
			const amountUserDeducted = currency(report.amountUserDeducted, locale, true);
			const amountNonInsurable = currency(report.claimAmountNonInsurable, locale, true);
			const amountExcess = currency(report.claimAmountExcess, locale, true);
			const amountCoInsurance = currency(report.claimAmountCoInsurance, locale, true);
			const rejectionReason = report.rejectionReason || 'No Reasons';
			const daysOpenClosed = report.daysOpenClosed || 'No Days Open Closed';
			const closedDate = shortDateTime(report.closedDateTime) || 'No Closed Date';
			const reassessmentDate = shortDate(report.reassessmentDateTime);
			const reassessmentClosedDate = shortDate(report.reassessmentClosedDateTime);
			const reassessmentOutcome = report.reassessmentOutcome ? toOutcomeLabel(report.reassessmentOutcome) : null;
			const eligibleConditions = report.eligibleConditions.map(eligibleCondition => eligibleCondition ?? 'Not Recorded').join('; ');
			const policyStatusDisplay = report.policyStatus.split('; ').map(ps => PolicyStatusDisplay[ps]).join('; ');

			return new BdxReportModel(
				report.brandName,
				report.brandLocale,
				report.customerId,
				report.policyId,
				report.customerExtRef,
				report.customerExtId,
				report.policyReference,
				report.claimId,
				report.customerFirstName,
				report.customerLastName,
				customerAddress,
				customerPostalCode,
				report.policyStatus,
				policyStatusDisplay,
				productName,
				vetAddresses,
				vetBusinessNames,
				vetPostalCodes,
				report.petExtRef,
				report.petExtId,
				report.petType,
				report.petGender,
				report.petBreed,
				petDateOfBirth,
				petAgeInception,
				petAgeTreatmentStart,
				policyInceptionDate,
				datePolicyStart,
				ailments,
				bodyParts,
				conditionTypes,
				dateOnSet,
				dateTreatmentStart,
				dateTreatmentEnd,
				sections,
				datePaid,
				report.claimCreatedByUser,
				claimCreatedDate,
				report.claimAssessedByUser,
				report.claimApprovedByUser,
				amountPaid,
				amountExGratia,
				amountSubmitted,
				userDeductions,
				amountUserDeducted,
				amountNonInsurable,
				amountExcess,
				amountCoInsurance,
				report.claimStatus,
				rejectionReason,
				report.rejectionOverriddenByUser,
				report.claimFormType,
				daysOpenClosed,
				closedDate,
				reassessmentDate,
				report.reassessmentCreatedByUser,
				report.reassessmentReason,
				reassessmentOutcome,
				reassessmentClosedDate,
				report.reassessmentClosedByUser,
				report.claimSource,
				eligibleConditions,
				report.isFastTrackReviewed,
				report.fastTrackReason,
			);
		}),
	};
}

export function toClaimPaymentsReportModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((report) => {
			const locale = report.brandLocale;
			const customerRef = report.customerId || '-';
			const vetBusinessName = report.vetBusinessName || '-';
			const datePaid = shortDate(report.claimDatePaid) || 'No Date Paid';
			const amountPaid = currency(report.claimAmountPaid, locale, true);
			const exGratiaAmountPaid = report.claimExGratiaAmountPaid ? currency(report.claimExGratiaAmountPaid, locale, true) : '-';
			const sortCode = report.sortCode || '-';
			const accountNumber = report.accountNumber || '-';
			const accountName = report.accountName || '-';
			const payee = report.payee || '-';

			return new ClaimPaymentsReportModel(
				report.brandName,
				report.brandLocale,
				report.customerId,
				customerRef,
				report.policyId,
				report.policyReference,
				report.claimId,
				report.customerFirstName,
				report.customerLastName,
				vetBusinessName,
				report.paymentType,
				accountName,
				sortCode,
				accountNumber,
				payee,
				amountPaid,
				exGratiaAmountPaid,
				datePaid,
			);
		}),
	};
}

export function toClaimStatusReportModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((report) => {
			const locale = report.brandLocale;
			const ailments = report.ailments.join('; ') || 'No Ailment';
			const conditionTypes = report.conditionTypes.join('; ') || 'No Condition';
			const amountSubmitted = currency(report.claimAmountSubmitted, locale, true);
			const claimCreatedDate = shortDateTime(report.claimCreatedDate);
			const claimFormUploadedDate = shortDate(report.claimFormUploadedDate) || 'No Claim Form Upload Date';
			const dateOnSet = shortDate(report.claimDateOfLoss) || 'No Date of Loss';
			const dateTreatmentStart = shortDate(report.claimTreatmentStartDate) || 'No Treatment Start Date';
			const lastInteraction = report.lastInteraction || 'No Interaction';
			const lastInteractionDate = shortDate(report.lastInteractionDate) || 'No Interaction Date';
			const daysOpenClosed = report.daysOpenClosed || 'No Days Open Closed';
			const claimClosedDate = shortDateTime(report.claimClosedDate) || 'No Closed Date';
			return new ClaimStatusReportModel(
				report.brandName,
				report.brandLocale,
				report.customerId,
				report.policyId,
				report.policyReference,
				report.claimId,
				report.customerFirstName,
				report.customerLastName,
				report.petName,
				report.petType,
				report.petBreed,
				ailments,
				conditionTypes,
				amountSubmitted,
				report.claimCreatedByUser,
				claimCreatedDate,
				claimFormUploadedDate,
				dateOnSet,
				dateTreatmentStart,
				lastInteraction,
				lastInteractionDate,
				report.lastInteractionUser,
				report.assignedToUser,
				report.claimStatus,
				report.rejectionOverriddenByUser,
				report.claimFormType,
				daysOpenClosed,
				claimClosedDate,
				report.claimClosedByUser,
				report.isFastTrackReviewed,
				report.fastTrackReason,
				report.claimAssessedByUser,
			);
		}),
	};
}

export function toUserStatsReportModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((report) => {
			return new UserStatsReportModel(
				toUser(report.user),
				report.brandName,
				report.userActive,
				report.teams.map(t => t.name).join('; ') || 'No Team',
				report.claimsCreated,
				report.claimsClosed,
				report.optInDurationSec,
				report.optOutDurationSec,
				report.logInTimeSec,
			);
		}),
	};
}

export function toClaimQualityAuditReportModel ({ result, ...paging }) {
	return {
		...paging,
		items: result.map((report) => {
			const locale = report.brandLocale;
			const claimAmountSubmitted = currency(report.claimAmountSubmitted, locale, true);
			const claimAmountPaid = currency(report.claimAmountPaid, locale, true);
			const claimCreatedDate = shortDateTime(report.claimCreatedDate);
			const claimDateOfLoss = shortDate(report.claimDateOfLoss) || 'No Date of Loss';
			const claimClosedDateTime = shortDateTime(report.claimClosedDateTime) || 'No Closed Date';
			const invoiceNumbers = report.invoiceNumbers.join('; ') || 'No Invoices';
			return new ClaimQualityAuditReportModel(
				report.brandName,
				locale,
				report.customerId,
				report.customerExtRef,
				report.customerExtId,
				report.policyId,
				report.policyReference,
				report.claimId,
				report.customerFirstName,
				report.customerLastName,
				report.claimFormType,
				report.petId,
				report.petName,
				report.petExtRef,
				report.petExtId,
				claimCreatedDate,
				claimDateOfLoss,
				claimClosedDateTime,
				claimAmountSubmitted,
				claimAmountPaid,
				report.claimStatus,
				report.claimAssessedByUser,
				report.fraudCheckReasons,
				invoiceNumbers,
			);
		}),
	};
}

// filters to view
export function toReportViewFilter (reportGroup, apiFiltersList) {
	let filter = {};
	let filterColumns = {};
	const apiFilters = groupBy(apiFiltersList, 'type');
	switch (reportGroup) {
	case FilterGroupEnum.ClaimReport: {
		filter = toClaimReportViewFilter(apiFilters);
		filterColumns = claimReportFilterColumns;
		break;
	}
	case FilterGroupEnum.ClaimPayments: {
		filter = toClaimPaymentsViewFilter(apiFilters);
		filterColumns = claimPaymentFilterColumns;
		break;
	}
	case FilterGroupEnum.ClaimStatus: {
		filter = toClaimStatusFilter(apiFilters);
		filterColumns = claimStatusFilterColumns;
		break;
	}
	case FilterGroupEnum.UserStats: {
		filter = toUserStatsFilter(apiFilters);
		filterColumns = userStatsFilterColumns;
		break;
	}
	case FilterGroupEnum.QualityAuditReport: {
		filter = toQualityAuditFilter(apiFilters);
		filterColumns = qualityAuditFilterColumns;
		break;
	}
	default: return null;
	}
	filter.selectedAggregate = setSelectedFilterValue(apiFilters[FilterType.Aggregate], 'Aggregate', FilterType.Aggregate);
	if (Object.keys(apiFilters).includes(FilterType.Aggregate)) {
		const appliedToTypes = getAggregateFunctionAppliedToType(apiFilters[FilterType.Aggregate]);
		if (appliedToTypes) {
			filter.selectedAggregate.value.appliedToTypes = appliedToTypes;
		}
	}
	filter.selectedColumn = setSelectedFilterValue(apiFilters[FilterType.Column], 'Column', FilterType.Column);
	if (Object.keys(apiFilters).includes(FilterType.Column)) {
		const dataType = getClaimReportAggregateColumnFilterDataType(...apiFilters[FilterType.Column], filterColumns);
		if (dataType) {
			filter.selectedColumn.value.dataType = dataType;
		}
	}
	filter.selectedGroupBy = setSelectedFilterValue(apiFilters[FilterType.GroupBy], 'Group By', FilterType.GroupBy);
	return filter;
}

export function toClaimReportViewFilter (apiFilters) {
	const filter = {
		dates: {

		},
		numbers: {

		},
	};

	// converting from save user filters to the corresponding value on UI filters
	filter.selectedBrand = setSelectedFilterValue(apiFilters[FilterType.Brand], Filter.Brand, FilterType.Brand, true);
	filter.selectedProduct = setSelectedFilterValue(apiFilters[FilterType.Product], Filter.Product, FilterType.Product, true);
	filter.selectedAilment = setSelectedFilterValue(apiFilters[FilterType.Ailment], Filter.Ailment, FilterType.Ailment, true);
	filter.selectedConditionType = setSelectedFilterValue(apiFilters[FilterType.ConditionType], Filter.ConditionType, FilterType.ConditionType, true);
	filter.selectedBreed = setSelectedFilterValue(apiFilters[FilterType.Breed], Filter.Breed, FilterType.Breed, true);
	filter.selectedPetType = setSelectedFilterValue(apiFilters[FilterType.PetType], Filter.Species, FilterType.PetType);
	filter.selectedGender = setSelectedFilterValue(apiFilters[FilterType.PetGender], Filter.Genders, FilterType.PetGender);
	filter.selectedReason = setSelectedFilterValue(apiFilters[FilterType.RejectionReason], Filter.RejectionReasons, FilterType.RejectionReason, true);
	filter.selectedUserDeductionType = setSelectedFilterValue(apiFilters[FilterType.UserDeductionType], Filter.UserDeductions, FilterType.UserDeductionType, true);
	filter.selectedPolicyStatus = setSelectedFilterValue(apiFilters[FilterType.PolicyStatus], Filter.PolicyStatus, FilterType.PolicyStatus, true);
	filter.selectedClaimStatus = setSelectedFilterValue(apiFilters[FilterType.ClaimStatus], Filter.ClaimStatus, FilterType.ClaimStatus, true);
	filter.selectedReassessmentReason = setSelectedFilterValue(apiFilters[FilterType.ReassessmentReason], Filter.ReassessmentReason, FilterType.ReassessmentReason, true);
	filter.selectedReassessmentOutcome = setSelectedFilterValue(apiFilters[FilterType.ReassessmentOutcome], Filter.ReassessmentOutcome, FilterType.ReassessmentOutcome, true);
	filter.selectedUser = setSelectedFilterValue(apiFilters[FilterType.RejectionOverriddenBy], Filter.OverriddenBy, FilterType.RejectionOverriddenBy);
	filter.selectedClaimType = setSelectedFilterValue(apiFilters[FilterType.ClaimType], Filter.ClaimType, FilterType.ClaimType);
	filter.selectedClaimFormType = setSelectedFilterValue(apiFilters[FilterType.ClaimFormType], Filter.FormType, FilterType.ClaimFormType, true);
	filter.isFastTrackReviewed = setSelectedFilterValue(apiFilters[FilterType.IsFastTrackReviewed], Filter.FastTrackReviewed, FilterType.IsFastTrackReviewed);
	filter.selectedVet = setSelectedFilterValue(apiFilters[FilterType.Vet], Filter.Vet, FilterType.Vet, true);
	filter.selectedCounty = setSelectedFilterValue(apiFilters[Filter.County], Filter.County, FilterType.County, true);

	// converting date filters separately because of it's structure
	filter.dates.petDateOfBirth = setDateFilter(apiFilters[FilterType.PetDateOfBirthFrom], apiFilters[FilterType.PetDateOfBirthTo], Filter.PetDateOfBirth);
	filter.dates.inceptionDate = setDateFilter(apiFilters[FilterType.PolicyInceptionDateFrom], apiFilters[FilterType.PolicyInceptionDateTo], Filter.InceptionDate);
	filter.dates.datePolicyStart = setDateFilter(apiFilters[FilterType.DatePolicyStartFrom], apiFilters[FilterType.DatePolicyStartTo], Filter.DatePolicyStart);
	filter.dates.dateOnSet = setDateFilter(apiFilters[FilterType.ClaimDateOfLossFrom], apiFilters[FilterType.ClaimDateOfLossTo], Filter.DateOnSet);
	filter.dates.dateTreatmentStart = setDateFilter(apiFilters[FilterType.ClaimTreatmentStartDateFrom], apiFilters[FilterType.ClaimTreatmentStartDateTo], Filter.TreatmentStart);
	filter.dates.dateTreatmentEnd = setDateFilter(apiFilters[FilterType.ClaimTreatmentEndDateFrom], apiFilters[FilterType.ClaimTreatmentEndDateTo], Filter.TreatmentEnd);
	filter.dates.datePaid = setDateFilter(apiFilters[FilterType.ClaimDatePaidFrom], apiFilters[FilterType.ClaimDatePaidTo], Filter.DatePaid);
	filter.dates.claimCreatedDate = setDateFilter(apiFilters[FilterType.ClaimCreatedDateFrom], apiFilters[FilterType.ClaimCreatedDateTo], Filter.ClaimCreatedDate);
	filter.dates.claimClosedDate = setDateFilter(apiFilters[FilterType.ClaimClosedDateFrom], apiFilters[FilterType.ClaimClosedDateTo], Filter.ClaimClosedDate);

	filter.numbers.exGratiaAmount = setNumberFilter(apiFilters[FilterType.ExGratiaPaymentFrom], apiFilters[FilterType.ExGratiaPaymentTo], apiFilters[FilterType.ExGratiaPaymentEqual], Filter.ExGratiaAmount);

	return filter;
}

export function toClaimPaymentsViewFilter (apiFilters) {
	const filter = {
		dates: {

		},
		numbers: {

		},
	};
	filter.selectedPaymentMethod = setSelectedFilterValue(apiFilters[FilterType.PaymentType], Filter.PaymentType, FilterType.PaymentType);
	filter.dates.datePaid = setDateFilter(apiFilters[FilterType.ClaimDatePaidFrom], apiFilters[FilterType.ClaimDatePaidTo], Filter.ClosedPaidDate);
	filter.numbers.paymentAmount = setNumberFilter(apiFilters[FilterType.PaymentAmountFrom], apiFilters[FilterType.PaymentAmountTo], apiFilters[FilterType.PaymentAmountEqual], Filter.PaymentAmount);
	return filter;
}

export function toClaimStatusFilter (apiFilters) {
	const filter = {
		dates: {

		},
	};
	// converting from save user filters to the corresponding value on UI filters
	filter.selectedBrand = setSelectedFilterValue(apiFilters[FilterType.Brand], Filter.Brand, FilterType.Brand, true);
	filter.selectedPetType = setSelectedFilterValue(apiFilters[FilterType.PetType], Filter.Species, FilterType.PetType);
	filter.selectedBreed = setSelectedFilterValue(apiFilters[FilterType.Breed], Filter.Breed, FilterType.Breed, true);
	filter.selectedAilment = setSelectedFilterValue(apiFilters[FilterType.Ailment], Filter.Ailment, FilterType.Ailment, true);
	filter.selectedConditionType = setSelectedFilterValue(apiFilters[FilterType.ConditionType], Filter.ConditionType, FilterType.ConditionType, true);
	filter.selectedUser = setSelectedFilterValue(apiFilters[FilterType.RejectionOverriddenBy], Filter.OverriddenBy, FilterType.RejectionOverriddenBy);
	filter.selectedClaimStatus = setSelectedFilterValue(apiFilters[FilterType.ClaimStatus], Filter.ClaimStatus, FilterType.ClaimStatus, true);
	filter.selectedClaimFormType = setSelectedFilterValue(apiFilters[FilterType.ClaimFormType], Filter.FormType, FilterType.ClaimFormType, true);
	filter.selectedClosedBy = setSelectedFilterValue(apiFilters[FilterType.ClaimClosedBy], Filter.ClaimClosedBy, FilterType.ClaimClosedBy, true);
	filter.selectedAssignedTo = setSelectedFilterValue(apiFilters[FilterType.ClaimAssignedToId], Filter.ClaimAssignedTo, FilterType.ClaimAssignedToId, true);
	filter.isFastTrackReviewed = setSelectedFilterValue(apiFilters[FilterType.IsFastTrackReviewed], Filter.FastTrackReviewed, FilterType.IsFastTrackReviewed);
	filter.selectedVet = setSelectedFilterValue(apiFilters[FilterType.Vet], Filter.Vet, FilterType.Vet, true);
	filter.selectedCounty = setSelectedFilterValue(apiFilters[Filter.County], Filter.County, FilterType.County, true);

	// converting date filters separately because of it's structure
	filter.dates.claimCreatedDate = setDateFilter(apiFilters[FilterType.ClaimCreatedDateFrom], apiFilters[FilterType.ClaimCreatedDateTo], Filter.ClaimCreatedDate);
	filter.dates.claimFormUploadedDate = setDateFilter(apiFilters[FilterType.ClaimFormUploadedDateFrom], apiFilters[FilterType.ClaimFormUploadedDateTo], Filter.ClaimFormUploadedDate);
	filter.dates.dateOnSet = setDateFilter(apiFilters[FilterType.ClaimDateOfLossFrom], apiFilters[FilterType.ClaimDateOfLossTo], Filter.DateOnSet);
	filter.dates.dateTreatmentStart = setDateFilter(apiFilters[FilterType.ClaimTreatmentStartDateFrom], apiFilters[FilterType.ClaimTreatmentStartDateTo], Filter.TreatmentStart);
	filter.dates.lastInteractionDate = setDateFilter(apiFilters[FilterType.LastInteractionDateFrom], apiFilters[FilterType.LastInteractionDateTo], Filter.LastInteractionDate);
	filter.dates.claimClosedDate = setDateFilter(apiFilters[FilterType.ClaimClosedDateFrom], apiFilters[FilterType.ClaimClosedDateTo], Filter.ClaimClosedDate);

	return filter;
}

export function toUserStatsFilter (apiFilters) {
	const filter = {
		dates: {

		},
	};
	filter.statsTeams = setSelectedFilterValue(apiFilters[FilterType.StatsTeams], Filter.StatsTeams, FilterType.StatsTeams, true, null, false);
	filter.statsUsers = setSelectedFilterValue(apiFilters[FilterType.StatsUsers], Filter.StatsUsers, FilterType.StatsUsers, true, null, false);
	filter.statsUserActive = setSelectedFilterValue(apiFilters[FilterType.StatsUserActive], Filter.StatsActiveUsers, FilterType.StatsUserActive, false, null, false);
	filter.selectedBrand = setSelectedFilterValue(apiFilters[FilterType.Brand], Filter.Brand, FilterType.Brand, true, null, false);

	// converting date filters separately because of it's structure
	filter.dates.statsDate = setDateFilter(apiFilters[FilterType.DateTimeFrom], apiFilters[FilterType.DateTimeTo], Filter.StatsDate);
	return filter;
}

export function toQualityAuditFilter (apiFilters) {
	const filter = {
		dates: {

		},
	};
	// converting from save user filters to the corresponding value on UI filters
	filter.selectedBrand = setSelectedFilterValue(apiFilters[FilterType.Brand], Filter.Brand, FilterType.Brand, true);
	filter.selectedClaimStatus = setSelectedFilterValue(apiFilters[FilterType.ClaimStatus], Filter.ClaimStatus, FilterType.ClaimStatus, true);
	filter.selectedFraudFlag = setSelectedFilterValue(apiFilters[FilterType.FraudFlag], Filter.FraudFlags, FilterType.FraudFlag, true);

	// converting date filters separately because of it's structure
	filter.dates.claimCreatedDate = setDateFilter(apiFilters[FilterType.ClaimCreatedDateFrom], apiFilters[FilterType.ClaimCreatedDateTo], Filter.ClaimCreatedDate);
	filter.dates.claimClosedDate = setDateFilter(apiFilters[FilterType.ClaimClosedDateFrom], apiFilters[FilterType.ClaimClosedDateTo], Filter.ClaimClosedDate);

	return filter;
}

function getAggregateFunctionAppliedToType (aggregateFunction) {
	return aggregateFunction ? aggregateFunctionsList.find((func) => func.id === aggregateFunction.value).appliedToTypes : null;
}

function getClaimReportAggregateColumnFilterDataType (aggregateColumn, filterColumns) {
	return aggregateColumn ? filterColumns.find((column) => column.id === aggregateColumn.value).dataType : null;
}

// to api
export function toReportsApiFilters (filterData) {
	return toApiFilters(filterData);
}

export function toReportChartModel (apiResult) {
	if (apiResult.result.length === 0) return;
	const result = apiResult.result;
	const title = apiResult.chartTitle;
	let scaleOptions;
	let labels;
	let data;
	if (typeof result[0].value === 'string'
		&& isDate(result[0].value)) {
		// if a date - change the y-axis format
		scaleOptions = {
			yAxes: [{
				type: 'time',
				time: {
					unit: 'day',
					displayFormats: {
						day: DateFormats.ShortDate,
					},
				},
				distribution: 'series',
			}],
		};
		// and convert to date
		data = result.map((group) => ({ y: fromStringToDate(group.value) }));
	}	else {
		// if value is number - just display as it is
		data = result.map((group) => parseFloat(group.value.toFixed(2)));
		scaleOptions = {
			yAxes: [{
				ticks: {
					min: 0,
					callback: function (value) {
						return value;
					},
				},
			}],
		};
	}
	if (isDate(result[0].key) && result[0].key !== '') {
		labels = result.map((group) => shortDate(group.key));
	} else {
		labels = result.map((group) => group.key === '' ? 'Uncategorized' : group.key);
	}
	return { data, labels, scaleOptions, title };
}

export function toViewSorting (reportGroup, apiSorting) {
	const countOfSortSelectors = 4;
	// create an empty sorting as default
	const viewSorting = Array.from({ length: countOfSortSelectors }, () => ({
		value: null,
		direction: SortingDirection.Ascending,
	}));
	if (apiSorting) {
		// and then fill it with saved users sorting values and direction
		apiSorting.forEach((sortItem, index) => {
			viewSorting[index].value = getSelectedSortingColumnByField(reportGroup, sortItem);
			viewSorting[index].direction = sortItem.direction;
		});
	}
	return viewSorting;
}

export function toSortingTerms (viewSorting) {
	return viewSorting.filter(sortItem => sortItem.value)
		.map((sortItem, index) => {
			const label = index === 0 ? 'Sort By' : 'Then By';
			const value = `${sortItem.value.fieldView} - ${sortItem.direction === SortingDirection.Ascending ? 'Asc' : 'Desc'}`;
			return { label, value };
		});
}

export function toApiSorting (viewSorting) {
	return viewSorting.filter(sortItem => sortItem.value)
		.map(sortItem => ({ direction: sortItem.direction, field: sortItem.value.field }));
}

function getSelectedSortingColumnByField (reportGroup, sortItem) {
	let sortingColumns = [];
	switch (reportGroup) {
	case FilterGroupEnum.ClaimReport:
		sortingColumns = ClaimReportSortingColumns;
		break;
	case FilterGroupEnum.ClaimPayments:
		sortingColumns = ClaimPaymentSortingColumns;
		break;
	case FilterGroupEnum.ClaimStatus:
		sortingColumns = ClaimStatusSortingColumns;
		break;
	case FilterGroupEnum.UserStats:
		sortingColumns = UserStatsSortingColumns;
		break;
	case FilterGroupEnum.QualityAuditReport:
		sortingColumns = ClaimQualityAuditSortingColumns;
		break;
	case FilterGroupEnum.WorkQueueDraft:
		sortingColumns = DraftClaimsSortingColumns;
		break;
	case FilterGroupEnum.WorkQueueSettled:
		sortingColumns = PaymentApprovalClaimsSortingColumns;
		break;
	case FilterGroupEnum.WorkQueueFraudCheck:
		sortingColumns = FraudClaimsSortingColumns;
		break;
	case FilterGroupEnum.WorkQueueRejectionPending:
		sortingColumns = RejectionPendingClaimsSortingColumns;
		break;
	case FilterGroupEnum.WorkQueueMissingInfo:
		sortingColumns = MissingInfoClaimsSortingColumns;
		break;
	case FilterGroupEnum.WorkQueueReassessmentPending:
		sortingColumns = ClaimsToReassessSortingColumns;
		break;
	}
	return sortingColumns.find(sortColumn => sortColumn.field === sortItem.field);
}

export function toOutcomeLabel ({ id, description, subgroup }) {
	if (!subgroup) {
		return {
			id,
			description,
		};
	}
	return {
		id,
		description: `${subgroup} - ${description}`,
	};
}
