const SupportedLocales = {
	enGB: 'en-gb',
	enUS: 'en-us',
	enAU: 'en-au',
	enNZ: 'en-nz',
	enCA: 'en-ca',
};

const formats = {
	[SupportedLocales.enGB]: {
		parseDateFormats: [
			'dd/MM/yyyy',
			'dd/MM/yy',
			'dd.MM.yyyy',
			'dd.MM.yy',
			'dd-MM-yyyy',
			'dd-MM-yy',
		],
		displayDateFormats: {
			LongDate: 'do MMMM yyyy',
			ShortDate: 'dd/MM/yyyy',
			ShortDateTime: 'dd/MM/yyyy h:mm a',
			ShortDateTimeDay: 'eee dd/MM/yyyy h:mm a',
			ShortDateNoCentury: 'dd/MM/yy',
			ShortDateTimeNoCentury: 'dd/MM/yy h:mm a',
			ShortDateTimeDayNoCentury: 'eee dd/MM/yy h:mm a',
		},
	},
	[SupportedLocales.enUS]: {
		parseDateFormats: [
			'MM/dd/yyyy',
			'MM/dd/yy',
			'MM.dd.yyyy',
			'MM.dd.yy',
			'MM-dd-yyyy',
			'MM-dd-yy',
		],
		displayDateFormats: {
			LongDate: 'MMMM do yyyy',
			ShortDate: 'MM/dd/yyyy',
			ShortDateTime: 'MM/dd/yyyy h:mm a',
			ShortDateTimeDay: 'eee MM/dd/yyyy h:mm a',
			ShortDateNoCentury: 'MM/dd/yy',
			ShortDateTimeNoCentury: 'MM/dd/yy h:mm a',
			ShortDateTimeDayNoCentury: 'eee MM/dd/yy h:mm a',
		},
	},
};

formats[SupportedLocales.enAU] = { ...formats[SupportedLocales.enGB] };
formats[SupportedLocales.enNZ] = { ...formats[SupportedLocales.enGB] };
formats[SupportedLocales.enCA] = { ...formats[SupportedLocales.enUS] };

const Currencies = {
	[SupportedLocales.enGB]: {
		symbol: '£',
		precision: 2,
		prefix: true,
	},
	[SupportedLocales.enUS]: {
		symbol: '$',
		precision: 2,
		prefix: true,
	},
};
Currencies[SupportedLocales.enAU] = { ...Currencies[SupportedLocales.enUS] };
Currencies[SupportedLocales.enNZ] = { ...Currencies[SupportedLocales.enUS] };
Currencies[SupportedLocales.enCA] = { ...Currencies[SupportedLocales.enUS] };

const UiSettingsList = {
	[SupportedLocales.enGB]: {
		paymentLabel: 'BACS',
		bankCodeRegex: /^[0-9]{6}$/,
		bankCodeLabel: 'Sort Code',
		bankCodeErrorMessage: 'Value should contain 6 digits',
		accountNumberRegex: /^[0-9]{8,9}$/,
		accountNumberErrorMessage: 'Value should contain 8 or 9 digits',
		postalCodeRegex: /^.{6,9}$/,
		postalCodeErrorMessage: 'The field length should be between 6 and 9',
		regionCountyLabel: 'County',
		excessLabel: 'Excess',
		excessDeductionsLabel: 'Excess Deductions',
		amountExcessLabel: 'Amount Excess',
		deductibleLabel: 'Excess',
		payeeLabel: 'Policy Holder',
	},
	[SupportedLocales.enUS]: {
		paymentLabel: 'ACH',
		bankCodeRegex: /^[0-9]{9}$/,
		bankCodeLabel: 'Routing Number',
		bankCodeErrorMessage: 'Value should contain 9 digits',
		accountNumberRegex: /^[0-9]{4,17}$/,
		accountNumberErrorMessage: 'Value should contain 4-17 digits',
		postalCodeRegex: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
		postalCodeErrorMessage: 'The field should be 5 or 9(5-4) digits value',
		regionCountyLabel: 'State',
		excessLabel: 'Deductibles',
		excessDeductionsLabel: 'Deductible',
		amountExcessLabel: 'Amount Deductible',
		deductibleLabel: 'Deductible',
		payeeLabel: 'Policy Holder',
	},
	[SupportedLocales.enAU]: {
		paymentLabel: 'EFT',
		bankCodeRegex: /^[0-9]{6}$/,
		bankCodeLabel: 'BSB Number',
		bankCodeErrorMessage: 'Value should contain 6 digits',
		accountNumberRegex: /^[0-9]{6,10}$/,
		accountNumberErrorMessage: 'Value should contain 6-10 digits',
		postalCodeRegex: /^.{1,4}$/,
		postalCodeErrorMessage: 'The field length should be between 1 and 4',
		regionCountyLabel: 'Suburb',
		excessLabel: 'Excess',
		excessDeductionsLabel: 'Excess Deductions',
		amountExcessLabel: 'Amount Excess',
		deductibleLabel: 'Excess',
		payeeLabel: 'Policy Holder',
	},
	[SupportedLocales.enNZ]: {
		paymentLabel: 'EFT',
		bankCodeRegex: /^[0-9]{6}$/,
		bankCodeLabel: 'Bank/Branch Number',
		bankCodeErrorMessage: 'Value should contain 6 digits',
		accountNumberRegex: /^[0-9]{7,12}$/,
		accountNumberErrorMessage: 'Value should contain 7-12 digits',
		postalCodeRegex: /^[0-9]{4}$/,
		postalCodeErrorMessage: 'Value should contain 4 digits',
		regionCountyLabel: 'Suburb',
		excessLabel: 'Excess',
		excessDeductionsLabel: 'Excess Deductions',
		amountExcessLabel: 'Amount Excess',
		deductibleLabel: 'Excess',
		payeeLabel: 'Policy Holder',
	},
	[SupportedLocales.enCA]: {
		paymentLabel: 'EFT',
		bankCodeRegex: /^0\d{8}$/,
		bankCodeLabel: 'Institution-Transit Number',
		bankCodeErrorMessage: 'Value should contain 9 digits with leading 0',
		accountNumberRegex: /^[0-9]{7,12}$/,
		accountNumberErrorMessage: 'Value should contain 7-12 digits',
		externalValidation: false,
		postalCodeRegex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
		postalCodeErrorMessage: 'Incorrect postal code',
		regionCountyLabel: 'State',
		excessLabel: 'Deductibles',
		excessDeductionsLabel: 'Deductible',
		amountExcessLabel: 'Amount Deductible',
		deductibleLabel: 'Deductible',
		payeeLabel: 'Policy Holder',
	},
};

const locale = (ClaimsConfig.LOCALE || SupportedLocales.enGB).toLowerCase();
const currentLocale = Object.values(SupportedLocales).includes(locale) ? locale : SupportedLocales.enGB;

const currentFormat = formats[currentLocale];
const ParseDateFormats = Object.freeze([...currentFormat.parseDateFormats]);
const DateFormats = Object.freeze({ ...currentFormat.displayDateFormats });
const Currency = Object.freeze({ ...Currencies[currentLocale] });
const UiLocaleSettings = Object.freeze({ ...UiSettingsList[currentLocale] });

const getCurrencyByLocale = locale => (locale && Currencies[locale.toLowerCase()]) || Currency;
const getUiSettingsByLocale = locale => (locale && UiSettingsList[locale.toLowerCase()]) || UiLocaleSettings;

export { ParseDateFormats, DateFormats, Currency, UiLocaleSettings, currentLocale, SupportedLocales, getCurrencyByLocale, getUiSettingsByLocale };
