const chartDataType = Object.freeze({
	numeric: 'numeric',
	date: 'date',
	bool: 'bool',
});

const AggregateFunction = Object.freeze({
	Sum: 'Sum',
	Min: 'Min',
	Max: 'Max',
	Count: 'Count',
	Average: 'Average',
});

const AggregateFunctionDisplayMap = Object.freeze({
	[AggregateFunction.Sum]: 'Sum',
	[AggregateFunction.Min]: 'Min',
	[AggregateFunction.Max]: 'Max',
	[AggregateFunction.Count]: 'Count',
	[AggregateFunction.Average]: 'Average',
});

const GroupBy = Object.freeze({
	Breed: 'Breed',
	Product: 'Product',
	Ailment: 'Ailment',
	ConditionType: 'ConditionType',
	Brand: 'Brand',
	Species: 'Species',
	Gender: 'Gender',
	RejectionReason: 'RejectionReason',
	PolicyStatus: 'PolicyStatus',
	ClaimStatus: 'ClaimStatus',
	DateOnSet: 'DateOnSet',
	TreatmentStart: 'TreatmentStart',
	TreatmentEnd: 'TreatmentEnd',
	DatePaid: 'DatePaid',
	ClaimCreatedDate: 'ClaimCreatedDate',
	PetDateOfBirth: 'PetDateOfBirth',
	InceptionDate: 'InceptionDate',
	DatePolicyStart: 'DatePolicyStart',
	PaymentType: 'PaymentType',
	ClaimFormUploadedDate: 'ClaimFormUploadedDate',
	LastInteractionDate: 'LastInteractionDate',
	ClosedByUser: 'ClosedByUser',
	ByTeam: 'ByTeam',
	ByUser: 'ByUser',
});

const GroupByDisplayMap = Object.freeze({
	[GroupBy.Breed]: 'Breed',
	[GroupBy.Product]: 'Product',
	[GroupBy.Ailment]: 'Ailment',
	[GroupBy.ConditionType]: 'Condition Type',
	[GroupBy.Brand]: 'Brand',
	[GroupBy.Species]: 'Species',
	[GroupBy.Gender]: 'Gender',
	[GroupBy.RejectionReason]: 'Rejection Reason',
	[GroupBy.PolicyStatus]: 'Policy Status',
	[GroupBy.ClaimStatus]: 'Claim Status',
	[GroupBy.DateOnSet]: 'Date On Set',
	[GroupBy.TreatmentStart]: 'Treatment Start',
	[GroupBy.TreatmentEnd]: 'Treatment End',
	[GroupBy.DatePaid]: 'Date Paid',
	[GroupBy.ClaimCreatedDate]: 'Claim Created Date',
	[GroupBy.PetDateOfBirth]: 'Pet Date Of Birth',
	[GroupBy.InceptionDate]: 'Inception Date',
	[GroupBy.DatePolicyStart]: 'Date Policy Start',
	[GroupBy.PaymentType]: 'Payment Type',
	[GroupBy.ClaimFormUploadedDate]: 'Claim Form Uploaded Date',
	[GroupBy.LastInteractionDate]: 'Last Interaction Date',
	[GroupBy.ClosedByUser]: 'Closed By User',
	[GroupBy.ByTeam]: 'By Team',
	[GroupBy.ByUser]: 'By User',
});

const AggregateColumn = Object.freeze({
	PetDateOfBirth: 'PetDateOfBirth',
	PetAge: 'PetAge',
	PetAgeTreatmentStart: 'PetAgeTreatmentStart',
	InceptionDate: 'InceptionDate',
	DatePolicyStart: 'DatePolicyStart',
	DateOnSet: 'DateOnSet',
	DateTreatmentStart: 'DateTreatmentStart',
	DateTreatmentEnd: 'DateTreatmentEnd',
	DatePaid: 'DatePaid',
	ClaimCreatedDate: 'ClaimCreatedDate',
	AmountPaid: 'AmountPaid',
	AmountSubmitted: 'AmountSubmitted',
	AmountNonInsurable: 'AmountNonInsurable',
	AmountExcess: 'AmountExcess',
	AmountCoInsurance: 'AmountCoInsurance',
	ClosedPaidDate: 'ClosedPaidDate',
	ClaimFormUploadedDate: 'ClaimFormUploadedDate',
	LastInteractionDate: 'LastInteractionDate',
	ClaimsCreated: 'ClaimsCreated',
	ClaimsClosed: 'ClaimsClosed',
	FastTrackReviewed: 'FastTrackReviewed',
});

const AggregateColumnDisplayMap = Object.freeze({
	[AggregateColumn.PetDateOfBirth]: 'Pet Date Of Birth',
	[AggregateColumn.PetAge]: 'Pet Age',
	[AggregateColumn.PetAgeTreatmentStart]: 'Pet Age Treatment Start',
	[AggregateColumn.InceptionDate]: 'Inception Date',
	[AggregateColumn.DatePolicyStart]: 'Date Policy Start',
	[AggregateColumn.DateOnSet]: 'Date On Set',
	[AggregateColumn.DateTreatmentStart]: 'Date Treatment Start',
	[AggregateColumn.DateTreatmentEnd]: 'Date Treatment End',
	[AggregateColumn.DatePaid]: 'Date Paid',
	[AggregateColumn.ClaimCreatedDate]: 'Claim Created Date',
	[AggregateColumn.AmountPaid]: 'Amount Paid',
	[AggregateColumn.AmountSubmitted]: 'Amount Submitted',
	[AggregateColumn.AmountNonInsurable]: 'Amount Non-Insurable',
	[AggregateColumn.AmountExcess]: 'Amount Excess',
	[AggregateColumn.AmountCoInsurance]: 'Amount Co-Insurance',
	[AggregateColumn.ClosedPaidDate]: 'Closed-Paid Date',
	[AggregateColumn.ClaimFormUploadedDate]: 'Claim Form Uploaded Date',
	[AggregateColumn.LastInteractionDate]: 'Last Interaction Date',
	[AggregateColumn.ClosedById]: 'Claim Closed By',
	[AggregateColumn.ClaimsCreated]: 'Claims Created',
	[AggregateColumn.ClaimsClosed]: 'Claims Closed',
	[AggregateColumn.FastTrackReviewed]: 'Fast Track Reviewed',
});

const aggregateFunctionsList = [
	{
		id: AggregateFunction.Sum,
		description: AggregateFunctionDisplayMap.Sum,
		appliedToTypes: [chartDataType.numeric],
	},
	{
		id: AggregateFunction.Max,
		description: AggregateFunctionDisplayMap.Max,
		appliedToTypes: [chartDataType.numeric, chartDataType.date],
	},
	{
		id: AggregateFunction.Min,
		description: AggregateFunctionDisplayMap.Min,
		appliedToTypes: [chartDataType.numeric, chartDataType.date],
	},
	{
		id: AggregateFunction.Count,
		description: AggregateFunctionDisplayMap.Count,
		appliedToTypes: [chartDataType.numeric, chartDataType.date, chartDataType.bool],
	},
	{
		id: AggregateFunction.Average,
		description: AggregateFunctionDisplayMap.Average,
		appliedToTypes: [chartDataType.numeric],
	},
];

const claimReportFilterColumns = [
	{
		id: AggregateColumn.PetDateOfBirth,
		description: AggregateColumnDisplayMap.PetDateOfBirth,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.PetAge,
		description: AggregateColumnDisplayMap.PetAge,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.PetAgeTreatmentStart,
		description: AggregateColumnDisplayMap.PetAgeTreatmentStart,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.InceptionDate,
		description: AggregateColumnDisplayMap.InceptionDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DatePolicyStart,
		description: AggregateColumnDisplayMap.DatePolicyStart,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DateOnSet,
		description: AggregateColumnDisplayMap.DateOnSet,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DateTreatmentStart,
		description: AggregateColumnDisplayMap.DateTreatmentStart,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DateTreatmentEnd,
		description: AggregateColumnDisplayMap.DateTreatmentEnd,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DatePaid,
		description: AggregateColumnDisplayMap.DatePaid,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.ClaimCreatedDate,
		description: AggregateColumnDisplayMap.ClaimCreatedDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.AmountPaid,
		description: AggregateColumnDisplayMap.AmountPaid,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.AmountSubmitted,
		description: AggregateColumnDisplayMap.AmountSubmitted,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.AmountNonInsurable,
		description: AggregateColumnDisplayMap.AmountNonInsurable,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.AmountExcess,
		description: AggregateColumnDisplayMap.AmountExcess,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.AmountCoInsurance,
		description: AggregateColumnDisplayMap.AmountCoInsurance,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.FastTrackReviewed,
		description: AggregateColumnDisplayMap.FastTrackReviewed,
		dataType: chartDataType.bool,
	},
];

const claimPaymentFilterColumns = [
	{
		id: AggregateColumn.ClosedPaidDate,
		description: AggregateColumnDisplayMap.ClosedPaidDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.AmountPaid,
		description: AggregateColumnDisplayMap.AmountPaid,
		dataType: chartDataType.numeric,
	},
];

const claimStatusFilterColumns = [
	{
		id: AggregateColumn.ClaimCreatedDate,
		description: AggregateColumnDisplayMap.ClaimCreatedDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.ClaimFormUploadedDate,
		description: AggregateColumnDisplayMap.ClaimFormUploadedDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DateOnSet,
		description: AggregateColumnDisplayMap.DateOnSet,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.DateTreatmentStart,
		description: AggregateColumnDisplayMap.DateTreatmentStart,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.LastInteractionDate,
		description: AggregateColumnDisplayMap.LastInteractionDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.AmountSubmitted,
		description: AggregateColumnDisplayMap.AmountSubmitted,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.FastTrackReviewed,
		description: AggregateColumnDisplayMap.FastTrackReviewed,
		dataType: chartDataType.bool,
	},
];

const userStatsFilterColumns = [
	{
		id: AggregateColumn.ClaimsCreated,
		description: AggregateColumnDisplayMap.ClaimsCreated,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.ClaimsClosed,
		description: AggregateColumnDisplayMap.ClaimsClosed,
		dataType: chartDataType.numeric,
	},
];

const qualityAuditFilterColumns = [
	{
		id: AggregateColumn.ClaimCreatedDate,
		description: AggregateColumnDisplayMap.ClaimCreatedDate,
		dataType: chartDataType.date,
	},
	{
		id: AggregateColumn.AmountSubmitted,
		description: AggregateColumnDisplayMap.AmountSubmitted,
		dataType: chartDataType.numeric,
	},
	{
		id: AggregateColumn.AmountPaid,
		description: AggregateColumnDisplayMap.AmountPaid,
		dataType: chartDataType.numeric,
	},
];

const claimReportFiltersGroupBy = [
	{
		id: GroupBy.Brand,
		description: GroupByDisplayMap.Brand,
	},
	{
		id: GroupBy.Product,
		description: GroupByDisplayMap.Product,
	},
	{
		id: GroupBy.Species,
		description: GroupByDisplayMap.Species,
	},
	{
		id: GroupBy.Breed,
		description: GroupByDisplayMap.Breed,
	},
	{
		id: GroupBy.Ailment,
		description: GroupByDisplayMap.Ailment,
	},
	{
		id: GroupBy.ConditionType,
		description: GroupByDisplayMap.ConditionType,
	},
	{
		id: GroupBy.Gender,
		description: GroupByDisplayMap.Gender,
	},
	{
		id: GroupBy.RejectionReason,
		description: GroupByDisplayMap.RejectionReason,
	},
	{
		id: GroupBy.PolicyStatus,
		description: GroupByDisplayMap.PolicyStatus,
	},
	{
		id: GroupBy.ClaimStatus,
		description: GroupByDisplayMap.ClaimStatus,
	},
	{
		id: GroupBy.PetDateOfBirth,
		description: GroupByDisplayMap.PetDateOfBirth,
	},
	{
		id: GroupBy.DateOnSet,
		description: GroupByDisplayMap.DateOnSet,
	},
	{
		id: GroupBy.ClaimCreatedDate,
		description: GroupByDisplayMap.ClaimCreatedDate,
	},
	{
		id: GroupBy.TreatmentStart,
		description: GroupByDisplayMap.TreatmentStart,
	},
	{
		id: GroupBy.TreatmentEnd,
		description: GroupByDisplayMap.TreatmentEnd,
	},
	{
		id: GroupBy.InceptionDate,
		description: GroupByDisplayMap.InceptionDate,
	},
	{
		id: GroupBy.DatePaid,
		description: GroupByDisplayMap.DatePaid,
	},
	{
		id: GroupBy.DatePolicyStart,
		description: GroupByDisplayMap.DatePolicyStart,
	},
];

const claimPaymentsFiltersGroupBy = [
	{
		id: GroupBy.DatePaid,
		description: GroupByDisplayMap.DatePaid,
	},
	{
		id: GroupBy.PaymentType,
		description: GroupByDisplayMap.PaymentType,
	},
];

const claimStatusFiltersGroupBy = [
	{
		id: GroupBy.DatePaid,
		description: GroupByDisplayMap.DatePaid,
	},
	{
		id: GroupBy.ClaimCreatedDate,
		description: GroupByDisplayMap.ClaimCreatedDate,
	},
	{
		id: GroupBy.TreatmentStart,
		description: GroupByDisplayMap.TreatmentStart,
	},
	{
		id: GroupBy.LastInteractionDate,
		description: GroupByDisplayMap.LastInteractionDate,
	},
	{
		id: GroupBy.ClaimFormUploadedDate,
		description: GroupByDisplayMap.ClaimFormUploadedDate,
	},
	{
		id: GroupBy.Brand,
		description: GroupByDisplayMap.Brand,
	},
	{
		id: GroupBy.Species,
		description: GroupByDisplayMap.Species,
	},
	{
		id: GroupBy.Breed,
		description: GroupByDisplayMap.Breed,
	},
	{
		id: GroupBy.Ailment,
		description: GroupByDisplayMap.Ailment,
	},
	{
		id: GroupBy.ConditionType,
		description: GroupByDisplayMap.ConditionType,
	},
	{
		id: GroupBy.ClosedByUser,
		description: GroupByDisplayMap.ClosedByUser,
	},
];

const userStatsFiltersGroupBy = [
	{
		id: GroupBy.ByTeam,
		description: GroupByDisplayMap.ByTeam,
	},
	{
		id: GroupBy.ByUser,
		description: GroupByDisplayMap.ByUser,
	},
];

const qualityAuditFiltersGroupBy = [
	{
		id: GroupBy.Brand,
		description: GroupByDisplayMap.Brand,
	},
	{
		id: GroupBy.ClaimStatus,
		description: GroupByDisplayMap.ClaimStatus,
	},
	{
		id: GroupBy.ClaimCreatedDate,
		description: GroupByDisplayMap.ClaimCreatedDate,
	},
	{
		id: GroupBy.ClaimsClosed,
		description: GroupByDisplayMap.ClaimsClosed,
	},
];

export {
	claimReportFilterColumns,
	claimPaymentFilterColumns,
	claimStatusFilterColumns,
	userStatsFilterColumns,
	qualityAuditFilterColumns,
	claimReportFiltersGroupBy,
	claimPaymentsFiltersGroupBy,
	aggregateFunctionsList,
	claimStatusFiltersGroupBy,
	userStatsFiltersGroupBy,
	qualityAuditFiltersGroupBy,
};
