<template>
	<div>
		<aq-confirmation-modal
			ref="deleteQueueModal"
			name="delete-queue-modal"
			title="Confirm Delete Queue"
			:description="`You are about to delete '${queueName}' queue. Do you wish to continue?`"
			yes-label="Delete"
			no-label="Cancel"
			data-qa="tasks-page_modal_delete-queue"
		/>
		<aq-modal name="save-queue-modal">
			<save-filters-modal
				label="Queue"
				title="Save queue"
				:value="saveQueueValue"
				:description="saveQueueDescription"
				:is-name-uniq="isQueueNameUniq"
				@save="onSaveQueue"
				@touch="isQueueNameUniq = true"
				:reserved-names="$options.reservedQueueNames"
			/>
		</aq-modal>
		<aq-modal name="export-queue-modal">
			<export-modal
				title="Export Queue"
				@export="onExport($event)"
			/>
		</aq-modal>
		<div class="position-relative">
			<div
				v-if="isFiltersOpen"
				class="filters-content"
			>
				<div class="container-fluid">
					<div class="row">
						<div class="col-9 border-right">
							<div class="row section-header">
								<div class="icon mr-6">
									<i class="fa fa-table" />
								</div>
								<div class="section-header__title">
									Select Filters
								</div>
								<div
									class="d-flex ml-auto align-items-center mr-10"
								>
									<aq-checkbox
										:value="prioritizedQueue"
										class="round large thin"
										@input="$emit('select-tasks-auto-assignment')"
										:disabled="!$can.CreateQueue"
									/>
									<AutoAssignLogo
										class="assign-user-logo mx-10"
										:class="[!prioritizedQueue ? 'opacity-50' : '']"
									/>
									Auto Assignment
								</div>
								<aq-tooltip-static
									class="mr-10 ml-0 tooltip"
									:class="{ 'tooltip-disabled' : !$can.CreateQueue }"
									:disabled="!$can.CreateQueue"
									v-if="prioritizedQueue"
								>
									<div class="tooltip-arrow m-0" />
									<div class="tooltip-inner d-flex justify-content-between">
										<div
											class="aqv-user-simple fs-32 ml-4 mt-3 mb-3"
											:class="{'disabled' : !$can.CreateQueue}"
										/>
										<aq-max-min-number-input
											data-qa="taskPageCommonFilters_min-opt-in"
											input-class="mt-18 ml-n25 mb-2 min-opt-in-input"
											:value="minOptIn"
											:disabled="!$can.CreateQueue"
											:max-number="999"
											:min-number="0"
											@input="$emit('select-min-opt-in', $event)"
										/>
										<div class="d-flex align-items-center ml-5 mr-5">
											Min Opt In No
											<div
												v-if="!$can.CreateQueue"
												class="ml-5 lock"
											>
												<i class="fas fa-lock" />
											</div>
										</div>
									</div>
								</aq-tooltip-static>
							</div>
						</div>
						<div class="col-3">
							<div class="row section-header">
								<div class="icon">
									<i class="aqv-sorting-filter fs-32 text-primary" />
								</div>
								<div class="section-header__title">
									Sort
								</div>
							</div>
						</div>
						<div class="col-9 px-0 pt-0 pb-0 section-content border-right">
							<AqDynamicFilters
								:filter="filter"
								:available-filters="availableFilters"
								:filter-dictionaries="filterDictionaries"
							/>
						</div>
						<div class="col-3 p-20 section-content">
							<sorting
								:sorting-columns="sortingColumns"
								:sorting="sorting"
							/>
						</div>
					</div>
				</div>
				<div class="section-header d-flex justify-content-end">
					<button
						class="btn btn-row mr-8"
						@click="onCancel"
						data-qa="tasksPageFiltersControl_button_cancel"
					>
						Cancel
					</button>
					<button
						class="btn btn-primary"
						@click="onRun"
						data-qa="tasksPageFiltersControl_button_run"
					>
						Run
					</button>
				</div>
			</div>
			<div
				:class="{ invisible:hideFilters }"
				class="d-flex justify-content-end px-25 py-10"
			>
				<aq-search-box
					class="mr-6"
					:is-filter-enabled="false"
					btn-class="btn-primary"
					type="number"
					placeholder="Search by Claim ID"
					@search="onSearch"
				/>
				<button
					class="btn btn-primary px-14"
					@click="onFiltersOpen"
					data-qa="tasksPage_button_filters"
				>
					<i class="fas fa-filter mr-5" />
					Filters
				</button>
				<aq-dropdown
					v-if="$can.CreateQueue"
					data-qa="tasksPage_select_save"
					label="Save"
					:options="saveOptions"
					btn-class="btn btn-primary px-14"
					:is-read-only-mode="false"
					@select="onSelectAction"
					@main-action="onToggleSave"
					:split-toggle="true"
					:height="37"
					class="ml-6"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import WorkflowService from '@commonServices/workflowService';
import SaveFiltersModal from '@commonView/Shared/SaveFiltersModal';
import ErrorCode from '@commonServices/models/ErrorCode';
import { isKnownError } from '@commonServices/utils/general';
import { toApiSorting } from '@commonServices/utils/reportsConverter';
import eventBus from '@commonServices/eventBus';
import { EverytingElseQueueName, UnfilteredTasksQueueName } from '@commonServices/utils/constants';
import Sorting from '@commonWidgets/Sorting';
import { AutoAssignLogo } from '@assets/icons';
import AqMaxMinNumberInput from '@commonView/Shared/AqMaxMinNumberInput';
import { SortingColumnsByQueueType } from '@commonServices/models/SortingColumns';
import { WorkQueueFiltersMap } from '@commonServices/models/FilterGroup';
import AqDynamicFilters from '@commonWidgets/Filters/AqDynamicFilters';
import ExportModal from '@commonView/ReportsPage/ExportModal';

const actions = {
	saveAs: { name: 'Save as', value: 'saveAs', iconClass: 'fa-save text-primary' },
	rename: { name: 'Rename', value: 'rename', iconClass: 'fa-edit text-primary' },
	delete: { name: 'Delete', value: 'delete', iconClass: 'fa-times text-primary' },
	export: { name: 'Export', value: 'export', iconClass: 'fa-file-export text-primary' },
};

const reservedQueueNames = [EverytingElseQueueName.toLowerCase(), UnfilteredTasksQueueName.toLowerCase()];

export default {
	name: 'TasksPageFilters',
	components: {
		SaveFiltersModal,
		Sorting,
		AutoAssignLogo,
		AqMaxMinNumberInput,
		AqDynamicFilters,
		ExportModal,
	},
	props: {
		pot: {
			type: Number,
			required: true,
		},
		queueId: {
			type: Number,
			required: false,
			default: 0,
		},
		queueName: {
			type: String,
			required: false,
			default: null,
		},
		filter: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		filters: {
			type: Array,
			required: false,
			default: () => ([]),
		},
		hideFilters: {
			type: Boolean,
			required: false,
			default: true,
		},
		sorting: {
			type: Array,
			required: true,
		},
		prioritizedQueue: {
			type: Boolean,
			required: true,
		},
		minOptIn: {
			type: Number,
			required: false,
			default: 0,
		},
		disabledExportMessage: {
			type: String,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			isFiltersOpen: false,
			filterDictionaries: {},
			isQueueNameUniq: true,
			isRenameQueue: false,
		};
	},
	reservedQueueNames,
	async mounted () {
		this.filterDictionaries = await WorkflowService.getFiltersLookupData(this.pot);
	},
	computed: {
		saveOptions () {
			const saveOptions = [];
			if (this.queueId) {
				saveOptions.push(actions.saveAs);
				saveOptions.push(actions.rename);

				if (this.$can.DeleteQueue) {
					saveOptions.push(actions.delete);
				}
			}
			if (this.$can.ExportQueue) {
				saveOptions.push({
					...actions.export,
					disabled: this.disabledExportMessage != null,
					tooltip: this.disabledExportMessage,
				});
			}
			return saveOptions;
		},
		saveQueueDescription () {
			return this.isRenameQueue ? 'Please edit the Queue name' : 'Please enter a name for your Queue';
		},
		saveQueueValue () {
			return this.isRenameQueue ? this.queueName : null;
		},
		availableFilters () {
			return Object.entries(WorkQueueFiltersMap[this.pot]).map(([key, value]) => {
				return {
					key,
					description: value,
				};
			});
		},
		sortingColumns () {
			return SortingColumnsByQueueType[this.pot];
		},
	},
	methods: {
		onFiltersOpen () {
			this.isFiltersOpen = true;
		},
		onCancel () {
			this.isFiltersOpen = false;
		},
		onRun () {
			this.isFiltersOpen = false;
			this.$emit('apply-filters');
		},
		async onToggleSave () {
			if (this.queueId) {
				const updateQueueModel = this.getFilterSetModel();
				await WorkflowService.updateQueue(this.queueId, updateQueueModel);
			} else {
				this.isRenameQueue = false;
				this.$modal.show('save-queue-modal');
			}
		},
		async onSelectAction (action) {
			switch (action) {
			case actions.saveAs.value:
				this.isRenameQueue = false;
				this.$modal.show('save-queue-modal');
				break;
			case actions.rename.value:
				this.isRenameQueue = true;
				this.$modal.show('save-queue-modal');
				break;
			case actions.delete.value: {
				const userChoise = await this.$refs.deleteQueueModal.show();
				if (userChoise === 'yes') {
					await WorkflowService.deleteQueue(this.queueId);
					this.$router.replace({ name: 'tasks', params: { pot: this.pot } });
				}
				break;
			}
			case actions.export.value:
				this.$modal.show('export-queue-modal');
				break;
			}
		},
		async onSaveQueue (queueName) {
			try {
				if (this.isRenameQueue) {
					await WorkflowService.updateQueueDetails(this.queueId, queueName, [ErrorCode.DuplicationErrorCode]);
					eventBus.$emit('task_queue-renamed', { queueId: this.queueId, queueName });
				} else {
					const saveQueueModel = this.getFilterSetModel(queueName);
					const { value: queueId } = await WorkflowService.saveQueue(this.pot, saveQueueModel, [ErrorCode.DuplicationErrorCode]);
					this.$router.push({ name: 'tasks', params: { pot: this.pot, queueId } });
				}

				this.$modal.hide('save-queue-modal');
			} catch (error) {
				if (isKnownError(error, ErrorCode.DuplicationErrorCode)) {
					this.isQueueNameUniq = false;
				}
			}
		},
		getFilterSetModel (queueName) {
			const saveQueueModel = {
				isPrioritized: this.prioritizedQueue,
				...queueName && { name: queueName },
				filters: this.filters,
				sorting: toApiSorting(this.sorting),
				minOptIn: this.minOptIn,
			};
			return saveQueueModel;
		},
		onSearch (searchValue) {
			this.filter.claim.value = searchValue ? [{ id: searchValue, description: searchValue }] : null;
			this.$emit('apply-filters');
		},
		onExport (value) {
			this.$emit('export-queue', value);
			this.$modal.hide('export-queue-modal');
		},
	},
};
</script>

<style lang="scss" scoped>
  .filters-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 4;
  }

  .section-header {
    background: var(--headerItemBg);
  }

  ::v-deep .search-block .search-input {
    height: 37px;
    width: 160px;
    background-color: var(--searchBg);
    border: 2px solid $primary !important;
  }

  ::v-deep .btn {
    border-radius: 0;
  }

  .border-right {
    border-width: 2px !important;
    border-color: var(--searchBg) !important;
  }

  .icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: $primary;
    font-size: 26px;
  }

  .assign-user-logo {
    fill: var(--cardIconColour);
  }

  .opacity-50 {
    opacity: 0.5;
  }

  .lock {
    color: $warning;
  }

  ::v-deep .min-opt-in-input:disabled {
    background-color: var(--cardIconSecondaryColour);
  }

  ::v-deep .min-opt-in-input {
    color: var(--headerBg);
    background-color: var(--sectionTextColour);
    width: 24px;
    font-size: 12px;
    text-align: center;
    border: none;
  }

  .aqv-user-simple.disabled {
    color: var(--cardIconSecondaryColour);
  }

  .tooltip {
    max-width: 150px;

    &-disabled {
      &-inner {
        background-color: var(--bannerBgInner);
      }

      &-arrow {
        border-color: var(--bannerBgInner);
      }
    }
  }
</style>
