// Vue general components
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// Additional services
import { longDate, shortDate, shortDateNoCentury, currency, pad, shortDateTime, shortDateTimeNoCentury, capitalize, capitalizeLetter, pluralize, claimIdFormat, limitCount } from '@commonServices/utils/filters';
import directives from '@commonServices/utils/directivies';
import '@fortawesome/fontawesome-free/css/all.css';
import '@assets/fonts/style.css';

// Third party libraries

import { Table, TableColumn, Steps, Step, Carousel, CarouselItem } from 'element-ui';
import Toasted from 'vue-toasted';
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VTooltip from 'v-tooltip';
import Shortkey from 'vue-shortkey';
import Sticky from 'vue-sticky-directive';
import PortalVue from 'portal-vue';
// settings
import { initToasted, commonToastOptions } from '@commonServices/settings/toastSettings';
import { keysMapProxy } from '@commonServices/settings/shortcutsSettings';

// Views
import AqSearchResult from '@commonView/AqSearchResult';
import CustomerPage from '@commonView/CustomerPage';
import CustomerCardInfo from '@commonView/CustomerPage/CustomerCardInfo';
import PetCardInfo from '@commonView/Shared/PetCardInfo';
import ProductCardInfo from '@commonView/Shared/ProductCardInfo';
import PetCard from '@commonView/CustomerPage/PetsLayout/PetCard';
import ClaimPage from '@commonView/ClaimPage';
import ClaimEnterBasic from '@commonView/ClaimPage/ClaimEnterBasic';
import ClaimMissingItems from '@commonView/ClaimPage/ClaimMissingItems';
import NewVetModal from '@commonView/ClaimPage/Shared/NewVetModal';
import NewPayeeModal from '@commonView/ClaimPage/ClaimPayment/NewPayeeModal';
import ClaimProcess from './components/views/ClaimPage/ClaimProcess';
import EmptyStep from './components/widgets/Steps/EmptyStep';
import ClaimItemView from '@commonView/ClaimPage/ClaimProcess/ClaimItemView';
import ClaimItemForm from '@commonView/ClaimPage/ClaimProcess/ClaimItemForm';
import AqProcessTimeline from './components/views/ClaimPage/ClaimProcess/AqProcessTimeline';
import ClaimPayment from './components/views/ClaimPage/ClaimPayment';
import PaymentRecipients from './components/views/ClaimPage/ClaimPayment/PaymentRecipients';
import PaymentBreakdown from '@commonView/ClaimPage/ClaimPayment/PaymentBreakdown';
import PaymentHistory from '@commonView/ClaimPage/ClaimPayment/PaymentHistory';
import ClaimBaseBar from '@commonView/ClaimPage/Shared/ClaimNavigationBar/ClaimBaseBar';
import ClaimNavigationBar from '@commonView/ClaimPage/Shared/ClaimNavigationBar';
import ItemHeading from '@commonView/Shared/ItemHeading';
import ItemAttribute from '@commonView/Shared/ItemAttribute';
import DocumentPanel from './components/views/ClaimPage/DocumentPanel';
import DocumentEdit from './components/views/ClaimPage/DocumentPanel/DocumentEdit';
import DocumentList from './components/views/ClaimPage/DocumentPanel/DocumentList';
import ClaimFormScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan';
import ClaimBasicScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/ClaimBasicScan';
import ClaimPayeeScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/ClaimPayeeScan';
import MultiConditionSelector from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/MultiConditionSelector';
import ClaimVetScan from '@commonView/ClaimPage/DocumentPanel/ClaimFormScan/ClaimVetScan';
import InvoiceScan from '@commonView/ClaimPage/DocumentPanel/InvoiceScan';
import InvoiceItemsScan from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceItemsScan';
import InvoiceLineItem from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceLineItem';
import InvoiceScanEdit from '@commonView/ClaimPage/DocumentPanel/InvoiceScan/InvoiceScanEdit';
import TasksPage from '@commonView/TasksPage';
import TaskCounter from '@commonView/TasksPage/TaskCounter';
import RejectClaimModal from '@commonView/TasksPage/RejectClaimModal';
import TaskTable from '@commonView/TasksPage/TaskTable';
import FraudCheckModal from '@commonView/ClaimPage/Shared/FraudCheckModal';
import PayeesDataModal from '@commonView/Shared/PayeesDataModal';
import FlagsDataModal from '@commonView/Shared/FlagsDataModal';
import MissingDataModal from '@commonView/Shared/MissingDataModal';
import TaskCounterWrapper from '@commonView/TasksPage/TaskCounterWrapper';
import ConditionForm from '@commonView/Shared/ConditionsPanel/ConditionForm';
import DiagnosisCondition from '@commonView/Shared/ConditionsPanel/DiagnosisCondition';
import PreExistingConditions from '@commonView/Shared/ConditionsPanel/PreExistingConditions';
import EligibleConditions from '@commonView/Shared/ConditionsPanel/EligibleConditions';
import RelatedConditions from '@commonView/Shared/ConditionsPanel/RelatedConditions';
import ConditionComments from '@commonView/Shared/ConditionsPanel/ConditionComments';
import ReportsPage from '@commonView/ReportsPage';
import VetDetailsInfo from '@commonView/Shared/ConditionsPanel/VetDetailsInfo';

// Shared
import AqMultiProgressBar from './components/views/Shared/AqMultiProgressBar';
import HistoryPanel from '@commonView/Shared/HistoryPanel';
import ConditionsPanel from './components/views/Shared/ConditionsPanel';
import DocumentPreview from '@commonView/Shared/DocumentPreview';
import ManualRejectionModal from '@commonView/ClaimPage/Shared/ManualRejectionModal';

import AqPageControl from '@commonWidgets/AqPageControl';
import AqLevelSwitcher from '@commonView/Shared/AqLevelSwitcher';
import TruncatedTextCell from '@commonView/Shared/Table/Cells/TruncatedTextCell';
import BooleanCell from '@commonView/Shared/Table/Cells/BooleanCell';
import IconCell from '@commonView/Shared/Table/Cells/IconCell';
import PagedColumns from '@commonView/Shared/Table/PagedColumns';
import AqTooltipParagraph from '@commonWidgets/AqTooltipParagraph';
import ReassessmentOutcomeModal from '@commonView/ClaimPage/Shared/ReassessmentOutcomeModal';
import EscalateClaimModal from '@commonView/ClaimPage/Shared/EscalateClaimModal';
import RegisteredVetHeader from '@commonView/Shared/RegisteredVet/RegisteredVetHeader';
import RegisteredVetContent from '@commonView/Shared/RegisteredVet/RegisteredVetContent';
import AqComment from '@commonView/Shared/AqComment';
import MedicalRecordSummaryButton from '@commonView/Shared/MedicalRecordsSummaryButton';

// Widgets
import AqInput from '@commonWidgets/AqInput';
import AqMoneyInput from '@commonWidgets/AqMoneyInput';

import AqFormInput from '@commonWidgets/AqFormInput';
import AqFormInputError from '@commonWidgets/AqFormInputError';
import AqSearchInput from '@commonWidgets/AqSearchInput';
import AqSearchButton from '@commonWidgets/AqSearchButton';
import AqProgressBar from '@commonWidgets/AqProgressBar';
import AqSelect from '@commonWidgets/AqSelect';
import AqDropdown from '@commonWidgets/AqDropdown';
import FileUpload from '@commonWidgets/AqFileUpload';
import AqSteps from './components/widgets/Steps/AqSteps';
import AqWizard from './components/widgets/Steps/AqWizard';
import AqWizardItem from './components/widgets/Steps/AqWizardItem';
import AqToggler from '@commonWidgets/AqToggler';
import AqSlider from '@commonWidgets/AqSlider';
import AqModalContainer from '@commonWidgets/Modals/AqModalContainer';
import AqConfirmationModal from '@commonWidgets/Modals/AqConfirmationModal';
import AqAlertModal from '@commonWidgets/Modals/AqAlertModal';
import AqTimeline from './components/widgets/AqTimeline';
import AqBar from './components/widgets/AqMultiProgressBar/AqBar';
import AqListNavigationControl from '@commonWidgets/AqListNavigationControl';
import AqShortcutsModal from '@commonWidgets/Modals/AqShortcutsModal';
import AqPanelWrapper from '@commonWidgets/Modals/AqPanelWrapper';
import AqPanelItems from '@commonWidgets/AqPanelItems';
import AqShifter from '@commonWidgets/AqShifter';
import AqSearchTerm from '@commonWidgets/AqSearchTerm';
import AqCarousel from '@commonWidgets/AqCarousel';
import AqCustomerCard from '@commonWidgets/AqCustomerCard';
import AqClaimCard from '@commonWidgets/AqClaimCard';
import AqScanFormInput from '@commonWidgets/AqScanFormInput';
import AqScanFormSelect from '@commonWidgets/AqScanFormSelect';
import AqSandwich from '@commonWidgets/AqSandwich';
import AqNotifier from '@commonWidgets/AqNotifier';
import AqTabSelector from '@commonWidgets/AqTabSelector';
import AqCard from '@commonWidgets/AqCard';
import AqCheckbox from '@commonWidgets/AqCheckbox';
import AqAilments from '@commonWidgets/AqAilments';
import AqBodyParts from '@commonWidgets/AqBodyParts';
import AqExpandableCard from '@commonWidgets/AqExpandableCard';
import AqIconAvatar from '@commonWidgets/AqIconAvatar';
import AqBrandImageAvatar from '@commonWidgets/AqBrandImageAvatar';
import AqImageAvatar from '@commonWidgets/AqImageAvatar';
import AqMoreLessToggle from '@commonWidgets/AqMoreLessToggle';
import AqMoreLessToggler from '@commonWidgets/AqMoreLessToggler';
import AqTasks from '@commonWidgets/Tasks/AqTasks';
import AqDateFilter from '@commonWidgets/Filters/AqDateFilter';
import AqNumberFilter from '@commonWidgets/Filters/AqNumberFilter';
import AqBooleanFilter from '@commonWidgets/Filters/AqBooleanFilter';
import AqSelectFilter from '@commonWidgets/Filters/AqSelectFilter';
import AqPetAvatar from '@commonWidgets/AqPetAvatar';
import AqEditPetDateOfDeath from '@commonWidgets/AqEditPetDateOfDeath';
import AqTooltipOnTruncate from '@commonWidgets/AqTooltipOnTruncate';
import AqFraudCheckActions from '@commonWidgets/AqFraudCheckActions';
import AqModal from '@commonWidgets/AqModal';
import AqMultiCheckBox from '@commonWidgets/AqMultiCheckBox';
import AqHeaderToggler from '@commonWidgets/AqHeaderToggler';
import AqCareFlag from '@commonWidgets/AqCareFlag';
import AqSearchBox from '@commonWidgets/AqSearchBox';
import AqToolTipStatic from '@commonWidgets/AqToolTipStatic';
import AqCalcInput from '@commonWidgets/AqCalcInput';
import AqBookmark from '@commonWidgets/AqBookmark';

// Layouts
import Header from '@commonLayout/Header';
import Navbar from '@commonLayout/Navbar';
import Footer from '@commonLayout/Footer';
import NotificationItem from '@commonLayout/Notifications/NotificationItem';
import NotificationItemInfo from '@commonLayout/Notifications/NotificationItemInfo';

// Hubs
import InteractionHub from '@commonServices/hubs/eventsHub';

import PaNavigateService from '@commonServices/plugins/policyAdminNavigateService';
import Access from '@commonServices/plugins/accessPlugin';
import { defineAccess } from '@commonServices/settings/access';

Vue.config.productionTip = false;

// Global componet registration
// Third party components
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Steps.name, Steps);
Vue.component(Step.name, Step);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Carousel.name, Carousel);

// Layouts
Vue.component('aq-header', Header);
Vue.component('aq-navbar', Navbar);
Vue.component('aq-footer', Footer);
Vue.component('notification-item', NotificationItem);
Vue.component('notification-item-info', NotificationItemInfo);

// Widgets
Vue.component('aq-input', AqInput);
Vue.component('aq-money-input', AqMoneyInput);
Vue.component('aq-form-input', AqFormInput);
Vue.component('aq-form-input-error', AqFormInputError);
Vue.component('aq-search-input', AqSearchInput);
Vue.component('aq-search-button', AqSearchButton);
Vue.component('aq-progress', AqProgressBar);
Vue.component('aq-select', AqSelect);
Vue.component('aq-dropdown', AqDropdown);
Vue.component('aq-file-upload', FileUpload);
Vue.component('aq-steps', AqSteps);
Vue.component('aq-wizard', AqWizard);
Vue.component('aq-wizard-item', AqWizardItem);
Vue.component('aq-toggler', AqToggler);
Vue.component('aq-slider', AqSlider);
Vue.component('aq-modal-container', AqModalContainer);
Vue.component('aq-confirmation-modal', AqConfirmationModal);
Vue.component('aq-alert-modal', AqAlertModal);
Vue.component('aq-time-line', AqTimeline);
Vue.component('aq-process-timeline', AqProcessTimeline);
Vue.component('aq-bar', AqBar);
Vue.component('aq-list-navigation-control', AqListNavigationControl);
Vue.component('aq-shortcuts-modal', AqShortcutsModal);
Vue.component('aq-panel-wrapper', AqPanelWrapper);
Vue.component('aq-panel-items', AqPanelItems);
Vue.component('aq-shifter', AqShifter);
Vue.component('aq-search-term', AqSearchTerm);
Vue.component('aq-carousel', AqCarousel);
Vue.component('aq-customer-card', AqCustomerCard);
Vue.component('aq-claim-card', AqClaimCard);
Vue.component('aq-scan-form-input', AqScanFormInput);
Vue.component('aq-scan-form-select', AqScanFormSelect);
Vue.component('aq-sandwich', AqSandwich);
Vue.component('aq-notifier', AqNotifier);
Vue.component('aq-tab-selector', AqTabSelector);
Vue.component('aq-card', AqCard);
Vue.component('aq-checkbox', AqCheckbox);
Vue.component('aq-ailments', AqAilments);
Vue.component('aq-body-parts', AqBodyParts);
Vue.component('aq-more-less-toggle', AqMoreLessToggle);
Vue.component('aq-more-less-toggler', AqMoreLessToggler);
Vue.component('aq-expandable-card', AqExpandableCard);
Vue.component('aq-icon-avatar', AqIconAvatar);
Vue.component('aq-brand-image-avatar', AqBrandImageAvatar);
Vue.component('aq-image-avatar', AqImageAvatar);
Vue.component('aq-tasks', AqTasks);
Vue.component('aq-date-filter', AqDateFilter);
Vue.component('aq-number-filter', AqNumberFilter);
Vue.component('aq-boolean-filter', AqBooleanFilter);
Vue.component('aq-select-filter', AqSelectFilter);
Vue.component('aq-pet-avatar', AqPetAvatar);
Vue.component('aq-edit-pet-date-of-death', AqEditPetDateOfDeath);
Vue.component('aq-tooltip-on-truncate', AqTooltipOnTruncate);
Vue.component('aq-fraud-check-actions', AqFraudCheckActions);
Vue.component('aq-modal', AqModal);
Vue.component('aq-multi-checkbox', AqMultiCheckBox);
Vue.component('aq-header-toggler', AqHeaderToggler);
Vue.component('aq-care-flag', AqCareFlag);
Vue.component('aq-search-box', AqSearchBox);
Vue.component('aq-tooltip-static', AqToolTipStatic);
Vue.component('aq-calc-input', AqCalcInput);
Vue.component('aq-bookmark', AqBookmark);

// Views
Vue.component('aq-search-result', AqSearchResult);
Vue.component('customer-page', CustomerPage);
Vue.component('customer-card-info', CustomerCardInfo);
Vue.component('pet-card-info', PetCardInfo);
Vue.component('product-card-info', ProductCardInfo);
Vue.component('pet-card', PetCard);
Vue.component('claim-page', ClaimPage);
Vue.component('claim-enter-basic', ClaimEnterBasic);
Vue.component('claim-missing-items', ClaimMissingItems);
Vue.component('claim-process', ClaimProcess);
Vue.component('new-vet-modal', NewVetModal);
Vue.component('new-payee-modal', NewPayeeModal);
Vue.component('empty-step', EmptyStep);
Vue.component('claim-item-view', ClaimItemView);
Vue.component('claim-item-form', ClaimItemForm);
Vue.component('claim-payment', ClaimPayment);
Vue.component('payment-recipients', PaymentRecipients);
Vue.component('payment-breakdown', PaymentBreakdown);
Vue.component('payment-history', PaymentHistory);
Vue.component('claim-base-bar', ClaimBaseBar);
Vue.component('claim-navigation-bar', ClaimNavigationBar);
Vue.component('item-heading', ItemHeading);
Vue.component('item-attribute', ItemAttribute);
Vue.component('claim-form-scan', ClaimFormScan);
Vue.component('claim-basic-scan', ClaimBasicScan);
Vue.component('claim-payee-scan', ClaimPayeeScan);
Vue.component('multi-condition-selector', MultiConditionSelector);
Vue.component('claim-vet-scan', ClaimVetScan);
Vue.component('invoice-scan', InvoiceScan);
Vue.component('invoice-items-scan', InvoiceItemsScan);
Vue.component('invoice-line-item', InvoiceLineItem);
Vue.component('invoice-scan-edit', InvoiceScanEdit);
Vue.component('tasks-page', TasksPage);
Vue.component('task-counter', TaskCounter);
Vue.component('reject-claim-modal', RejectClaimModal);
Vue.component('task-table', TaskTable);
Vue.component('fraud-check-modal', FraudCheckModal);
Vue.component('payees-data-modal', PayeesDataModal);
Vue.component('flags-data-modal', FlagsDataModal);
Vue.component('missing-data-modal', MissingDataModal);
Vue.component('task-counter-wrapper', TaskCounterWrapper);
Vue.component('condition-form', ConditionForm);
Vue.component('diagnosis-condition', DiagnosisCondition);
Vue.component('pre-existing-conditions', PreExistingConditions);
Vue.component('eligible-conditions', EligibleConditions);
Vue.component('related-conditions', RelatedConditions);
Vue.component('condition-comments', ConditionComments);
Vue.component('reports-page', ReportsPage);
Vue.component('vet-details-info', VetDetailsInfo);

// Shared
Vue.component('aq-multi-progress-bar', AqMultiProgressBar);
Vue.component('document-panel', DocumentPanel);
Vue.component('document-edit', DocumentEdit);
Vue.component('document-list', DocumentList);
Vue.component('history-panel', HistoryPanel);
Vue.component('conditions-panel', ConditionsPanel);
Vue.component('document-preview', DocumentPreview);
Vue.component('manual-rejection-modal', ManualRejectionModal);
Vue.component('aq-page-control', AqPageControl);
Vue.component('aq-level-switcher', AqLevelSwitcher);
Vue.component('truncated-text-cell', TruncatedTextCell);
Vue.component('boolean-cell', BooleanCell);
Vue.component('icon-cell', IconCell);
Vue.component('paged-columns', PagedColumns);
Vue.component('aq-tooltip-paragraph', AqTooltipParagraph);
Vue.component('reassessment-outcome-modal', ReassessmentOutcomeModal);
Vue.component('escalate-claim-modal', EscalateClaimModal);
Vue.component('registered-vet-header', RegisteredVetHeader);
Vue.component('registered-vet-content', RegisteredVetContent);
Vue.component('aq-comment', AqComment);
Vue.component('medical-record-summary-button', MedicalRecordSummaryButton);

Vue.use(InteractionHub);
Vue.use(Toasted, { ...commonToastOptions, router });
Vue.use(Toasted, { ...commonToastOptions, router });
initToasted(Vue.toasted);
Vue.use(Vuelidate);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(Shortkey, { prevent: ['input', '.aq-modal-container * ', '.aq-modal-container', 'textarea'] });
Vue.use(Sticky);
Vue.use(PortalVue);
Vue.use(PaNavigateService, router);
Vue.use(Access, defineAccess());

// Global filters registering
Vue.filter('longDate', longDate);
Vue.filter('shortDate', shortDate);
Vue.filter('shortDateNoCentury', shortDateNoCentury);
Vue.filter('shortDateTime', shortDateTime);
Vue.filter('shortDateTimeNoCentury', shortDateTimeNoCentury);
Vue.filter('currency', currency);
Vue.filter('pad', pad);
Vue.filter('claimIdFormat', claimIdFormat);
Vue.filter('capitalize', capitalize);
Vue.filter('capitalizeLetter', capitalizeLetter);
Vue.filter('pluralize', pluralize);
Vue.filter('limitCount', limitCount);

// Global directives registering
Vue.directive('click-outside', directives.clickOutside);
Vue.directive('scroll', directives.scroll);

// Applying shortcuts as global variable
Vue.prototype.$shortkeys = keysMapProxy(store);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');

VTooltip.options.defaultBoundariesElement = document.querySelector('#app');
