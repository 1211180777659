<template>
	<div class="mb-10">
		<div class="d-flex px-14 py-8">
			<div class="flex-grow-1">
				<div class="fs-14 font-weight-bold">
					{{ graph.description }}
				</div>
				<p class="fs-12 font-weight-bold">
					{{ graph.type }}<span v-if="graph.sectionLimitEndsDate">
						: {{ graph.sectionLimitEndsDate | longDate }}
					</span>
				</p>
			</div>
			<div class="flex-grow-1 text-right">
				<div class="fs-14 remaining-container">
					<div v-if="showClaimed">
						Claimed <span class="font-weight-bold">
							{{ totalNetAmount }}
						</span>
					</div>
					<div v-if="showRemaining">
						Remaining <span class="font-weight-bold">
							{{ remainingAmount }}
						</span>
					</div>
				</div>
				<p class="fs-12 font-weight-bold">
					{{ limitLabel }}<span v-if="graph.noMaximumLimit === false">: {{ sectionLimit }}</span>
					<span v-else>: Unlimited</span>
				</p>
			</div>
		</div>
		<div class="progress-bar-container">
			<aq-multi-progress-bar
				v-if="sections.length"
				ref="bar"
				class="progress-bar"
				:total="graph.noMaximumLimit ? totalNetAmount : graph.sectionLimit"
				:sections="sections"
				is-separated
				:is-label-truncated="false"
				:bar-height="12"
				@select-segment="onSelectSegment"
				style="height: 12px;"
			/>
		</div>
	</div>
</template>

<script>
import eventBus from '@commonServices/eventBus';
import { currency, pluralize } from '@commonServices/utils/filters';
import ProductType from '@commonServices/models/ProductType';
import ProductLevel from '@commonServices/models/ProductLevel';
import LimitType from '@commonServices/models/LimitType';
import RuleType from '@commonServices/models/RuleType';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { findAllIndexes } from '@commonServices/utils/general';

export default {
	name: 'SectionGraph',
	props: {
		graph: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
	},
	mounted () {
		eventBus.$on('select-pet-claim', this.onSelectPetClaimHandler);
	},
	beforeDestroy () {
		eventBus.$off('select-pet-claim', this.onSelectPetClaimHandler);
	},
	computed: {
		remainingAmount () {
			let remaining = this.graph.sectionLimit - this.totalNetAmount;
			remaining = remaining < 0 ? 0 : remaining;
			return this.graph.limitType === LimitType.NonFinancialLimit ? remaining : currency(remaining, this.locale);
		},
		sectionLimit () {
			return this.graph.limitType === LimitType.NonFinancialLimit ? `${this.graph.sectionLimit} ${pluralize(this.graph.unit, this.graph.sectionLimit)}` : currency(this.graph.sectionLimit, this.locale);
		},
		sections () {
			return this.graph.segments.map((section) => {
				return {
					value: this.graph.limitType === LimitType.NonFinancialLimit ? section.nonFinancialNumber : section.netAmount,
					class: this.segmentsColor,
					label: this.graph.limitType === LimitType.NonFinancialLimit ? `${currency(section.netAmount, this.locale)} (${section.nonFinancialNumber} ${pluralize(this.graph.unit, section.nonFinancialNumber)})` : `${currency(section.netAmount, this.locale)}`,
				};
			});
		},
		totalNetAmount () {
			if (this.graph.limitType === LimitType.NonFinancialLimit) {
				return this.graph.segments.reduce((accum, item) => accum + item.nonFinancialNumber, 0);
			}

			return this.graph.segments.reduce((accum, item) => accum + item.netAmount, 0);
		},
		segmentsColor () {
			if (this.graph.noMaximumLimit) {
				return 'bg-primary';
			}
			const newAmountPercentage = this.totalNetAmount / this.graph.sectionLimit;
			return newAmountPercentage < 0.5 ? 'bg-primary' : newAmountPercentage < 0.75 ? 'bg-warning' : 'bg-danger';
		},
		limitLabel () {
			if (this.graph.limitType === LimitType.ExcessLimit) {
				return this.uiLocaleSettings.deductibleLabel;
			}

			return `${this.graph.productType} ${(this.graph.productType === ProductType.Insurance && this.graph.productLevel === ProductLevel.Addon) ? this.graph.productLevel : ''} ${(this.graph.limitType === LimitType.SectionLimit || this.graph.limitType === LimitType.NonFinancialLimit) ? '' : this.graph.limitType} ${this.graph.level} Limit`;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.locale);
		},
		showRemaining () {
			return !(this.graph.noMaximumLimit === true || this.graph.type === RuleType.PerClaim);
		},
		showClaimed () {
			return this.graph.limitType === LimitType.NonFinancialLimit;
		},
	},
	methods: {
		onSelectPetClaimHandler (claimIds) {
			if (!this.$refs.bar) {
				return;
			}
			this.$refs.bar.clearSectionLabel();
			if (claimIds.length === 1) {
				const segmentIndexes = findAllIndexes(this.graph.segments, (segment) => segment.claimId === claimIds[0]);
				if (segmentIndexes.length === 0) {
					this.$refs.bar.clearSectionLabel();
				} else {
					segmentIndexes.forEach(segmentIndex => this.$refs.bar.setSelectionWithoutClear(segmentIndex));
				}
			} else {
				claimIds.forEach((claimId) => {
					const segmentIndexes = findAllIndexes(this.graph.segments, (segment) => segment.claimId === claimId);
					segmentIndexes.forEach(segmentIndex => this.$refs.bar.setSelectionWithoutClear(segmentIndex));
				});
			}
		},
		onSelectSegment (index) {
			const claimConditionId = this.graph.segments[index].claimConditionId;
			if (claimConditionId) {
				eventBus.$emit('select-graph-segment', claimConditionId);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.progress-bar-container {
  overflow: auto;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: var(--cardIconSecondaryColour);
}

::v-deep .bg-secondary--darken {
  background: darken($secondary, 3%);
}

.remaining-container {
  min-height: 50%;
}
</style>
