<template>
	<div
		data-qa="registeredVetHeaderCard_card_registeredVetInfo"
	>
		<aq-care-flag
			v-if="careFlags.length"
			:care-flags="careFlags"
		/>
		<item-heading
			class="gap-1"
			:heading="registeredVet.practiceName"
			heading-class="user-name mb-1 text-truncate"
			:paragraph="'Registered Vet'"
			paragraph-class="text-truncate"
		>
			<RegisteredVetAvatar
				class="registered-vet-avatar"
				v-tooltip="registeredVet.practiceName"
			/>
		</item-heading>
	</div>
</template>

<script>
import { getCareFlags } from '@clientCommon/services/models/CareFlagEnum';
import { RegisteredVetAvatar } from '@assets/icons';

export default {
	props: {
		registeredVet: {
			type: Object,
			required: true,
		},
	},
	components: {
		RegisteredVetAvatar,
	},
	computed: {
		careFlags () {
			return getCareFlags(this.registeredVet.careFlag);
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .user-name {
  font-size: 26px;
  letter-spacing: -0.06em;
  font-weight: bold;
}

.registered-vet-avatar {
  overflow: visible;

  &:focus {
    outline: none;
  }
}

.a {
  fill: var(--cardIconBg);
}

</style>
