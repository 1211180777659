<template>
	<div>
		<div class="py-15 px-15 filter-header d-flex justify-content-between">
			<aq-select
				data-qa="dynamic_filter_input"
				multiple
				:options="availableFilters"
				label="Search filter"
				track-by="key"
				option-label="description"
				class="select-filter"
				v-model="selectedFilters"
				@remove="onRemoveFilter"
				@update-toggled="onUpdateToggled"
			>
				<template #selectedOption>
					<div
						class="d-flex mt-8"
						v-if="selectedFilters.length"
					>
						<span class="font-weight-bold">
							{{ selectedFilters[selectedFilters.length - 1].description }}
						</span>
						<span
							class="ml-auto more-text"
							v-if="selectedFilters.length > 1"
						>
							& {{ selectedFilters.length - 1 }} MORE
						</span>
					</div>
				</template>
			</aq-select>
			<div class="text-right align-self-center mr-10">
				<a
					@click="clearFilters"
					class="text-underline cursor-pointer"
				>
					Clear Filters
				</a>
			</div>
		</div>
		<hr>
		<div class="filter-container">
			<img
				v-if="!selectedFilters.length"
				class="filter-img"
				alt="Filter Background Image"
				:class="{ 'opacity-03': dropdownToggled }"
				:src="require(`@/assets/images/${themeIconSettings.DynamicFilters}.svg`)"
			>
			<div class="filters">
				<div
					v-if="showFilter($options.Filter.Ailment)"
					class="filter"
				>
					<ailment-filter
						:filter="filter.selectedAilment"
						:condition-type="filter.selectedConditionType"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Brand)"
					class="filter"
				>
					<aq-select-filter
						data-qa="filter_input_brand"
						class="w-100"
						v-model="filter.selectedBrand.value"
						:options="brands"
						:label="filter.selectedBrand.label"
						:multiple="filter.selectedBrand.multiselect"
						:excluded="filter.selectedBrand.excluded"
						@toggle-mode="filter.selectedBrand.excluded = $event"
						no-result-message="No Brands found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Breed)"
					class="filter"
				>
					<breed-filter
						:filter="filter.selectedBreed"
						:pet-type="filter.selectedPetType"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.CareFlag)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_claimClosedBy"
						class="w-100"
						v-model="filter.selectedCareFlag.value"
						:options="careFlags"
						:label="filter.selectedCareFlag.label"
						:multiple="filter.selectedCareFlag.multiselect"
						:excluded="filter.selectedCareFlag.excluded"
						@toggle-mode="filter.selectedCareFlag.excluded = $event"
						no-result-message="No Care Flag found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ChaseCount)"
					class="filter"
				>
					<aq-number-filter
						type="number"
						label="Count"
						v-model="filter.numbers.chaseCount"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimAmount)"
					class="filter"
				>
					<aq-number-filter v-model="filter.numbers.claimAmount" />
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimAssignedTo)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_clamAssignedToUser"
						class="w-100"
						v-model="filter.selectedAssignedTo.value"
						:options="assignedUsers"
						:label="filter.selectedAssignedTo.label"
						:multiple="filter.selectedAssignedTo.multiselect"
						:excluded="filter.selectedAssignedTo.excluded"
						@toggle-mode="filter.selectedAssignedTo.excluded = $event"
						no-result-message="No Users found"
						:is-sorted="false"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimClosedBy)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_claimClosedBy"
						class="w-100"
						v-model="filter.selectedClosedBy.value"
						:options="users"
						:label="filter.selectedClosedBy.label"
						:multiple="filter.selectedClosedBy.multiselect"
						:excluded="filter.selectedClosedBy.excluded"
						@toggle-mode="filter.selectedClosedBy.excluded = $event"
						no-result-message="No Users found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimClosedDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.claimClosedDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimCreatedDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.claimCreatedDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimFormUploadedDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.claimFormUploadedDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimStatus)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_claimStatus"
						class="w-100"
						v-model="filter.selectedClaimStatus.value"
						:options="claimStatuses"
						:label="filter.selectedClaimStatus.label"
						:multiple="filter.selectedClaimStatus.multiselect"
						:excluded="filter.selectedClaimStatus.excluded"
						@toggle-mode="filter.selectedClaimStatus.excluded = $event"
						no-result-message="No Claim Status found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimType)"
					class="filter"
				>
					<aq-select
						data-qa="dynamicFilter_input_claimType"
						:options="claimTypes"
						:label="filter.selectedClaimType.label"
						option-label="description"
						class="w-100"
						v-model="filter.selectedClaimType.value"
						track-by="id"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ClosedPaidDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.datePaid" />
				</div>
				<div
					v-if="showFilter($options.Filter.ConditionType)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_conditionType"
						class="w-100"
						v-model="filter.selectedConditionType.value"
						:options="conditionTypes"
						:label="filter.selectedConditionType.label"
						:multiple="filter.selectedConditionType.multiselect"
						:excluded="filter.selectedConditionType.excluded"
						@toggle-mode="filter.selectedConditionType.excluded = $event"
						no-result-message="No Condition Types found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Country)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_country"
						class="w-100"
						v-model="filter.selectedCountry.value"
						:options="countries"
						:label="filter.selectedCountry.label"
						:multiple="filter.selectedCountry.multiselect"
						:excluded="filter.selectedCountry.excluded"
						@toggle-mode="filter.selectedCountry.excluded = $event"
						no-result-message="No countries found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.County)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_county"
						class="w-100"
						v-model="filter.selectedCounty.value"
						:options="counties"
						:label="filter.selectedCounty.label"
						:multiple="filter.selectedCounty.multiselect"
						:excluded="filter.selectedCounty.excluded"
						@toggle-mode="filter.selectedCounty.excluded = $event"
						:no-result-message="noCountiesMessage"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ClaimCreatedBy)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_claimCreatedBy"
						class="w-100"
						v-model="filter.selectedClaimCreatedByUser.value"
						:options="users"
						:label="filter.selectedClaimCreatedByUser.label"
						:multiple="filter.selectedClaimCreatedByUser.multiselect"
						:excluded="filter.selectedClaimCreatedByUser.excluded"
						@toggle-mode="filter.selectedClaimCreatedByUser.excluded = $event"
						no-result-message="No User found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.StatsDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.statsDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.DateOnSet)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.dateOnSet" />
				</div>
				<div
					v-if="showFilter($options.Filter.DatePaid)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.datePaid" />
				</div>
				<div
					v-if="showFilter($options.Filter.DatePolicyStart)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.datePolicyStart" />
				</div>
				<div
					v-if="showFilter($options.Filter.DayCountToTreatmentStart)"
					class="filter"
				>
					<aq-number-filter
						type="number"
						label="Count"
						v-model="filter.numbers.dayCountToTreatmentStart"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.DaysOpen)"
					class="filter"
				>
					<aq-number-filter
						type="number"
						label="Count"
						v-model="filter.numbers.daysOpen"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.DeceasedPet)"
					class="filter"
				>
					<aq-boolean-filter :filter="filter.isDead" />
				</div>
				<div
					v-if="showFilter($options.Filter.EscalationType)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_escalateTypes"
						class="w-100"
						v-model="filter.selectedEscalationType.value"
						:options="escalationTypes"
						:label="filter.selectedEscalationType.label"
						:multiple="filter.selectedEscalationType.multiselect"
						:excluded="filter.selectedEscalationType.excluded"
						@toggle-mode="filter.selectedEscalationType.excluded = $event"
						no-result-message="No Escalate Types found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ExGratiaAmount)"
					class="filter"
				>
					<aq-number-filter v-model="filter.numbers.exGratiaAmount" />
				</div>
				<div
					v-if="showFilter($options.Filter.FastTrackReviewed)"
					class="filter"
				>
					<aq-boolean-filter :filter="filter.isFastTrackReviewed" />
				</div>
				<div
					v-if="showFilter($options.Filter.FormType)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_claimFormType"
						class="w-100"
						v-model="filter.selectedClaimFormType.value"
						:options="claimFormTypes"
						:label="filter.selectedClaimFormType.label"
						:multiple="filter.selectedClaimFormType.multiselect"
						:excluded="filter.selectedClaimFormType.excluded"
						@toggle-mode="filter.selectedClaimFormType.excluded = $event"
						no-result-message="No Claim Form Types found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.PaymentRecipient)"
					class="filter"
				>
					<aq-select
						data-qa="dynamicFilter_input_paymentRecipient"
						:options="paymentRecipients"
						:label="filter.selectedPaymentRecipient.label"
						option-label="description"
						class="w-100"
						v-model="filter.selectedPaymentRecipient.value"
						track-by="id"
						no-result-message="No Payment Recipient found"
						:multiple="filter.selectedPaymentRecipient.multiselect"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.FraudCheckRequestedDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.fraudCheckRequestedDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.FraudFlags)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_fraudFlags"
						class="w-100"
						v-model="filter.selectedFraudFlag.value"
						:options="fraudFlags"
						:label="filter.selectedFraudFlag.label"
						:multiple="filter.selectedFraudFlag.multiselect"
						:excluded="filter.selectedFraudFlag.excluded"
						@toggle-mode="filter.selectedFraudFlag.excluded = $event"
						no-result-message="No Fraud Flags found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Genders)"
					class="filter"
				>
					<aq-select
						data-qa="dynamicFilter_input_petGender"
						:options="petGenders"
						:label="filter.selectedGender.label"
						option-label="description"
						class="w-100"
						v-model="filter.selectedGender.value"
						no-result-message="No Genders found"
						track-by="id"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.InceptionDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.inceptionDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.LastInteractionDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.lastInteractionDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.MissingInfoRequestedDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.missingInfoRequestedDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.MissingItem)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_missingItems"
						class="w-100"
						v-model="filter.selectedMissingItem.value"
						:options="missingItems"
						:label="filter.selectedMissingItem.label"
						:multiple="filter.selectedMissingItem.multiselect"
						:excluded="filter.selectedMissingItem.excluded"
						@toggle-mode="filter.selectedMissingItem.excluded = $event"
						no-result-message="No Missing Items found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.OverriddenBy)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_overrideRejectUsers"
						class="w-100"
						v-model="filter.selectedUser.value"
						:options="users"
						:label="filter.selectedUser.label"
						:multiple="filter.selectedUser.multiselect"
						:excluded="filter.selectedUser.excluded"
						@toggle-mode="filter.selectedUser.excluded = $event"
						no-result-message="No Users found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.PaymentAmount)"
					class="filter"
				>
					<aq-number-filter v-model="filter.numbers.paymentAmount" />
				</div>
				<div
					v-if="showFilter($options.Filter.PaymentType)"
					class="filter"
				>
					<aq-select
						data-qa="dynamicFilter_input_paymentMethod"
						:options="paymentMethods"
						track-by="id"
						:label="filter.selectedPaymentMethod.label"
						option-label="description"
						class="w-100"
						v-model="filter.selectedPaymentMethod.value"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.PetDateOfBirth)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.petDateOfBirth" />
				</div>
				<div
					v-if="showFilter($options.Filter.PolicySections)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_policySections"
						class="w-100"
						v-model="filter.selectedPolicySection.value"
						:options="policySections"
						:label="filter.selectedPolicySection.label"
						:multiple="filter.selectedPolicySection.multiselect"
						:excluded="filter.selectedPolicySection.excluded"
						@toggle-mode="filter.selectedPolicySection.excluded = $event"
						no-result-message="No Policy Sections found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.PolicyStatus)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_policyStatus"
						class="w-100"
						v-model="filter.selectedPolicyStatus.value"
						:options="policyStatuses"
						:label="filter.selectedPolicyStatus.label"
						:multiple="filter.selectedPolicyStatus.multiselect"
						:excluded="filter.selectedPolicyStatus.excluded"
						@toggle-mode="filter.selectedPolicyStatus.excluded = $event"
						no-result-message="No Policy statuses found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Product)"
					class="filter"
				>
					<product-filter
						:filter="filter.selectedProduct"
						:brand="filter.selectedBrand"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ReassessmentOutcome)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_reassessmentOutcome"
						class="w-100"
						v-model="filter.selectedReassessmentOutcome.value"
						:options="reassessmentOutcomes"
						:label="filter.selectedReassessmentOutcome.label"
						:multiple="filter.selectedReassessmentOutcome.multiselect"
						:excluded="filter.selectedReassessmentOutcome.excluded"
						@toggle-mode="filter.selectedReassessmentOutcome.excluded = $event"
						no-result-message="No Reassessment Outcomes found"
						:is-sorted="false"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ReassessmentReason)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_reassessmentReason"
						class="w-100"
						v-model="filter.selectedReassessmentReason.value"
						:options="reassessmentReasons"
						:label="filter.selectedReassessmentReason.label"
						:multiple="filter.selectedReassessmentReason.multiselect"
						:excluded="filter.selectedReassessmentReason.excluded"
						@toggle-mode="filter.selectedReassessmentReason.excluded = $event"
						no-result-message="No Reassessment Reasons found"
						:is-sorted="false"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.ReassessmentRequestedDate)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.reassessmentRequestedDate" />
				</div>
				<div
					v-if="showFilter($options.Filter.RejectionReasons)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_rejectionReason"
						class="w-100"
						v-model="filter.selectedReason.value"
						:options="rejectionReasons"
						:label="filter.selectedReason.label"
						:multiple="filter.selectedReason.multiselect"
						:excluded="filter.selectedReason.excluded"
						@toggle-mode="filter.selectedReason.excluded = $event"
						no-result-message="No Reasons found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Rejection)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_rejectionStatus"
						class="w-100"
						v-model="filter.selectedRejectionStatus.value"
						:options="claimRejectionStatuses"
						:label="filter.selectedRejectionStatus.label"
						:multiple="filter.selectedRejectionStatus.multiselect"
						:excluded="filter.selectedRejectionStatus.excluded"
						@toggle-mode="filter.selectedRejectionStatus.excluded = $event"
						no-result-message="No Rejection Status found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.RequestedBy)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_requestedBy"
						class="w-100"
						v-model="filter.selectedRequestedByUser.value"
						:options="users"
						:label="filter.selectedRequestedByUser.label"
						:multiple="filter.selectedRequestedByUser.multiselect"
						:excluded="filter.selectedRequestedByUser.excluded"
						@toggle-mode="filter.selectedRequestedByUser.excluded = $event"
						no-result-message="No Users found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Species)"
					class="filter"
				>
					<aq-select
						data-qa="dynamicFilter_input_petType"
						:options="species"
						:label="filter.selectedPetType.label"
						option-label="description"
						class="w-100"
						v-model="filter.selectedPetType.value"
						track-by="id"
						no-result-message="No Species found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.StatsTeams)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_teams"
						class="w-100"
						v-model="filter.statsTeams.value"
						:options="statsTeams"
						:label="filter.statsTeams.label"
						:multiple="filter.statsTeams.multiselect"
						:excluded="filter.statsTeams.excluded"
						@toggle-mode="filter.statsTeams.excluded = $event"
						no-result-message="No Teams found"
						:is-sorted="false"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.TreatmentStart)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.dateTreatmentStart" />
				</div>
				<div
					v-if="showFilter($options.Filter.TreatmentEnd)"
					class="filter"
				>
					<aq-date-filter v-model="filter.dates.dateTreatmentEnd" />
				</div>
				<div
					v-if="showFilter($options.Filter.SettledReason)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_settledReason"
						class="w-100"
						v-model="filter.selectedSettledReason.value"
						:options="settledReasons"
						:label="filter.selectedSettledReason.label"
						:multiple="filter.selectedSettledReason.multiselect"
						:excluded="filter.selectedSettledReason.excluded"
						@toggle-mode="filter.selectedSettledReason.excluded = $event"
						no-result-message="No Settled Reasons found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.StatsUsers)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_users"
						class="w-100"
						v-model="filter.statsUsers.value"
						:options="users"
						:label="filter.statsUsers.label"
						:multiple="filter.statsUsers.multiselect"
						:excluded="filter.statsUsers.excluded"
						@toggle-mode="filter.statsUsers.excluded = $event"
						no-result-message="No Users found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.StatsActiveUsers)"
					class="filter"
				>
					<aq-boolean-filter :filter="filter.statsUserActive" />
				</div>
				<div
					v-if="showFilter($options.Filter.UserDeductions)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_userDeductionType"
						class="w-100"
						v-model="filter.selectedUserDeductionType.value"
						:options="userDeductionTypes"
						:label="filter.selectedUserDeductionType.label"
						:multiple="filter.selectedUserDeductionType.multiselect"
						:excluded="filter.selectedUserDeductionType.excluded"
						@toggle-mode="filter.selectedUserDeductionType.excluded = $event"
						no-result-message="No User Deductions found"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.Vet)"
					class="filter"
				>
					<vet-filter :filter="filter.selectedVet" />
				</div>
				<div
					v-if="showFilter($options.Filter.Escalated)"
					class="filter"
				>
					<aq-boolean-filter
						data-qa="taskPageFilter_input_escalated"
						:filter="filter.isEcalated"
						class="w-100"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.MissingInfoCompleted)"
					class="filter"
				>
					<aq-boolean-filter
						data-qa="taskPageFilter_input_missingInfoCompleted"
						:filter="filter.missingInfoCompleted"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.IsReassessment)"
					class="filter"
				>
					<aq-boolean-filter
						data-qa="taskPageFilter_input_isReassessment"
						:filter="filter.isReassessment"
					/>
				</div>
				<div
					v-if="showFilter($options.Filter.LanguagePreference)"
					class="filter"
				>
					<aq-select-filter
						data-qa="dynamicFilter_input_languagePreferences"
						class="w-100"
						v-model="filter.selectedLanguagePreference.value"
						:options="languagePreferences"
						:label="filter.selectedLanguagePreference.label"
						:multiple="filter.selectedLanguagePreference.multiselect"
						:excluded="filter.selectedLanguagePreference.excluded"
						@toggle-mode="filter.selectedLanguagePreference.excluded = $event"
						no-result-message="No Language Preferences found"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserService from '@commonServices/usersService';
import { Filter } from '@commonServices/models/FilterType';
import AilmentFilter from '@commonView/Shared/TableFilters/AilmentFilter';
import BreedFilter from '@commonView/Shared/TableFilters/BreedFilter';
import ProductFilter from '@commonView/Shared/TableFilters/ProductFilter';
import VetFilter from '@commonView/Shared/TableFilters/VetFilter';
import { updateBreedOnPetType, updateConditionTypeOnAilmentSelection, updateProductOnBrandSelection, removeFilter } from '@commonServices/filterServices';
import { sortComparer } from '@commonServices/utils/general';
import { DefaultFiltersEnum, DefaultFiltersEnumLabel } from '@commonServices/models/DefaultFiltersEnum';
import { toOutcomeLabel } from '@commonServices/utils/reportsConverter';
import { PolicyStatusDisplay } from '@clientCommon/services/models/PolicyStatus';
import { UiLocaleSettings } from '@commonServices/settings/localeSettings';
import { pluralize } from '@commonServices/utils/filters';
import { CareFlags } from '@commonServices/models/CareFlagEnum';
import { mapState } from 'vuex';

export default {
	name: 'AqDynamicFilters',
	components: {
		AilmentFilter,
		BreedFilter,
		ProductFilter,
		VetFilter,
	},
	props: {
		filter: {
			type: Object,
			required: true,
		},
		availableFilters: {
			type: Array,
			required: true,
		},
		filterDictionaries: {
			type: Object,
			required: true,
		},
	},
	Filter,
	data () {
		return {
			searchTerms: [],
			selectedFilters: [],
			paymentMethods: [],
			species: [],
			conditionTypes: [],
			claimStatuses: [],
			claimFormTypes: [],
			paymentRecipients: [],
			users: [],
			counties: [],
			countries: [],
			noCountiesMessage: `No ${pluralize(UiLocaleSettings.regionCountyLabel, 2)} found`,
			careFlags: [],
			claimTypes: [],
			policySections: [],
			brands: [],
			dropdownToggled: false,
		};
	},
	async mounted () {
		await this.initialize();
		this.populateSavedFilters();
	},
	computed: {
		...mapState(['themeIconSettings']),
		assignedUsers () {
			const unassignedLabel = DefaultFiltersEnumLabel[DefaultFiltersEnum.Unassigned];
			const unassignedUser = {
				id: unassignedLabel,
				description: unassignedLabel,
			};
			const sortedUsers = this.users.filter(user => !user.system && user.enabled).sort(sortComparer('description'));
			return [unassignedUser, ...sortedUsers];
		},
		statsTeams () {
			const noTeam = {
				id: 0,
				name: 'No Team',
				description: 'No Team',
			};
			return [noTeam, ...this.teams];
		},
	},
	methods: {
		async initialize () {
			if (this.filterDictionaries) {
				this.paymentMethods = this.filterDictionaries.PaymentType || [];
				this.species = this.filterDictionaries.PetType || [];
				this.conditionTypes = this.filterDictionaries.ConditionType || [];
				this.claimStatuses = this.filterDictionaries.ClaimStatus || [];
				this.claimFormTypes = this.filterDictionaries.ClaimFormType || [];
				this.paymentRecipients = this.filterDictionaries.PaymentRecipient || [];
				this.teams = (this.filterDictionaries.StatsTeams || []).map(team => ({ id: team.id, description: team.name }));
				this.rejectionReasons = this.filterDictionaries.RejectionReason || [];
				this.userDeductionTypes = this.filterDictionaries.UserDeductionType || [];
				this.petGenders = this.filterDictionaries.PetGender || [];
				this.policyStatuses = (this.filterDictionaries.PolicyStatus || []).map(ps => ({ ...ps, description: PolicyStatusDisplay[ps.description] }));
				this.reassessmentReasons = this.filterDictionaries.ReassessmentReason || [];
				this.reassessmentOutcomes = (this.filterDictionaries.ReassessmentOutcome || []).map((reassessmentOutcome) => toOutcomeLabel(reassessmentOutcome));
				this.careFlags = [...CareFlags];
				this.claimTypes = this.filterDictionaries.ClaimType || [];
				this.policySections = this.filterDictionaries.PolicySection || [];
				this.fraudFlags = this.filterDictionaries.FraudFlag || [];
				this.missingItems = this.filterDictionaries.MissingItem || [];
				this.settledReasons = this.filterDictionaries.SettledReason || [];
				this.claimRejectionStatuses = this.filterDictionaries.RejectionStatus || [];
				this.isEscalated = this.filterDictionaries.IsEscalated || [];
				this.escalationTypes = this.filterDictionaries.EscalationType || [];
				this.languagePreferences = this.filterDictionaries.LanguagePreference || [];
				this.brands = this.filterDictionaries.Brand || [];
				this.counties = this.filterDictionaries.County || [];
				this.countries = this.filterDictionaries.Country || [];
			}
			this.users = await UserService.getUsers();
		},
		populateSavedFilters () {
			for (const filter of this.getSavedFilters(this.filter)) {
				this.selectedFilters = [...this.selectedFilters, { ...filter }];
			}
			for (const dateFilter of this.getSavedFilters(this.filter.dates, true)) {
				this.selectedFilters = [...this.selectedFilters, { ...dateFilter }];
			}
			for (const numberFilter of this.getSavedFilters(this.filter.numbers, true)) {
				this.selectedFilters = [...this.selectedFilters, { ...numberFilter }];
			}
			this.selectedFilters = [...this.selectedFilters].sort(sortComparer('description'));
		},
		getSavedFilters (filter, byType = false) {
			if (filter == null) return [];
			const availableFilters = this.availableFilters.map(a => a.description);
			let values = Object.values(filter).filter(value => availableFilters.includes(value.label));
			if (byType) {
				values = values.filter(f => !!f.type).map(f => f.label);
			} else {
				values = values.filter(f => !!f.value).map(f => f.label);
			}
			return this.availableFilters.filter(filter => values.includes(filter.description));
		},
		clearFilters () {
			this.selectedFilters.forEach(filter => {
				this.onRemoveFilter(filter);
			});
			this.selectedFilters = [];
		},
		showFilter (filter) {
			return !!this.selectedFilters.find(f => f.description === filter);
		},
		onRemoveFilter (filter) {
			removeFilter(this.filter, filter.description);
		},
		onUpdateToggled (toggled) {
			this.dropdownToggled = toggled;
		},
	},
	watch: {
		'filter.selectedAilment.value' (value) {
			updateConditionTypeOnAilmentSelection(this.filter.selectedConditionType.value, value, conditionTypes => { this.filter.selectedConditionType.value = conditionTypes; });
		},
		'filter.selectedBrand.value' () {
			if (this.filter.selectedProduct) {
				this.filter.selectedProduct.value = updateProductOnBrandSelection(this.filter.selectedProduct.value, this.filter.selectedBrand.value);
			}
		},
		'filter.selectedPetType.value' (value) {
			this.filter.selectedBreed.value = updateBreedOnPetType(this.filter.selectedBreed.value, value);
		},
	},
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-wrap: wrap;

  .filter {
    width: calc(100% / 5);
    padding: 10px 15px;
  }
}

.text-underline {
  text-decoration: underline;
  color: var(--btnLink);

  &:hover {
    color: $primary;
  }
}

.select-filter {
  width: 300px;
}

hr {
  border-style: ridge;
}

.more-text {
  color: $light;
}

.filter-header {
  height: 100px;
}

.filter-container {
  min-height: 350px;
  position: relative;
}

.filter-img {
  position: absolute;
  bottom: -5px;
  margin: auto;
  left: 0;
  right: 0;
}

</style>
