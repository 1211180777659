<template>
	<div class="container-fluid pt-24">
		<div class="row no-gutters">
			<div class="col-4 pr-10">
				<aq-form-input
					label="Date"
					type="date"
					v-model="scannedDataItem.date"
					:is-valid="!$v.scannedDataItem.date.$error"
					@input="$v.scannedDataItem.date.$touch"
					@focused="$emit('focused', 'date')"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="date"
				/>
			</div>
			<div class="col-4 px-10">
				<aq-form-input
					label="Quantity"
					v-model="scannedDataItem.quantity"
					type="number"
					@focused="$emit('focused', 'quantity')"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="quantity"
				/>
			</div>
			<div class="col-4 pl-10">
				<aq-form-input
					label="Pet Name"
					v-model="scannedDataItem.petName"
					type="text"
					@focused="$emit('focused', 'petName')"
				/>
			</div>
			<div class="col-8 my-20 pr-10">
				<aq-form-input
					label="Description"
					v-model="scannedDataItem.description"
					type="text"
					@focused="$emit('focused', 'description')"
				/>
			</div>
			<div class="col-4 my-20 pl-10">
				<aq-select
					label="Line Type"
					:options="LineTypeOptions"
					:value="selectedLineType"
					option-label="value"
					:is-sorted="false"
					:allow-empty="false"
					:searchable="false"
					data-qa="invoiceScanEdit_select_lineType"
					@input="onLineTypeSelected"
					@update-toggled="$emit('focused', 'itemType')"
				/>
			</div>
			<div
				class="col-12 mb-20"
				v-if="allowAilmentEdit"
			>
				<aq-select
					:options="conditions"
					label="Ailment"
					option-label="description"
					track-by="id"
					searchable
					:is-sorted="false"
					v-model="$v.scannedDataItem.selectedClaimCondition.$model"
					no-result-message="No Ailment found"
					:is-valid="!$v.scannedDataItem.selectedClaimCondition.$error"
					@input="onConditionChanged"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="selectedClaimCondition"
				/>
			</div>
			<!-- <div class="col-6 mb-20" /> -->
			<div
				v-if="isNonFinancialLimitPolicySection(scannedDataItem.selectedPolicySection)"
				class="col-12 mb-20"
			>
				<aq-form-input
					:label="nonFinancialNumberLabel"
					icon-class="text-primary fa-lg"
					v-model="scannedDataItem.nonFinancialNumber"
					type="number"
					:is-valid="!$v.scannedDataItem.nonFinancialNumber.$error"
					:locale="locale"
					@input="$v.scannedDataItem.nonFinancialNumber.$touch"
					@focused="$emit('focused', 'nonFinancialNumber')"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="nonFinancialNumber"
				/>
			</div>
			<div class="col-6 pr-10">
				<aq-form-input
					label="Amount"
					icon-class="text-primary fa-lg"
					v-model="scannedDataItem.amount"
					type="money"
					:is-valid="!$v.scannedDataItem.amount.$error"
					:locale="locale"
					@input="$v.scannedDataItem.amount.$touch"
					@focused="$emit('focused', 'amount')"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="amount"
				/>
			</div>
			<div class="col-6 pl-10">
				<aq-form-input
					label="Tax"
					icon-class="text-primary fa-lg"
					v-model="scannedDataItem.tax"
					type="money"
					:is-valid="!$v.scannedDataItem.tax.$error"
					:locale="locale"
					@input="$v.scannedDataItem.tax.$touch"
					@focused="$emit('focused', 'tax')"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="tax"
				/>
			</div>
			<div class="col-12 my-20">
				<aq-select
					:options="conditionPolicySections"
					label="Policy Section"
					option-label="description"
					track-by="id"
					searchable
					v-model="scannedDataItem.selectedPolicySection"
					no-result-message="No Policy Section found"
					:is-valid="!$v.scannedDataItem.selectedPolicySection.$error"
					@input="$v.scannedDataItem.selectedPolicySection.$touch"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="selectedPolicySection"
				/>
			</div>
			<div class="col-12">
				<aq-select
					:options="conditionDeductionTypes"
					label="User Deduction"
					option-label="description"
					track-by="id"
					multiple
					v-model="scannedDataItem.selectedUserDeductions"
					no-result-message="No User Deduction found"
				/>
			</div>
			<div class="col-6 pr-10 mt-20">
				<aq-form-input
					label="Discount Amount"
					icon-class="text-primary fa-lg"
					v-model="scannedDataItem.discountAmount"
					type="money"
					:locale="locale"
					@focused="$emit('focused', 'discountAmount')"
				/>
			</div>
			<div class="col-6 pl-10 mt-20">
				<aq-form-input
					label="Discount Percent"
					v-model="scannedDataItem.discountPercent"
					type="number"
					@focused="$emit('focused', 'discountPercent')"
				/>
				<aq-form-input-error
					class="error mt-4"
					:error-messages="$options.errorMessages"
					:validator="$v.scannedDataItem"
					property="discountPercent"
				/>
			</div>
		</div>
		<div class="d-flex justify-content-end mt-30">
			<button
				class="btn btn-secondary mr-10"
				type="button"
				@click="onCancel"
			>
				Cancel
			</button>
			<button
				class="btn btn-primary"
				type="button"
				:disabled="isItemInvalid"
				@click="onFinish"
			>
				Finish
			</button>
		</div>
	</div>
</template>

<script>

import { required, minValue, between } from 'vuelidate/lib/validators';
import { moreThen } from '@commonServices/utils/validators';
import { fromStringToDate } from '@commonServices/utils/dateUtils';
import { ValidationMessages } from '@commonServices/utils/constants';
import { capitalize, pluralize } from '@commonServices/utils/filters';
import { LineTypeOptions } from '@commonServices/models/InvoiceLineType';

const errorMessages = {
	date: {
		required: ValidationMessages.required,
	},
	amount: {
		required: ValidationMessages.required,
	},
	selectedPolicySection: {
		required: ValidationMessages.required,
	},
	quantity: {
		minValue: ValidationMessages.noNegative,
	},
	selectedClaimCondition: {
		required: ValidationMessages.required,
	},
	nonFinancialNumber: {
		required: ValidationMessages.required,
		minValue: ValidationMessages.noNegative,
	},
	discountPercent: {
		between: ValidationMessages.between(0, 100),
	},
	tax: {
		minValue: ValidationMessages.noNegative,
	},
};

export default {
	props: {
		invoiceScanData: {
			type: Object,
			required: true,
		},
		deductions: {
			type: Array,
			required: true,
		},
		policySections: {
			type: Array,
			required: true,
		},
		conditions: {
			type: Array,
			required: true,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		multicondition: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			scannedDataItem: {
				date: undefined,
				quantity: undefined,
				description: undefined,
				amount: undefined,
				selectedUserDeductions: [],
				selectedPolicySection: undefined,
				selectedClaimCondition: undefined,
				nonFinancialNumber: undefined,
				discountAmount: undefined,
				discountPercent: undefined,
				tax: undefined,
				itemType: undefined,
				petName: undefined,
			},
			allowAilmentEdit: false,
			LineTypeOptions,
		};
	},
	errorMessages,
	validations () {
		const moreThanZero = this.isNonFinancialLimitPolicySection(this.scannedDataItem.selectedPolicySection) ? moreThen(0) : minValue(0);
		const requiredNonFinancialNumber = this.isNonFinancialLimitPolicySection(this.scannedDataItem.selectedPolicySection) ? required : () => true;

		return {
			scannedDataItem: {
				date: {
					required,
				},
				amount: {
					required,
				},
				selectedPolicySection: {
					required,
				},
				selectedClaimCondition: {
					required,
				},
				quantity: {
					minValue: minValue(0),
				},
				nonFinancialNumber: {
					required: requiredNonFinancialNumber,
					minValue: moreThanZero,
				},
				discountPercent: {
					between: between(0, 100),
				},
				tax: {
					minValue: minValue(0),
				},
			},
		};
	},
	computed: {
		isItemInvalid () {
			return (
				this.$v.scannedDataItem.date.$error
				|| this.$v.scannedDataItem.amount.$error
				|| this.$v.scannedDataItem.selectedPolicySection.$error
				|| this.$v.scannedDataItem.selectedClaimCondition.$error
				|| this.$v.scannedDataItem.quantity.$error
				|| this.$v.scannedDataItem.nonFinancialNumber.$error
				|| this.$v.scannedDataItem.discountPercent.$error
				|| this.$v.scannedDataItem.tax.$error
			);
		},
		nonFinancialNumberLabel () {
			const { selectedPolicySection } = this.scannedDataItem;
			return selectedPolicySection && selectedPolicySection.hasNonFinancialLimit ? `Number of ${capitalize(pluralize(selectedPolicySection.nonFinancialLimitUnit, 10))}` : '';
		},
		conditionPolicySections () {
			return this.policySections.filter(ps => ps.claimConditionId === this.scannedDataItem.selectedClaimCondition?.id);
		},
		conditionDeductionTypes () {
			return this.deductions.filter(d => d.claimConditionId === this.scannedDataItem.selectedClaimCondition?.id && !d.isSystem);
		},
		selectedLineType () {
			return this.LineTypeOptions.find(option => option.key === this.scannedDataItem.itemType);
		},
	},
	mounted () {
		this.scannedDataItem.date = fromStringToDate(this.invoiceScanData.date.value);
		this.scannedDataItem.quantity = this.invoiceScanData.quantity.value;
		this.scannedDataItem.description = this.invoiceScanData.description.value;
		this.scannedDataItem.amount = this.toAmount(this.invoiceScanData.amount.value);
		this.scannedDataItem.selectedClaimCondition = this.invoiceScanData.claimCondition.value;
		this.scannedDataItem.selectedPolicySection = this.conditionPolicySections.find(ps => ps.id === this.invoiceScanData.policySection.value?.id);
		this.scannedDataItem.nonFinancialNumber = parseInt(this.invoiceScanData.nonFinancialNumber.value) || undefined;
		this.scannedDataItem.discountAmount = this.invoiceScanData.discountAmount.value;
		this.scannedDataItem.discountPercent = this.invoiceScanData.discountPercent.value;
		this.scannedDataItem.tax = this.invoiceScanData.tax.value;
		this.scannedDataItem.itemType = this.invoiceScanData.itemType.value;
		this.scannedDataItem.petName = this.invoiceScanData.petName.value;
		this.allowAilmentEdit = this.multicondition || this.invoiceScanData.claimCondition.value == null;
		this.scannedDataItem.selectedUserDeductions = this.invoiceScanData.deductions?.map((deduction) => {
			const deductionData = this.deductions.find(d => d.id === deduction.value?.id);
			return {
				id: deductionData.id,
				description: deductionData.description,
			};
		}) ?? [];
	},
	methods: {
		onCancel () {
			this.$emit('cancel');
		},
		onFinish () {
			this.$v.scannedDataItem.$touch();
			if (!this.isItemInvalid) {
				this.$emit('finish', this.scannedDataItem);
			}
		},
		toAmount (value) {
			const parsed = parseFloat(value);
			return isNaN(parsed) ? value : parsed;
		},
		isNonFinancialLimitPolicySection (policySection) {
			return policySection && policySection.hasNonFinancialLimit;
		},
		onConditionChanged () {
			this.scannedDataItem.selectedPolicySection = null;
		},
		onLineTypeSelected (itemType) {
			this.scannedDataItem.itemType = itemType.key;
		},
	},
};
</script>
