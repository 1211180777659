<template>
	<v-popover
		trigger="click"
		placement="bottom"
		popover-class="user-info-popover"
		@hide="onCloseUserInfo"
	>
		<div
			class="d-flex align-items-center v-center"
			@click="onUserOptionsOpen"
		>
			<div class="icon-container">
				<i
					class="aqv-user user-icon"
					v-tooltip.left="{
						content: userInfoTooltip,
						html: true,
					}"
				/>
				<i :class="[userOptIn ? 'opt-indicator opt-in' : 'opt-indicator opt-out']" />
			</div>
		</div>
		<template slot="popover">
			<template v-for="({ value, disabled, name, iconClass, description }, index) in userOptions">
				<div
					:key="index"
					@click="optionHandler(value)"
					v-close-popover
					class="dropdown-item"
					:data-qa="`aqDropdown_button_${value}`"
				>
					<div class="flex-grow-1 text-right">
						{{ name }}
						<div
							class="fs-12 ml-auto mr-0"
							v-if="disabled"
						>
							{{ description }}
						</div>
					</div>
					<div
						class="pl-20 text-right"
						v-if="iconClass"
					>
						<i
							class="fas"
							:class="[{ 'disabled': disabled }, iconClass]"
						/>
					</div>
				</div>
			</template>
			<div
				class="dropdown-item dropdown-item__dark"
				:class="{ 'active': showQueuesSection }"
				@click="onShowQueuesSection"
			>
				<div class="text-right flex-grow-1">
					Display Opted in Users
				</div>
				<div
					class="arrow"
					v-if="showQueuesSection"
				/>
				<div class="toggle-queues-icon ml-10 mr-0">
					<i
						class="fas fa-times text-warning"
						v-if="showQueuesSection"
					/>
					<i
						v-else
						class="text-primary aqv-info fs-20"
					/>
				</div>
			</div>
			<div v-if="showQueuesSection">
				<div
					v-for="(queue, index) in queuesStatistics"
					:key="index"
				>
					<aq-sandwich
						:header="queue.queueName"
						class="d-flex flex-column"
						header-class="queue-header"
						active-container-class="h-100"
						content-class="flex-grow-1 d-flex flex-column"
					>
						<template #content>
							<aq-queue-users-statistics
								:queue="queue"
								@opt-out-user="onOptOutUser"
							/>
						</template>
					</aq-sandwich>
				</div>
			</div>
		</template>
	</v-popover>
</template>

<script>
import AqQueueUsersStatistics from '@commonWidgets/AqQueueUsersStatistics';
import SecurityManager from '@commonServices/securityService';
import UsersService from '@commonServices/usersService';
import WorkflowService from '@clientCommon/services/workflowService';
import { mapActions, mapGetters } from 'vuex';

const logout = { name: 'Log Off', value: 'logout', iconClass: 'fas fa-sign-out-alt' };
const optIn = { name: 'Auto Assign Opt In', value: 'optIn', iconClass: 'aqv-user-opt-in' };
const optOut = { name: 'Auto Assign Opt Out', value: 'optOut', iconClass: 'aqv-user-opt-out', description: '(Not Available)' };

export default {
	name: 'AqUserInfoBasic',
	components: {
		AqQueueUsersStatistics,
	},
	props: {
		userInfo: {
			type: Object,
			required: true,
		},
	},
	data () {
		return {
			canOptOut: true,
			showQueuesSection: false,
			queuesStatistics: null,
		};
	},
	computed: {
		...mapGetters(['userOptIn']),
		userInfoTooltip () {
			return `${this.userInfo.description} <br/> ${this.userInfo.roles.join(', ')}`;
		},
		userOptions () {
			if (this.userOptIn) {
				const checkedOptOut = { ...optOut };
				if (!this.canOptOut) {
					checkedOptOut.disabled = true;
				}
				return [logout, checkedOptOut];
			}
			return [logout, optIn];
		},
	},
	methods: {
		...mapActions(['setUserOptIn']),
		async optionHandler (action) {
			switch (action) {
			case 'logout':
				await SecurityManager.logout();
				break;
			case 'optIn':
			case 'optOut':
				if (this.canOptOut || !this.userOptIn) {
					this.setUserOptIn(!this.userOptIn);
				}
				break;
			}
		},
		async onUserOptionsOpen () {
			this.canOptOut = await UsersService.checkAbilityToOptOut();
		},
		onShowQueuesSection () {
			if (this.showQueuesSection) {
				this.showQueuesSection = false;
				return;
			}
			WorkflowService.getQueuesUsersStatistics().then(statistics => {
				this.queuesStatistics = statistics;
				if (statistics.length) this.showQueuesSection = true;
			});
		},
		onCloseUserInfo () {
			this.showQueuesSection = false;
		},
		async onOptOutUser (user) {
			if (!user.optInStatus || this.userInfo.id === user.id || !this.$can.ManageOthersState) {
				return;
			}

			await UsersService.optOutUser(user.id);
			this.queuesStatistics = this.queuesStatistics.map(queue => {
				const userToUpdate = queue.users.find(u => u.id === user.id);
				if (userToUpdate) {
					userToUpdate.optInStatus = false;
				}
				return { ...queue, users: [...queue.users] };
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.v-center {
  height: 73px;

  @media (width <= 1279px) {
    span {
      display: none;
    }
  }

  padding: 0 28px;
}

.user-icon {
  color: var(--userIcon);
  font-size: 24px;
}

.icon-container {
  width: 20px;
  position: relative;
}

.opt-indicator {
  height: 9px;
  width: 9px;
  border-radius: 100%;
  position: absolute;
  right: -2px;
  bottom: 2px;

  &.opt-in {
    background: var(--optInIndicatorColour);
  }

  &.opt-out {
    background: var(--optOutIndicatorColour);
  }
}
</style>

<style lang="scss">
.user-info-popover {
  min-width: 250px;

  &.popover {
    margin-top: 0;

    .popover-arrow {
      display: none;
    }

    .tooltip-inner {
      width: 100%;
      color: var(--bodyColour);
      background: var(--backgroundThree);
      border: none;
      padding: 0;
      font-size: 14px;
      border-radius: 0;

      .dropdown-item {
        padding: 10px 20px;
        font-weight: 500;

        &__dark {
          background: var(--logoBg);
          color: var(--sectionTextColour);
          border: 2px solid transparent;

          &:hover {
            background: var(--logoBg);
          }
        }

        &.active {
          border: 2px solid $warning;
          position: relative;

          .arrow {
            border: solid $warning;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            position: absolute;
            transform: rotate(45deg);
            right: 50%;
            bottom: -5px;
            z-index: 100;
            background: var(--logoBg);
          }
        }

        &:first-child {
          border-bottom: 2px solid var(--sectionTextColour);
        }
      }

      .section-header {
        padding: 0;
        min-height: 0;

        .queue-header {
          font-size: 14px;
          font-weight: 500;
          padding: 7px 20px;
          text-align: center;
        }

        .caret {
          font-size: 10px;
        }
      }
    }
  }

  .toggle-queues-icon {
    width: 20px;
  }
}
</style>
