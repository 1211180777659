<template>
	<transition name="scale-up">
		<modal
			:name="name"
			width="260px"
			height="164px"
			:shift-y="shiftY"
			class="clear"
			classes="task-modal"
			:draggable="true"
			:click-to-close="false"
		>
			<img
				class="background"
				alt="Task Notification Background"
				:src="require(`@/assets/icons/${themeIconSettings.TaskNotification}.svg`)"
			>
			<div class="bell">
				<TaskNotificationBellBackground class="background" />
				<TaskNotificationBellRing class="ring" />
				<i class="fas fs-30 fa-bell icon" />
			</div>
			<div class="modal-container">
				<div
					v-if="$options.claimSourceLabelMap[source]"
					class="title pl-20"
				>
					<div class="d-flex flex-column flex-grow-1 text-center">
						<div>Source:</div>
						<div><b>{{ $options.claimSourceLabelMap[source] }}</b></div>
					</div>
					<div class="pipe fs-20">
						|
					</div>
					<div class="d-flex flex-column flex-grow-1 text-center">
						<div>Payee:</div>
						<div><b>{{ payeeType }}</b></div>
					</div>
				</div>
				<div
					v-else
					class="title pl-5"
				>
					Payee: <b>{{ payeeType }}</b>
				</div>
				<div class="body">
					<div class="timer">
						<img
							alt="Timer Icon"
							:src="require(`@/assets/icons/${themeIconSettings.Timer}.svg`)"
						>
						<label>{{ timer }}</label>
					</div>
					<div class="fs-13">
						Claim -
						<b
							@click="onOpenClaim"
							class="cursor-pointer"
						>
							<u>{{ id }}</u>
						</b>
					</div>
					<button
						@click="close"
						class="mt-6 btn btn-acknowledge w-100"
					>
						Acknowledge
					</button>
				</div>
			</div>
		</modal>
	</transition>
</template>

<script>
import { TaskNotificationBellBackground, TaskNotificationBellRing } from '@assets/icons';
import { claimSourceLabelMap } from '@clientCommon/services/models/ClaimSource';
import { toRecipientLabel } from '@commonServices/utils/converter';
import { getUiSettingsByLocale } from '@commonServices/settings/localeSettings';
import { mapState } from 'vuex';
import { openClaimNewWindow } from '@commonServices/utils/routerUtils';

export default {
	name: 'AqTaskNotificationModal',
	components: {
		TaskNotificationBellBackground,
		TaskNotificationBellRing,
	},
	claimSourceLabelMap,
	props: {
		name: {
			type: String,
			required: true,
		},
		id: {
			type: Number,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		timer: {
			type: String,
			required: true,
		},
		source: {
			type: Number,
			required: true,
		},
		payeeTypes: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState(['appSettings', 'themeIconSettings', 'brand']),
		shiftY () {
			// Start 45% down the screen and move down by 2% for each notification
			return (this.index / 50) + 0.45;
		},
		uiLocaleSettings () {
			return getUiSettingsByLocale(this.brand.brandLocale);
		},
		payeeType () {
			return this.payeeTypes.map(x => this.toRecipientLabel(x)).join(' & ');
		},
	},
	methods: {
		close () {
			this.$modal.hide(this.name);
			this.$emit('close', this.id);
		},
		onOpenClaim () {
			openClaimNewWindow(this.$router, this.id);
			this.close();
		},
		toRecipientLabel (recipientType) {
			return toRecipientLabel(recipientType, this.uiLocaleSettings, this.appSettings);
		},
	},

};
</script>

<style lang="scss" scoped>
::v-deep .task-modal {
  overflow: visible;
  pointer-events: all;
}

.scale-up-enter-active {
  animation: scale-up 1s ease-in-out;
}

@keyframes scale-up {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.background {
  overflow: visible;
  z-index: -1;
  position: absolute;
  top: -29px;
  left: -29px;
  width: 320px;
  height: 220px;
  user-select: none;

  &:focus {
    outline: none;
  }
}

.bell {
  .ring {
    position: absolute;
    left: 14.2px;
    top: 14px;
  }

  .background {
    position: absolute;
    top: 7px;
    left: 8px;
  }

  .icon {
    position: absolute;
    color: #fff;
    top: 15px;
    left: 15.5px;
    transition: transform 0.1s ease-in-out; /* Smooth transition */
    transform-origin: 50% 50%; /* Rotate around the center */

    &:hover {
      transform: rotate(15deg);
    }
  }
}

.modal-container {
  margin-left: 24px;

  .title {
    color: var(--taskNotificationTextColour);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    height: 55px;
    padding-right: 5px;
    padding-top: 15px;
  }

  .body {
    text-align: center;
    width: 100%;
    height: 100px;
    margin-top: 5px;
    padding: 5px 10px;
  }
}

.timer {
  label {
    vertical-align: super;
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
  }
}

.btn-acknowledge {
  background: #FFBB81;
  color: #012336;

  &:hover {
    background: #FE7602;
    color: #FFF;
  }
}

.pipe {
  color: #FE7602;
}
</style>
