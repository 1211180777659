<template>
	<div>
		<div class="section-header d-flex flex-nowrap">
			<div>
				<div class="section-header__title">
					Policy Section Limits
				</div>
				<p>Limits and balances by Policy Section</p>
			</div>
			<div class="ml-auto mr-10 text-gray">
				{{ currentPolicyTerm.policyTermStartDate | longDate }} - {{ currentPolicyTerm.policyTermEndDate | longDate }}
			</div>
			<div
				v-if="showGraphClose"
				v-tooltip="'Close Claims Graphs'"
				class="close"
				@click="toggleGraphViewMode()"
			>
				<i class="fas fa-times" />
			</div>
		</div>
		<div class="section-content">
			<div class="container-fluid">
				<div v-if="showConditionsSelector">
					<graph-conditions-selector
						:conditions="conditions"
						:claim-condition-id="claimConditionId"
						@condition-changed="onConditionChanged"
					/>
				</div>
				<div v-if="sectionGraphs.length">
					<section-graph
						class="list-item--bg"
						v-for="(graph, index) of sectionGraphs"
						:key="index"
						:graph="graph"
						:index="index"
						:locale="locale"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClaimService from '@commonServices/claimService';
import eventBus from '@commonServices/eventBus';
import SectionGraph from '@commonView/CustomerPage/PetsLayout/SectionGraphs/SectionGraph';
import GraphConditionsSelector from '@commonView/CustomerPage/PetsLayout/GraphConditionsSelector';

export default {
	components: { GraphConditionsSelector, SectionGraph },
	name: 'SectionGraphs',
	props: {
		currentPolicyTerm: {
			type: Object,
			required: true,
		},
		selectionData: {
			type: Object,
			required: false,
			default: null,
		},
		locale: {
			type: String,
			required: false,
			default: null,
		},
		showGraphClose: {
			type: Boolean,
			required: false,
			default: false,
		},
		isListViewMode: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			sectionGraphs: [],
			conditions: [],
			claimConditionId: null,
		};
	},
	async mounted () {
		await this.updateGraphs();
		eventBus.$on('select-graph-conditions', this.onSelectGraphConditionsHandler);
	},
	beforeDestroy () {
		eventBus.$off('select-graph-conditions', this.onSelectGraphConditionsHandler);
	},
	computed: {
		showConditionsSelector () {
			return this.isListViewMode && this.conditions && this.conditions.length > 1;
		},
	},
	watch: {
		currentPolicyTerm () {
			this.updateGraphs();
		},
		selectionData () {
			this.updateGraphs();
		},
	},
	methods: {
		async updateGraphs () {
			if (this.selectionData) {
				await this.updateBySelectionData(this.selectionData);
			} else {
				await this.updateByPolicyTerm();
			}
		},
		async updateByPolicyTerm () {
			this.sectionGraphs = await ClaimService.getPolicySectionGraphs(this.currentPolicyTerm.policyTermId);
		},
		async updateBySelectionData ({ claimId, claimConditionId, claimIds }) {
			const isPeriodSelected = claimIds.filter(item => item !== claimId).length > 0;
			this.sectionGraphs = await ClaimService.getPolicySectionGraphs(this.currentPolicyTerm.policyTermId, claimId, claimConditionId, isPeriodSelected);
			await this.$nextTick();
			eventBus.$emit('select-pet-claim', claimIds);
		},
		toggleGraphViewMode () {
			this.$emit('toggle-graph-view');
		},
		onSelectGraphConditionsHandler (conditions, claimConditionId) {
			this.claimConditionId = claimConditionId;
			this.conditions = conditions;
		},
		onConditionChanged (claimConditionId) {
			eventBus.$emit('select-graph-segment', claimConditionId);
		},
	},
};
</script>
<style lang="scss" scoped>
.section-header {
  border-left: 1px solid var(--subSectionColour);
}

.close {
  height: 100%;
  width: 50px;
  vertical-align: middle;
  text-align: center;
  line-height: 30px;
  color: $active-color;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    font-size: 24px;
  }
}

</style>
