<template>
	<div :class="{ 'line-item-edit': isEditing }">
		<div class="row no-gutters attribute py-10 mb-10 align-items-center">
			<div class="col-6 pl-10">
				{{ label }}
			</div>
			<div class="col-2">
				{{ totalAmount | currency(locale) }}
			</div>
			<div class="col-4 pr-10">
				<div
					v-if="!isReadOnlyMode && invoiceRecognizedItemsSum === 0"
					class="circle float-right"
					:class="[ isEditing ? 'edited' : 'original']"
					@click="onEdit"
					v-tooltip="'Edit'"
				>
					<i class="fas fa-pencil-alt" />
				</div>
			</div>
		</div>
		<div
			v-if="isEditing"
			class="container-fluid pt-24 section-content"
		>
			<div class="row no-gutters">
				<div class="col-4 pl-10">
					<aq-form-input
						:label="label"
						icon-class="text-primary fa-lg"
						v-model="changedTotalAmount"
						type="money"
						:is-valid="!$v.changedTotalAmount.$error"
						:locale="locale"
						@input="$v.changedTotalAmount.$touch"
						@focused="onFocus(recognizedField)"
					/>
					<aq-form-input-error
						class="error mt-4"
						:error-messages="errorMessages"
						:validator="$v"
						property="changedTotalAmount"
					/>
				</div>
				<div
					v-if="includedField"
					class="col-4 d-flex align-items-center ml-10"
				>
					<aq-checkbox
						class="default"
						label="Included"
						v-model="included"
					/>
				</div>
			</div>
			<div class="d-flex justify-content-end mt-30">
				<button
					class="btn btn-secondary mr-10"
					type="button"
					@click="onCancel"
				>
					Cancel
				</button>
				<button
					class="btn btn-primary"
					type="button"
					@click="onFinish"
				>
					Finish
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { ValidationMessages } from '@commonServices/utils/constants';
import { moreThenOrEqual } from '@commonServices/utils/validators';

export default {
	name: 'InvoiceTotalItemEdit',
	inject: ['setScannedItemCoordinates'],
	data () {
		return {
			isEditing: false,
			changedTotalAmount: null,
			totalAmount: null,
			included: !!this.includedField?.value,
		};
	},
	props: {
		label: {
			type: String,
			required: true,
		},
		recognizedField: {
			type: Object,
			required: false,
			default: null,
		},
		includedField: {
			type: Object,
			required: false,
			default: null,
		},
		invoiceRecognizedAmount: {
			type: Number,
			required: false,
			default: null,
		},
		invoiceRecognizedItemsSum: {
			type: Number,
			required: false,
			default: null,
		},
		initialTotalSum: {
			type: Number,
			required: false,
			default: null,
		},
		isReadOnlyMode: {
			type: Boolean,
			default: false,
		},
	},
	mounted () {
		this.totalAmount = this.initialTotalSum ?? this.invoiceTotalAmount;
	},
	validations () {
		return {
			changedTotalAmount: {
				required,
				minValue: moreThenOrEqual(0),
			},
		};
	},
	computed: {
		locale () {
			return this.$store.state.brand.brandLocale;
		},
		errorMessages () {
			return {
				changedTotalAmount: {
					minValue: ValidationMessages.noNegative,
				},
			};
		},
		invoiceTotalAmount () {
			return this.invoiceRecognizedItemsSum !== 0 ? this.invoiceRecognizedItemsSum : this.invoiceRecognizedAmount;
		},
	},
	watch: {
		'invoiceTotalAmount' (newValue) {
			this.totalAmount = newValue;
		},
	},
	methods: {
		onEdit () {
			this.isEditing = true;
			this.changedTotalAmount = this.totalAmount;
		},
		onCancel () {
			this.isEditing = false;
		},
		onFinish () {
			this.$v.changedTotalAmount.$touch();
			if (!this.$v.changedTotalAmount.$error) {
				this.isEditing = false;
				this.totalAmount = this.changedTotalAmount;
				this.$emit('on-total-item-edit', { value: this.totalAmount, included: this.included });
			}
		},
		onFocus (field) {
			if (field?.boundingBox) {
				this.setScannedItemCoordinates(field.boundingBox, field.page);
			}
		},
	},
};
</script>
