export default class ParentPetClaim {
	constructor (
		claimId,
		conditionId,
		dateOfLoss,
		dateOfDeath,
		treatmentStart,
		treatmentEnd,
		amount,
		canBeParent,
		ailment,
		conditionType,
		bodyPart,
		formType,
	) {
		this.claimId = claimId;
		this.conditionId = conditionId;
		this.dateOfLoss = dateOfLoss;
		this.dateOfDeath = dateOfDeath;
		this.treatmentStart = treatmentStart;
		this.treatmentEnd = treatmentEnd;
		this.amount = amount;
		this.canBeParent = canBeParent;
		this.ailment = ailment;
		this.conditionType = conditionType;
		this.bodyPart = bodyPart;
		this.formType = formType;
	}
}
