import { Filter } from '@commonServices/models/FilterType';

const ClaimPaymentsReportFilters = [
	Filter.ClosedPaidDate,
	Filter.PaymentAmount,
	Filter.PaymentType,
];

const ClaimStatusReportFilters = [
	Filter.Brand,
	Filter.Species,
	Filter.Breed,
	Filter.Ailment,
	Filter.ConditionType,
	Filter.ClaimCreatedDate,
	Filter.ClaimFormUploadedDate,
	Filter.DateOnSet,
	Filter.TreatmentStart,
	Filter.LastInteractionDate,
	Filter.OverriddenBy,
	Filter.ClaimStatus,
	Filter.FormType,
	Filter.ClaimClosedDate,
	Filter.ClaimClosedBy,
	Filter.ClaimAssignedTo,
	Filter.Vet,
	Filter.FastTrackReviewed,
	Filter.County,
];

const UserStatsReportFilters = [
	Filter.StatsDate,
	Filter.StatsTeams,
	Filter.StatsUsers,
	Filter.StatsActiveUsers,
	Filter.Brand,
];

const QualityAuditReportFilters = [
	Filter.Brand,
	Filter.ClaimCreatedDate,
	Filter.ClaimClosedDate,
	Filter.FraudFlags,
	Filter.ClaimStatus,
];

const ClaimReportFilters = [
	Filter.Brand,
	Filter.Product,
	Filter.Ailment,
	Filter.ConditionType,
	Filter.Breed,
	Filter.Species,
	Filter.Genders,
	Filter.RejectionReasons,
	Filter.PolicyStatus,
	Filter.ClaimStatus,
	Filter.OverriddenBy,
	Filter.ExGratiaAmount,
	Filter.DateOnSet,
	Filter.TreatmentStart,
	Filter.TreatmentEnd,
	Filter.DatePaid,
	Filter.ClaimCreatedDate,
	Filter.PetDateOfBirth,
	Filter.InceptionDate,
	Filter.DatePolicyStart,
	Filter.UserDeductions,
	Filter.FormType,
	Filter.ReassessmentReason,
	Filter.ReassessmentOutcome,
	Filter.Vet,
	Filter.FastTrackReviewed,
	Filter.County,
	Filter.ClaimClosedDate,
];

export { ClaimPaymentsReportFilters, ClaimStatusReportFilters, UserStatsReportFilters, QualityAuditReportFilters, ClaimReportFilters };
